export default {
	namespaced: true,
	state: () => ({
		desktop: {
			id: [2],
			value: true,
		},
		mobile: {
			id: [1, 4, 5],
			value: true,
		},
		smartTV: {
			id: [3],
			value: false,
		},
	}),
	getters: {
		deviceType: (state) => state,
		selectedDeviceType: (state) => {
			const selectedDeviceType = [];
			Object.keys(state).forEach((fieldName) => {
				if (state[fieldName].value) {
					selectedDeviceType.push(...state[fieldName].id);
				}
			});
			return selectedDeviceType;
		},
		isSmartTvSelected: (state, getters) => getters.selectedDeviceType.includes(3),
	},
	mutations: {
		UPDATE_DEVICE_TYPE: (state, { fieldName, value }) => {
			state[fieldName].value = value;
		},
		SET_DEFAULT_DEVICE_TYPE(state) {
			Object.keys(state).forEach((fieldName) => {
				if (fieldName === 'smartTV') {
					state[fieldName].value = false;
					return;
				}
				state[fieldName].value = true;
			});
		},
	},
};
