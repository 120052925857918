class StatisticsAPI {
	constructor(apiCall, sortByDate) {
		this.apiCall = apiCall;
		this.sortByDate = sortByDate;
	}

	static generateEmptyHourlyMap = (timeStart) => {
		const emptyHourlyMap = {};
		for (let i = 0; i <= 23; i += 1) {
			emptyHourlyMap[new Date(new Date(timeStart).setHours(i)).getTime()] = {
				date: new Date(new Date(timeStart).setHours(i)),
				click: 0,
				win: 0,
				complete: 0,
			};
		}
		return emptyHourlyMap;
	};

	generateStatisticsResultByMethod = (method, statistics, timeStart) => {
		if (method === 'statistics-campaign-hourly') {
			const emptyMap = this.generateEmptyHourlyMap(timeStart);
			statistics.forEach((statisticItem) => {
				const statisticItemTime = new Date(statisticItem.time).getTime();
				emptyMap[statisticItemTime].click = statisticItem.state._._.click;
				emptyMap[statisticItemTime].complete = statisticItem.state._._.complete;
				emptyMap[statisticItemTime].win = statisticItem.state._._.win;
				emptyMap[statisticItemTime].date = statisticItem.date;
			});
			return Object.values(emptyMap);
		}
		const result = statistics.map((statisticItem) => ({
			date: new Date(statisticItem.time),
			click: statisticItem.state._._.click,
			complete: statisticItem.state._._.complete,
			win: statisticItem.state._._.win,
		}));
		return result.sort(this.sortByDate());
	};

	async getCampaignSummary(campaignID, timeStart, timeEnd, ssps = []) {
		try {
			const result = await this.apiCall.get({
				gate: `/statistic/campaign/${campaignID}/summary`,
				params: { timeStart, timeEnd, ssps: ssps.join(',') },
				isFatal: true,
			});
			return [null, result];
		} catch (err) {
			return [err];
		}
	}

	async getBannersWithStatisticMap(campaignID, timeStart, timeEnd, ssps = []) {
		try {
			const result = await this.apiCall.get({
				gate: `/statistic/campaign/${campaignID}/banners`,
				params: { timeStart, timeEnd, ssps: ssps.join(',') },
				isFatal: true,
			});
			return [null, result];
		} catch (err) {
			return [err];
		}
	}

	async getStatistics(method, campaignID, timeStart, timeEnd, ssps = []) {
		try {
			const statistics = await this.apiCall.get({
				gate: `/statistic/campaign/${campaignID}/${method}`,
				params: { timeStart, timeEnd, ssps: ssps.join(',') },
				isFatal: true,
			});
			const resultedStatistics = this.generateStatisticsResultByMethod(method, statistics, timeStart, timeEnd);
			return [null, resultedStatistics];
		} catch (err) {
			return [err];
		}
	}

	async getStatisticByOffers(campaignID, startTimeISO, endTimeISO, offsetTimeZone, ssps = []) {
		try {
			const statisticInteractives = await this.apiCall.get({
				gate: `/statistic/campaign/${campaignID}/offers`,
				params: {
					startTimeISO, endTimeISO, offsetTime: offsetTimeZone, sspList: ssps.join(','),
				},
				isFatal: true,
			});
			return [null, statisticInteractives];
		} catch (err) {
			return [err];
		}
	}

	async getStatisticByLogoButton(campaignID, startTimeISO, endTimeISO, offsetTimeZone, ssps = []) {
		try {
			const statisticInteractives = await this.apiCall.get({
				gate: `/statistic/campaign/${campaignID}/logo`,
				params: {
					startTimeISO, endTimeISO, offsetTime: offsetTimeZone, sspList: ssps.join(','),
				},
				isFatal: true,
			});
			return [null, statisticInteractives];
		} catch (err) {
			return [err];
		}
	}

	async getStatisticByOverlay(campaignID, startTimeISO, endTimeISO, offsetTimeZone, ssps = []) {
		try {
			const statisticInteractives = await this.apiCall.get({
				gate: `/statistic/campaign/${campaignID}/overlay`,
				params: {
					startTimeISO, endTimeISO, offsetTime: offsetTimeZone, sspList: ssps.join(','),
				},
				isFatal: true,
			});
			return [null, statisticInteractives];
		} catch (err) {
			return [err];
		}
	}

	async getStatisticByOffersXlsx(campaignID, startTimeISO, endTimeISO, offsetTimeZone, ssps = []) {
		try {
			const responseData = await this.apiCall.get({
				gate: `/statistic/campaign/${campaignID}/offers-xlsx`,
				params: {
					startTimeISO, endTimeISO, offsetTime: offsetTimeZone, sspList: ssps.join(','),
				},
			});
			return [null, responseData];
		} catch (err) {
			return [err];
		}
	}

	async getStatisticByLogoButtonXlsx(campaignID, startTimeISO, endTimeISO, offsetTimeZone, ssps = []) {
		try {
			const responseData = await this.apiCall.get({
				gate: `/statistic/campaign/${campaignID}/logo-xlsx`,
				params: {
					startTimeISO, endTimeISO, offsetTime: offsetTimeZone, sspList: ssps.join(','),
				},
			});
			return [null, responseData];
		} catch (err) {
			return [err];
		}
	}

	async getStatisticByOverlayXlsx(campaignID, startTimeISO, endTimeISO, offsetTimeZone, ssps = []) {
		try {
			const responseData = await this.apiCall.get({
				gate: `/statistic/campaign/${campaignID}/overlay-xlsx`,
				params: {
					startTimeISO, endTimeISO, offsetTime: offsetTimeZone, sspList: ssps.join(','),
				},
			});
			return [null, responseData];
		} catch (err) {
			return [err];
		}
	}
}

export default StatisticsAPI;
