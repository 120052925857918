import store from '@/store';
import getRelevantSession from '@/helpers/get-relevant-session';

// VARIABLES
export const mainPathByRole = {
	unauthorized: { name: 'Login' },
	cabinets: { name: 'UserCabinets' },
	account: { name: 'CampaignsList' },
	agency: { name: 'AccountsList' },
};

// SETUP

export const getUserRole = () => localStorage.getItem('current-session-role') ?? store.getters['User/userRole'];
export const getIsGuest = () => store.getters['User/isGuest'];
export const getIsSelfService = () => store.getters['User/userAccess'] === 'selfService';
export const getIsReportsAccount = () => store.getters['GlobalHooks/isReportsAccount'];

export async function checkSession() {
	await store.dispatch('User/updateUserInfo');
	const session = getRelevantSession();
	return !!session;
}

export function setDefaultZeroStateValues() {
	store.commit('Loading/UPDATE_GLOBAL_IS_LOADING_BUTTON', false);
	store.commit('FatalError/UPDATE_IS_FATAL_ERROR_VISIBLE', { visibleValue: false, errorCode: 0, errorMessage: '' });
}

export function setPageTitle(to) {
	const { title } = to.meta;
	const defaultTitle = 'Плацкарт';
	let additionalTitle = '';
	if (to.name === 'Login') {
		additionalTitle = to.params.role === 'account' ? 'рекламодателя' : 'агентства';
	}
	document.title = title ? `${defaultTitle} - ${title} ${additionalTitle}` : defaultTitle;
}
