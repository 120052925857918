<template>
  <div class="gradient-layout-container">
    <router-view />
  </div>
</template>

<script>
export default {
	name: 'GradientLayout',
	components: {},
};
</script>

<style lang="less" scoped>
@import url('~@/assets/style/variables');

.gradient-layout-container {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(122.66deg, @primary-color -6.38%, #002B28 18.69%, #000000 49.17%, #034250 85.33%, @secondary-color 111%);
}
</style>
