export default {
	namespaced: true,
	state: () => ({
		isFatalErrorVisible: false,
		textFatalError: 'Что-то пошло не так. Приносим свои извинения. Ведутся работы по восстановлению работоспособности системы.',
		error: {
			code: 0,
			message: '',
		},
	}),
	getters: {
		error(state) {
			return state.error;
		},
		isFatalErrorVisible(state) {
			return state.isFatalErrorVisible;
		},
		textFatalError(state) {
			return state.textFatalError;
		},
	},
	mutations: {
		UPDATE_IS_FATAL_ERROR_VISIBLE(state, { visibleValue, errorCode, errorMessage }) {
			state.isFatalErrorVisible = visibleValue;
			state.error.code = errorCode;
			state.error.message = errorMessage;
		},
	},
	actions: {},
};
