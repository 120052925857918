export default {
	namespaced: true,
	state: () => ({
		deviceManufactures: [],
		allDeviceManufactures: [],
	}),
	getters: {
		resultDeviceManufactures: (state) => state.deviceManufactures,
		allDeviceManufactures: (state) => state.allDeviceManufactures,
	},
	mutations: {
		UPDATE_DEVICE_MANUFACTURES: (state, deviceManufactures) => {
			state.deviceManufactures = deviceManufactures;
		},
		UPDATE_ALL_DEVICE_MANUFACTURES: (state, allDeviceManufactures) => {
			state.allDeviceManufactures = allDeviceManufactures;
		},
		SET_DEFAULT_DEVICE_MANUFACTURES(state) {
			state.deviceManufactures = [];
		},
		SET_DEFAULT_ALL_DEVICE_MANUFACTURES(state) {
			state.allDeviceManufactures = [];
		},
	},
	actions: {
		async getAllDeviceManufactures({ commit }) {
			const [err, result] = await this.$fetch.dictionaryAPI.getManufactures();
			if (err) {
				commit('UPDATE_ALL_DEVICE_MANUFACTURES', []);
				return;
			}

			commit('UPDATE_ALL_DEVICE_MANUFACTURES', result);
		},
	},
};
