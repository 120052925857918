<template>
  <router-view />
</template>

<script>
import 'devextreme/localization/globalize/number';
import 'devextreme/localization/globalize/date';
import 'devextreme/localization/globalize/currency';
import 'devextreme/localization/globalize/message';

// Dictionaries for German and Russian languages
// eslint-disable-next-line import/extensions
import ruMessages from 'devextreme/localization/messages/ru.json';

// Common and language-specific CLDR JSONs
// eslint-disable-next-line import/no-extraneous-dependencies
import ruCldrData from 'devextreme-cldr-data/ru.json';
// eslint-disable-next-line import/no-extraneous-dependencies
import supplementalCldrData from 'devextreme-cldr-data/supplemental.json';

// eslint-disable-next-line import/no-extraneous-dependencies
import Globalize from 'globalize';
import { removeNBSP } from '@/helpers/common';

export default {
	components: {},
	data() {
		return {};
	},
	created() {
		this.initGlobalize();
	},
	mounted() {
		window.addEventListener('copy', (event) => {
			const selection = document.getSelection().toString();
			event.clipboardData.setData('text/plain', removeNBSP(selection));
			event.preventDefault();
		});
	},
	methods: {
		initGlobalize() {
			Globalize.load(ruCldrData, supplementalCldrData);
			Globalize.loadMessages(ruMessages);
			Globalize.locale('ru');
		},
	},
};
</script>

<style lang="less">
@import url('~@/assets/style/variables');

#app {
	height: 100vh;
	font-family: @default-font-family;
	background-color: @primary-background-color;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
</style>
