import { sortByField } from '@/helpers/common';

class AuthAPI {
	constructor(apiCall) {
		this.apiCall = apiCall;
	}

	async createAccountSession(accountID) {
		try {
			const result = await this.apiCall.post('/agency/account/session', { id: accountID });
			return [null, result];
		} catch (e) {
			return [e];
		}
	}

	async createSession(login, password) {
		try {
			const formData = new FormData();
			formData.append('login', login);
			formData.append('password', password);
			const result = await this.apiCall.post('/user/auth', formData);
			return [null, result.token];
		} catch (e) {
			return [e];
		}
	}

	async getSessionInfo() {
		try {
			const result = await this.apiCall.get({ gate: '/auth/session' });
			return [null, result];
		} catch (e) {
			return [e];
		}
	}

	async getNewAuthUser() {
		try {
			const result = await this.apiCall.get({
				gate: '/user/current',
			});
			return [null, result];
		} catch (err) {
			return [err, {}];
		}
	}

	async getUserCabinets() {
		try {
			const result = await this.apiCall.get({
				gate: '/user/cabinets',
			});
			return [null, result.cabinets.sort(sortByField('name'))];
		} catch (err) {
			return [err, []];
		}
	}

	async changeUserCabinetFavorite({ id, favorite }) {
		try {
			await this.apiCall.put('/user/cabinets/favorites', {
				id,
				favorite,
			});
			return [null];
		} catch (err) {
			return [err];
		}
	}

	async updateUserPassword({ password, userId }) {
		try {
			const result = await this.apiCall.put('/user/password', { password, userId });
			return [null, result];
		} catch (err) {
			return [err];
		}
	}
}

export default AuthAPI;
