import showMessage from '@/helpers/messaging-service';
import showConfirm from '@/helpers/confirm-service';

export default {
	namespaced: true,
	state: () => ({
		banners: [],
		targetCampaignBanners: [],
		isNewBanner: true,
		currentBanner: {},

		emptyBanner: {
			creativeType: 'file',
			file: [],
			adomain: '',
			application: '',
			approved: '',
			banner: '',
			campaign: '',
			confirmClick: false,
			cpm: 0,
			cpmBySSP: {},
			cpmRate: 0,
			ctrEffect: 0,
			deals: {},
			description: '',
			extraURL: null,
			fileType: '',
			forSizmek: false,
			forWeborama: false,
			isOwnClickControl: false,
			isVertical: false,
			links: null,
			loadBannerOnView: false,
			maxWin: 0,
			maxComplete: 0,
			name: '',
			reasonOfDisapproved: '',
			title: '',
			tracking: {
				trackingClick: [],
				trackingShow: [],
				firstQuartile: [],
				midpoint: [],
				thirdQuartile: [],
				complete: [],
				mute: [],
				unmute: [],
			},
			trackingClick: '',
			trackingShow: '',
			apiType: 0,
			url: '',
			videoDuration: 0,
			wrapperURL: '',
			wrapperType: '',
			rewarded: '',
			subtitles: '',
			videoConfig: {
				subtitles: false,
				sound: false,
				progressBar: false,
				button: '',
				logo: '',
				adMessage: '',
				unskipableTime: 0,
				needSkipButton: false,
				subs: '',
			},
			configID: '000000000000000000000000',
			ias: '',
			moat: '',
			targetBanner: '000000000000000000000000',
			erirId: '',
			width: 0,
			height: 0,
		},
	}),
	getters: {
		banners: (state) => state.banners,
	},
	mutations: {
		SET_DEFAULT_BANNERS(state) {
			state.banners = [];
		},
		SET_DEFAULT_TARGET_CAMPAIGN_BANNERS(state) {
			state.targetCampaignBanners = [];
		},
		UPDATE_BANNERS(state, campaignBanners) {
			state.banners = [...campaignBanners];
		},
		UPDATE_TARGET_CAMPAIGN_BANNERS(state, campaignBanners) {
			state.targetCampaignBanners = [...campaignBanners];
		},
		UPDATE_CURRENT_BANNER_FROM_TARGET_BANNER(state, targetBanner) {
			state.currentBanner.url = targetBanner.url;
			state.currentBanner.adomain = targetBanner.adomain;
			state.currentBanner.tracking = targetBanner.tracking;
			state.currentBanner.erirId = targetBanner.erirId;
			state.currentBanner.name = targetBanner.name;
			state.currentBanner.wrapperURL = targetBanner.wrapperURL;
			state.currentBanner.creativeType = targetBanner.wrapperURL !== '' ? 'wrapper' : 'file';
			state.currentBanner.wrapperType = targetBanner.wrapperURL !== '' ? 'vastURL' : '';
			state.currentBanner.videoDuration = targetBanner.wrapperURL !== '' ? targetBanner.videoDuration : 0;
			state.currentBanner.fileType = targetBanner.wrapperURL !== '' ? 'wrapper' : '';
		},
		ADD_NEW_BANNER_FOR_CREATING_CAMPAIGN(state) {
			state.currentBanner.rowID = new Date().getTime();
			state.banners = [{ ...state.currentBanner }, ...state.banners];
		},
		EDIT_BANNER_FOR_CREATING_CAMPAIGN(state) {
			const bannerForEditIndex = state.banners.findIndex((banner) => banner.rowID === state.currentBanner.rowID);
			state.banners.splice(bannerForEditIndex, 1, state.currentBanner);
			state.banners = [...state.banners];
		},
		REMOVE_BANNER_FOR_CREATING_CAMPAIGN(state) {
			const bannerForEditIndex = state.banners.findIndex((banner) => banner.rowID === state.currentBanner.rowID);
			state.banners.splice(bannerForEditIndex, 1);
			state.banners = [...state.banners];
		},
		UPDATE_IS_NEW_BANNER(state, value) {
			state.isNewBanner = value;
		},
		UPDATE_CURRENT_BANNER(state, bannerData) {
			state.currentBanner = JSON.parse(JSON.stringify(bannerData));
		},
		UPDATE_CURRENT_BANNER_BY_FIELD(state, { fieldName, value }) {
			state.currentBanner[fieldName] = value;
		},
		ADD_SPECIAL_EMPTY_TRACKING_ITEM(state, { trackingType }) {
			const initialTrackingArray = state.currentBanner.tracking[trackingType];
			state.currentBanner.tracking[trackingType] = [...initialTrackingArray, ''];
		},
		REMOVE_SPECIAL_TRACKING_ITEM(state, { trackingType, index }) {
			state.currentBanner.tracking[trackingType].splice(index, 1);
		},
		UPDATE_SPECIAL_TRACKING_ITEM(state, { trackingType, index, value }) {
			state.currentBanner.tracking[trackingType][index] = value;
		},
		TRANSFORM_EMPTY_STRING_TRACKINGS(state) {
			const trackings = state.currentBanner.tracking;
			Object.keys(trackings).forEach((key) => {
				trackings[key] = trackings[key].filter((trackingURL) => trackingURL && trackingURL.trim() !== '');
			});
		},
	},
	actions: {
		async getBanners({ commit, rootGetters }, { type, campaignId }) {
			if (campaignId !== '' && !rootGetters['Campaign/BaseInfo/isBrandLift']) {
				const [err, banners] = await this.$fetch.campaignAPI.getBanners(campaignId);
				if (err) {
					return Promise.reject(err);
				}

				if (type === 'current') {
					commit('UPDATE_BANNERS', banners);
					return Promise.resolve();
				}

				if (type === 'target') {
					commit('UPDATE_TARGET_CAMPAIGN_BANNERS', banners);
					return Promise.resolve();
				}
			}

			return Promise.resolve();
		},
		async getTargetBannerSettings({ commit }, creativeId) {
			const [err, targetBanner] = await this.$fetch.bannerAPI.getBanner({ creativeId });
			if (err) {
				return Promise.reject(err);
			}

			commit('UPDATE_CURRENT_BANNER_FROM_TARGET_BANNER', targetBanner);
			return Promise.resolve();
		},
		async createBanner({ commit, dispatch }, { currentBanner, campaignID }) {
			commit('Loading/UPDATE_GLOBAL_IS_LOADING_BUTTON', true, { root: true });
			const [err] = await this.$fetch.bannerAPI.createBanner(currentBanner, campaignID);
			if (err) {
				if (err.message.includes('ставка меньше минимальной')) {
					const minCpm = err.message.split(': ')[1];
					showMessage('minCpmBannerErrorMSG', minCpm);
					commit('Loading/UPDATE_GLOBAL_IS_LOADING_BUTTON', false, { root: true });
					return { status: false };
				}

				showMessage('createBannerErrorMSG');
				commit('Loading/UPDATE_GLOBAL_IS_LOADING_BUTTON', false, { root: true });
				return { status: false };
			}
			showMessage('createBannerSuccessMSG');
			await dispatch('getBanners', { type: 'current', campaignId: campaignID });
			commit('Loading/UPDATE_GLOBAL_IS_LOADING_BUTTON', false, { root: true });
			return { status: true };
		},
		async editBanner({ commit, dispatch }, { currentBanner, campaignID }) {
			commit('Loading/UPDATE_GLOBAL_IS_LOADING_BUTTON', true, { root: true });
			const [err] = await this.$fetch.bannerAPI.updateBanner(currentBanner);
			if (err) {
				if (err.message.includes('ставка меньше минимальной')) {
					const minCpm = err.message.split(': ')[1];
					showMessage('minCpmBannerErrorMSG', minCpm);
					commit('Loading/UPDATE_GLOBAL_IS_LOADING_BUTTON', false, { root: true });
					return { status: false };
				}

				showMessage('editBannerErrorMSG');
				commit('Loading/UPDATE_GLOBAL_IS_LOADING_BUTTON', false, { root: true });
				return { status: false };
			}
			showMessage('editBannerSuccessMSG');
			await dispatch('getBanners', { type: 'current', campaignId: campaignID });
			commit('Loading/UPDATE_GLOBAL_IS_LOADING_BUTTON', false, { root: true });
			return { status: true };
		},
		async saveBanner({
			rootGetters, commit, state, dispatch,
		}) {
			const campaignID = rootGetters['Campaign/BaseInfo/campaignID'];
			const { currentBanner } = state;
			const isCreatingCampaign = campaignID === '';
			const isCreatingCampaignAddNewBanner = isCreatingCampaign && state.isNewBanner;
			const isCreatingCampaignEditBanner = isCreatingCampaign && !state.isNewBanner;
			const isEditCampaignAddNewBanner = !currentBanner.id;
			if (isCreatingCampaignAddNewBanner) {
				commit('TRANSFORM_EMPTY_STRING_TRACKINGS');
				commit('ADD_NEW_BANNER_FOR_CREATING_CAMPAIGN');
				showMessage('createBannerForCreatingCampaignWarningMSG', currentBanner.name);
				return { status: true };
			}
			if (isCreatingCampaignEditBanner) {
				commit('TRANSFORM_EMPTY_STRING_TRACKINGS');
				commit('EDIT_BANNER_FOR_CREATING_CAMPAIGN');
				showMessage('editBannerForCreatingCampaignWarningMSG', currentBanner.name);
				return { status: true };
			}

			commit('UPDATE_CURRENT_BANNER_BY_FIELD', { fieldName: 'wrapperType', value: currentBanner.wrapperURL !== '' ? 'vastURL' : '' });

			if (isEditCampaignAddNewBanner) {
				return dispatch('createBanner', { currentBanner, erirId: currentBanner.erirId.trim(), campaignID });
			}
			return dispatch('editBanner', { currentBanner, erirId: currentBanner.erirId.trim(), campaignID });
		},
		async removeBanner({ commit, dispatch, rootGetters }, banner) {
			commit('Loading/UPDATE_GLOBAL_IS_LOADING_BUTTON', true, { root: true });
			const resultConfirm = await showConfirm('removeBannerConfirm', banner.name);
			if (!resultConfirm) {
				commit('Loading/UPDATE_GLOBAL_IS_LOADING_BUTTON', false, { root: true });
				return;
			}

			const bannerID = banner.id;
			const bannerFileID = banner.banner;
			const bannerFileType = banner.fileType;
			const campaignID = rootGetters['Campaign/BaseInfo/campaignID'];
			if (campaignID === '') {
				commit('REMOVE_BANNER_FOR_CREATING_CAMPAIGN');
				showMessage('removeBannerForCreatingCampaignSuccessMSG', banner.name);
				commit('Loading/UPDATE_GLOBAL_IS_LOADING_BUTTON', false, { root: true });
				return;
			}

			const [err] = await this.$fetch.bannerAPI.removeBanner(bannerID, bannerFileID, bannerFileType);
			if (err) {
				if (err.message === 'объявление не может быть удалено, т. к. содержит статистику') {
					showMessage('bannerWithStatisticsErrorMSG');
					commit('Loading/UPDATE_GLOBAL_IS_LOADING_BUTTON', false, { root: true });
					return;
				}

				if (err.message === 'failed to remove banner. it has extra banners') {
					showMessage('removeBannerExtraErrorMSG');
					commit('Loading/UPDATE_GLOBAL_IS_LOADING_BUTTON', false, { root: true });
					return;
				}

				showMessage('removeBannerForCreatingCampaignErrorMSG');
				commit('Loading/UPDATE_GLOBAL_IS_LOADING_BUTTON', false, { root: true });
				return;
			}

			showMessage('removeBannerForCreatingCampaignSuccessMSG', banner.name);
			commit('Loading/UPDATE_GLOBAL_IS_LOADING_BUTTON', false, { root: true });
			await dispatch('getBanners', { type: 'current', campaignId: campaignID });
		},
	},
};
