class ReportsAPI {
	constructor(apiCall, getPercents) {
		this.apiCall = apiCall;
		this.totalWinsByCampaignId = {};
		this.totalWinsByBannerId = {};
		this.totalReachByCampaignId = {};
		this.getPercents = getPercents;
	}

	calcBannerTotalWins = (bannerID, state) => {
		if (!this.totalWinsByBannerId[bannerID]) {
			this.totalWinsByBannerId[bannerID] = 0;
		}
		this.totalWinsByBannerId[bannerID] += state.win;
	};

	getCampaignTotalWins = (id) => this.totalWinsByCampaignId[id];

	getCampaignTotalReach = (id) => this.totalReachByCampaignId[id];

	getBannerTotalWins = (id) => this.totalWinsByBannerId[id];

	setStateParams = (state) => {
		state.ctr = this.getPercents(state.click, state.win, 2);

		// TODO (d.komin): DSP-T-1715 Remove after back problem fix
		const calculatedViewPercents = this.getPercents(state.view, state.win, 2);
		state.viewPercents = calculatedViewPercents >= 100 ? 98 : calculatedViewPercents;

		const complete = state.complete ? state.complete : 0;
		const win = state.win ? state.win : 0;
		state.vtr = this.getPercents(complete, win);
		state.duplicatedComplete = state.complete;
	};

	setDailyAmountIFA = (dailyReport, isBanner) => {
		if (isBanner) {
			dailyReport.forEach((reportItem) => {
				const totalWins = this.getCampaignTotalWins(reportItem.campaign);
				const bannerTotalWins = this.getBannerTotalWins(reportItem.id);
				const campaignTotalReach = this.getCampaignTotalReach(reportItem.campaign);
				const winsRatio = bannerTotalWins / totalWins;
				const bannerTotalReach = Math.ceil(winsRatio * campaignTotalReach);
				reportItem.amountIFA = (bannerTotalReach * reportItem.state.win) / bannerTotalWins;
			});
			return;
		}
		dailyReport.forEach((reportItem) => {
			const totalWins = this.getCampaignTotalWins(reportItem.id);
			if (totalWins === 0) {
				reportItem.amountIFA = 0;
				return;
			}
			const totalReach = reportItem.amountIFA;
			const dailyReach = (totalReach * reportItem.state.win) / totalWins;
			reportItem.amountIFA = reportItem.limit.userTotalWin === 0 ? dailyReach : reportItem.amountIFA;
		});
	};

	async getAccountsList() {
		try {
			const result = await this.apiCall.get({
				gate: '/reports/accounts',
				isFatal: true,
			});
			return [null, result];
		} catch (err) {
			return [err];
		}
	}

	async getCampaignsListByAccountID(startTime, endTime, accountID, type, status) {
		try {
			const result = await this.apiCall.get({
				gate: '/reports/campaigns',
				params: {
					startTime, endTime, accountID, type, status,
				},
				isFatal: true,
			});
			return [null, result];
		} catch (err) {
			return [err];
		}
	}

	async getCampaignsStatisticsDaily(startTime, endTime, campaignIDs) {
		try {
			const result = await this.apiCall.get({
				gate: '/reports/campaigns-daily',
				params: { startTime, endTime, ids: campaignIDs.join(',') },
			});
			const resultArray = [];
			Object.keys(result).forEach((date) => {
				const reportByDay = result[date];
				reportByDay.forEach((stateItem) => {
					stateItem.date = date;
					const { state } = stateItem;
					this.setStateParams(state);
					resultArray.push(stateItem);
				});
			});
			return [null, resultArray];
		} catch (err) {
			return [err];
		}
	}

	async getBannersStatisticsDaily(startTime, endTime, campaignIDs) {
		try {
			const result = await this.apiCall.get({
				gate: '/reports/banners-daily',
				params: { startTime, endTime, ids: campaignIDs.join(',') },
			});
			this.totalWinsByBannerId = {};
			const resultArray = [];
			Object.keys(result).forEach((date) => {
				const reportByDay = result[date];
				reportByDay.forEach((stateItem) => {
					stateItem.date = date;
					const { state } = stateItem;
					this.calcBannerTotalWins(stateItem.id, state);
					this.setStateParams(state);
					resultArray.push(stateItem);
				});
			});

			return [null, resultArray];
		} catch (err) {
			return [err];
		}
	}

	async getCampaignsStatistics(startTime, endTime, campaignIDs) {
		try {
			const result = await this.apiCall.get({
				gate: '/reports/campaigns-statistics',
				params: { startTime, endTime, ids: campaignIDs.join(',') },
			});
			this.totalWinsByCampaignId = {};
			this.totalReachByCampaignId = {};
			const resultArray = [];
			Object.values(result).forEach((campaignState) => {
				const { state } = campaignState;
				this.totalWinsByCampaignId[campaignState.id] = state.win;
				this.totalReachByCampaignId[campaignState.id] = campaignState.amountIFA;
				this.setStateParams(state);
				resultArray.push(campaignState);
			});

			return [null, resultArray];
		} catch (err) {
			return [err];
		}
	}

	async getOffersReport(dateStart, dateEnd, offsetTimeZone, campaignIDs) {
		try {
			const result = await this.apiCall.get({
				gate: '/reports/offers-daily',
				params: {
					dateStart, dateEnd, campaignIDs: campaignIDs.join(','), offsetTimeZone,
				},
			});

			return [null, result];
		} catch (err) {
			return [err];
		}
	}
}

export default ReportsAPI;
