import MainInfo from '@/store/modules/campaign-statistics/campaign-statistics-main-info';
import Chart from '@/store/modules/campaign-statistics/campaign-statistics-chart';
import Banners from '@/store/modules/campaign-statistics/campaign-statistics-banners';
import EventInfo from '@/store/modules/campaign-statistics/campaign-statistics-event-info';
import BrandLifts from '@/store/modules/campaign-statistics/campaign-statistics-brandLifts';
import Interactives from '@/store/modules/campaign-statistics/campaign-statistics-interactives';
import showMessage from '@/helpers/messaging-service';

export default {
	namespaced: true,
	state: () => ({
		isLoaded: false,
		id: '',
		needRealTimeUpdate: false,
		startDate: new Date().setHours(0, 0, 0, 0),
		endDate: new Date().setHours(23, 59, 59, 999),
		statisticsInterval: null,
		timeZone: 180,
	}),
	getters: {
		campaignID(state) {
			return state.id;
		},
		startDateTime(state) {
			return new Date(state.startDate).getTime();
		},
		endDateTime(state) {
			return new Date(state.endDate).getTime();
		},
		isOneDaySelected(state) {
			return new Date(state.endDate).getTime() - new Date(state.startDate).getTime() === 86399999;
		},
	},
	mutations: {
		UPDATE_STATISTICS_INTERVAL(state, interval) {
			state.statisticsInterval = interval;
		},
		CLEAR_STATISTICS_INTERVAL(state) {
			clearInterval(state.statisticsInterval);
		},
		UPDATE_DATES(state, { startDate, endDate }) {
			state.startDate = startDate;
			state.endDate = endDate;
		},
		UPDATE_NEED_REAL_TIME_UPDATE_BY_CAMPAIGN_INFO(state, payload) {
			state.needRealTimeUpdate = payload.status === 'active';
		},
		UPDATE_CAMPAIGN_ID(state, payload) {
			state.id = payload;
		},
		SET_LOADED(state, payload) {
			state.isLoaded = payload;
		},
		UPDATE_NEED_REAL_TIME_UPDATE(state, payload) {
			state.needRealTimeUpdate = payload;
		},
	},
	actions: {
		setDefaultStatisticsData({ commit }) {
			commit('Campaign/Ssps/SELECT_ALL_SSPS', 'all', { root: true });
			commit('MainInfo/SET_DEFAULT_MAIN_CAMPAIGN_INFO');
			commit('MainInfo/SET_DEFAULT_SUMMARY_CAMPAIGN_INFO');
			commit('Chart/SET_DEFAULT_CHART_DATA');
			commit('Chart/SET_DEFAULT_INTERVAL_HELPER_DATA');
			commit('Banners/SET_DEFAULT_BANNERS_DATA');
			commit('EventInfo/SET_DEFAULT_EVENT_INFO_DATA');
			commit('EventInfo/SET_DEFAULT_BAN_IDS_MAP');
			commit('EventInfo/SET_DEFAULT_INITIAL_BAN_IDS_MAP');
			commit('BrandLifts/SET_DEFAULT_BRAND_LIFT_STATISTICS');
			commit('BrandLifts/SET_DEFAULT_SELECTED_BRAND_LIFT_TYPE');
			commit('Interactives/SET_DEFAULT_INTERACTIVES_DATA_BY_OFFERS');
			commit('Interactives/SET_DEFAULT_INTERACTIVES_DATA_BY_LOGO_BUTTON');
			commit('Interactives/SET_DEFAULT_INTERACTIVES_DATA_BY_OVERLAY');
		},
		async getFullCampaignStatistics({ dispatch, state, getters }, { isFirstCall, needRecalculateRealTimeUpdate }) {
			try {
				if (getters['MainInfo/isBrandLift']) {
					await Promise.all([
						dispatch('MainInfo/getFullCampaignInfo', {
							isFirstCall,
							needRecalculateRealTimeUpdate,
						}),
						dispatch('BrandLifts/getBrandLiftStatistics', { id: state.id }),
					]);
					return;
				}
				await Promise.all([
					dispatch('MainInfo/getFullCampaignInfo', {
						isFirstCall,
						needRecalculateRealTimeUpdate,
					}),
					isFirstCall || !state.needRealTimeUpdate ? dispatch('Chart/getChartData') : dispatch('Chart/getIntervalChartData'),
					dispatch('Banners/getBannersStatistics'),
					dispatch('Interactives/getFullInteractivesStatistics'),
				]);
			} catch (err) {
				if (err.message === 'canceled') {
					return;
				}
				showMessage('customMSG', 'error', err.message);
				console.error(err);
			}
		},
	},
	modules: {
		MainInfo,
		Chart,
		Banners,
		EventInfo,
		BrandLifts,
		Interactives,
	},
};
