<template>
  <div class="main-box">
    <div v-if="videoInStreamFormat" class="main-box-item">
      <p-card
        class="demo-in-stream-wrapper"
        flat
      >
        <div class="demo-in-stream-header">
          <img src="@/assets/images/preview-demo-page/preview-in-stream-header.svg" alt="">
        </div>
        <p-vast-player :banner-i-d="bannerID" :landing-page-url="landingPageUrl">
          <template #skeleton>
            <p-skeleton video-in-stream-player />
          </template>
        </p-vast-player>
        <div class="demo-in-stream-content">
          <img src="@/assets/images/preview-demo-page/preview-in-stream-content.svg" alt="">
        </div>
      </p-card>
    </div>
    <div v-if="videoOutStreamFormat" class="main-box-item">
      <p-card
        class="demo-out-stream"
        flat
      >
        <div class="demo-out-stream-wrapper">
          <div class="demo-out-stream-header">
            <img src="@/assets/images/preview-demo-page/preview-out-stream-header.svg" alt="">
          </div>
          <div class="demo-out-stream-content">
            <div class="demo-out-stream-content content-left">
              <div class="demo-out-stream-content-item">
                <img src="@/assets/images/preview-demo-page/preview-out-stream-content-1.svg" alt="">
              </div>
              <div class="demo-out-stream-content-item">
                <p-vast-player :banner-i-d="bannerID" :landing-page-url="landingPageUrl">
                  <template #skeleton>
                    <p-skeleton video-out-stream-player />
                  </template>
                </p-vast-player>
              </div>
              <div class="demo-out-stream-content-item">
                <img src="@/assets/images/preview-demo-page/preview-out-stream-content-2.svg" alt="">
              </div>
            </div>
            <div class="demo-out-stream-content content-right">
              <div class="demo-out-stream-content-item">
                <img src="@/assets/images/preview-demo-page/preview-out-stream-sidebar.svg" alt="">
              </div>
            </div>
          </div>
        </div>
      </p-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PSkeleton from '@/components/PSkeleton';
import PCard from '@/components/PCard';
import PVastPlayer from '@/components/PVastPlayer';

export default {
	name: 'PreviewDemoPage',
	components: {
		PSkeleton,
		PCard,
		PVastPlayer,
	},
	props: {
		adFormat: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
		};
	},
	computed: {
		...mapGetters({
			fileFromStore: 'PreviewDemoPages/previewFile',
		}),
		bannerID() {
			return this.$route.query.id;
		},
		videoInStreamFormat() {
			return this.adFormat === 'in-stream-video';
		},
		videoOutStreamFormat() {
			return this.adFormat === 'out-stream-video';
		},
		landingPageUrl() {
			if (!this.$route.query.url) {
				return 'https://plazkart.ru/';
			}

			return decodeURIComponent(this.$route.query.url);
		},
	},
	watch: {},
	mounted() {
	},
	methods: {
	},
};
</script>

<style lang="less" scoped>
@import url('~@/assets/style/variables.less');

.demo-in-stream {
	&-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		background: #fff;
		padding: 20px;
		margin: 0 auto;
		border-radius: @default-border-radius;

		.player-container {
			height: 358px;
			margin-bottom: 20px;
		}
	}

	&-content {
		width: 100%;
		display: flex;
	}
}

.back-button-container {
	width: fit-content;
	margin-left: auto;
}

.demo-out-stream {
	background: #fff;
	padding: 20px;
	width: 100%;
	border-radius: @default-border-radius;

	&-wrapper {
		display: flex;
		flex-direction: column;
	}

	&-header {
		margin-bottom: 20px;
	}

	&-content {
		display: flex;
	}

	.content-right {
		width: 330px;
	}

	.content-left {
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 100%;

		.demo-out-stream-content-item:nth-child(2) {
			display: flex;
			justify-content: center;
		}
	}

	.player-container {
		height: 360px;
	}
}
</style>
