import { sortByField } from '@/helpers/common';

const translateAgesMap = {
	age_18_to_24: '18-24',
	age_25_to_34: '25-34',
	age_35_to_44: '35-44',
	age_45_to_54: '45-54',
	age_55_to_64: '55-64',
	age_65_plus: '65+',
	processing: 'Обрабатывается',
};

const translateGenderMap = {
	female: 'Женщины',
	male: 'Мужчины',
	processing: 'Обрабатывается',
};

const translateQualityMap = {
	default: 'Среднее',
	good: 'Хорошее',
	great: 'Хорошее',
	badLinking: 'Плохое',
	spammed: 'Плохое',
	harmful: 'Плохое',
};

function transformAges(ages) {
	return Object.entries(ages).map(([title, value]) => ({
		title: translateAgesMap[title],
		value: +(value).toFixed(2),
	}));
}

function transformGender(gender) {
	return Object.entries(gender).map(([title, value]) => ({
		title: translateGenderMap[title],
		value: +(value).toFixed(2),
	}));
}

function transformQuality(quality) {
	return translateQualityMap[quality];
}

export default {
	namespaced: true,
	state: () => ({
		allDomains: [],
		bigLists: [],
		ipBigLists: [],
		selectedBigList: {
			id: '',
			name: '',
			content: [],
			domainsForRemove: [],
			domainsForAdd: [],
		},
		categoriesSet: new Set(),
		domainsTableColumns: [
			{
				alignment: 'center',
				width: '100px',
				allowExporting: false,
				allowHeaderFiltering: false,
				cellTemplate: 'icon-cell-template',
			},
			{
				dataField: 'domain',
				caption: 'Домен',
				alignment: 'center',
				allowHeaderFiltering: false,
				allowSearch: true,
			},
			{
				dataField: 'category',
				caption: 'Категория',
				alignment: 'center',
				allowHeaderFiltering: true,
				allowSearch: true,
			},
			{
				dataField: 'quality',
				caption: 'Качество',
				alignment: 'center',
				visible: false,
				allowSearch: true,
				allowHeaderFiltering: false,
				cellTemplate: 'domain-quality-cell-template',
			},
			{
				dataField: 'trust',
				// width: 160,
				caption: 'Уникальность контента',
				alignment: 'center',
				allowFiltering: true,
				allowHeaderFiltering: false,
				headerCellTemplate: 'trust-header-cell-template',
				calculateSortValue(data) {
					return +data.trust;
				},
				allowSearch: false,
				cellTemplate: 'trust-circle-cell-template',
			},
			{
				dataField: 'spam',
				// width: 150,
				caption: 'Количество рекламы',
				alignment: 'center',
				headerCellTemplate: 'spam-header-cell-template',
				cellTemplate: 'spam-circle-cell-template',
				allowFiltering: true,
				allowHeaderFiltering: false,
				calculateSortValue(data) {
					return +data.spam;
				},
			},
			{
				alignment: 'center',
				width: 140,
				allowExporting: false,
				allowHeaderFiltering: false,
				caption: 'Дополнительная информация',
				cellTemplate: 'domain-info-cell-template',
			},
		],
	}),
	getters: {
		filteredDomainsTableColumns: (state) => (needRemovalColumn) => {
			const removalColumn = {
				caption: 'Удаление',
				alignment: 'center',
				allowExporting: false,
				cellTemplate: 'remove-cell-template',
			};
			const allColumns = [...state.domainsTableColumns];
			if (needRemovalColumn) {
				allColumns.push(removalColumn);
			}
			return allColumns;
		},
		categories: (state) => [...state.categoriesSet],
	},
	mutations: {
		UPDATE_ALL_DOMAINS(state, payload) {
			payload.map((item) => {
				item.quality = transformQuality(item.quality);
				item.rawAge = item.age;
				item.age = transformAges(item.age);
				item.rawGender = item.gender;
				item.gender = transformGender(item.gender);
				state.categoriesSet.add(item.category);
				return item;
			});
			state.allDomains = payload.sort(sortByField('win', true));
		},
		UPDATE_BIG_LISTS(state, payload) {
			state.bigLists = payload.sort(sortByField('name'));
		},
		UPDATE_IP_BIG_LISTS(state, payload) {
			state.ipBigLists = payload.sort(sortByField('name'));
		},
		UPDATE_SELECTED_BIG_LIST_CONTENT(state, payload) {
			state.selectedBigList.content = [...payload];
		},
		UPDATE_SELECTED_BIG_LIST_ID(state, id) {
			state.selectedBigList.id = id;
		},
		UPDATE_SELECTED_BIG_LIST_NAME(state, name) {
			state.selectedBigList.name = name;
		},
		UPDATE_SELECTED_BIG_LIST_DOMAINS_FOR_REMOVE(state, domainsForRemove) {
			state.selectedBigList.domainsForRemove = [...state.selectedBigList.domainsForRemove, ...domainsForRemove];
		},
		UPDATE_SELECTED_BIG_LIST_DOMAINS_FOR_ADD(state, domainsForAdd) {
			state.selectedBigList.domainsForAdd = [...state.selectedBigList.domainsForAdd, ...domainsForAdd];
		},
		SET_DEFAULT_SELECTED_BIG_LIST(state) {
			state.selectedBigList.name = '';
			state.selectedBigList.id = '';
			state.selectedBigList.content = [];
			state.selectedBigList.domainsForRemove = [];
			state.selectedBigList.domainsForAdd = [];
		},
	},
	actions: {
		async getAllDomains({ commit }) {
			const [err, allDomains] = await this.$fetch.inventoryAPI.getAllDomains();
			if (err) {
				commit('UPDATE_ALL_DOMAINS', []);
				return Promise.reject();
			}

			commit('UPDATE_ALL_DOMAINS', allDomains);
			return Promise.resolve();
		},
		async getBigLists({ commit }) {
			const [err, bigLists] = await this.$fetch.bigListAPI.getAllDomainsBigList();
			if (err) {
				commit('UPDATE_BIG_LISTS', []);
				return Promise.reject();
			}
			commit('UPDATE_BIG_LISTS', bigLists);
			return Promise.resolve();
		},
		async getIpBigLists({ commit }) {
			const [err, ipBigLists] = await this.$fetch.bigListAPI.getAllIpBigList();
			if (err) {
				commit('UPDATE_IP_BIG_LISTS', []);
				return Promise.reject();
			}
			commit('UPDATE_IP_BIG_LISTS', ipBigLists);
			return Promise.resolve();
		},
		async getBigListContent({ state, commit }, { bigListID, bigListName }) {
			commit('SET_DEFAULT_SELECTED_BIG_LIST');
			const [err, bigListContent] = await this.$fetch.bigListAPI.getBigListContent(bigListID);
			if (err || !bigListContent) {
				return;
			}
			commit('UPDATE_SELECTED_BIG_LIST_ID', bigListID);
			commit('UPDATE_SELECTED_BIG_LIST_NAME', bigListName);
			const bigListContentWithFullInfo = state.allDomains.filter((item) => bigListContent.includes(item.domain));

			if (bigListContentWithFullInfo.length < bigListContent.length) {
				const fullDomainsNames = bigListContentWithFullInfo.map((item) => item.domain);
				bigListContent.forEach((item) => {
					if (!fullDomainsNames.includes(item)) {
						bigListContentWithFullInfo.push({
							domain: item,
							spam: 'processing',
							trust: 'processing',
							quality: 'Обрабатывается',
							category: 'Обрабатывается',
							age: [{
								title: 'Обрабатывается',
								value: 1,
							}],
							gender: [{
								title: 'Обрабатывается',
								value: 1,
							}],
						});
					}
				});
			}

			commit('UPDATE_SELECTED_BIG_LIST_CONTENT', bigListContentWithFullInfo);
		},
		async getFullInventoryInfo({ dispatch }) {
			const getAllDomainsPromise = dispatch('getAllDomains');
			const getBigListsPromise = dispatch('getBigLists');
			const getIpBigListsPromise = dispatch('getIpBigLists');
			const arrayOfPromises = [getAllDomainsPromise, getBigListsPromise, getIpBigListsPromise];
			try {
				await Promise.all(arrayOfPromises);
			} catch (err) {
				console.error(err);
			}
		},
	},
};
