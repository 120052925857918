import { wrongParamGuard } from '@/router/utils/router-guards';
import PreviewDemoPage from '@/views/preview/PreviewDemoPage';

const previewRoutes = [
	{
		path: ':pageMode',
		props: true,
		redirect: '',
		name: 'PreviewLauncher',
		meta: { props: ['launcher'] },
		component: () => import('@/views/preview/PreviewLauncher'),
		beforeEnter: [wrongParamGuard],
	},
	{
		path: 'demo-page/:adFormat',
		props: true,
		name: 'PreviewDemoPage',
		meta: { props: ['in-stream-video', 'out-stream-video', 'in-video-banner'] },
		component: PreviewDemoPage,
		beforeEnter: [wrongParamGuard],
	},
	{
		path: 'player-customization',
		name: 'PreviewPlayerCustomization',
		component: () => import('@/views/preview/PreviewPlayerCustomization'),
		children: [
			{
				path: 'showcase',
				alias: ['/showcase'],
				name: 'PreviewShowcase',
				meta: { main: true, title: 'Примеры интерактива' },
				component: () => import('@/views/preview/PreviewPlayerCustomization/PreviewCardsGrid'),
			},
			{
				path: 'overlay',
				name: 'PreviewOverlay',
				meta: { title: 'Оверлей' },
				component: () => import('@/views/preview/PreviewPlayerCustomization/PreviewSingleCard'),
			},
			{
				path: 'logo-button',
				name: 'PreviewLogoButton',
				meta: { title: 'Динамическая кнопка' },
				component: () => import('@/views/preview/PreviewPlayerCustomization/PreviewSingleCard'),
			},
			{
				path: 'packshot',
				name: 'PreviewPackshot',
				meta: { title: 'Пэкшот' },
				component: () => import('@/views/preview/PreviewPlayerCustomization/PreviewSingleCard'),
			},
			{
				path: 'tabs',
				name: 'PreviewTabs',
				meta: { title: 'Микросайт' },
				component: () => import('@/views/preview/PreviewPlayerCustomization/PreviewSingleCard'),
			},
			{
				path: 'cart',
				name: 'PreviewCart',
				meta: { title: 'Механика витрин' },
				component: () => import('@/views/preview/PreviewPlayerCustomization/PreviewSingleCard'),
			},
			{
				path: 'clickable-packshot',
				name: 'PreviewClickablePackshot',
				meta: { title: 'Интерактив с активными деталями' },
				component: () => import('@/views/preview/PreviewPlayerCustomization/PreviewSingleCard'),
			},
		],
	},
];

export default previewRoutes;
