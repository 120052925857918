export default {
	namespaced: true,
	state: () => ({
		isBrandLiftPopupVisible: false,
		gallerySelectedIndex: 0,
		popupMode: '',
		brandLifts: [],
		brandLiftData: {
			name: '',
			cpm: 0,
			beforeAfterSwitcher: true,
			timeStart: new Date().getTime(),
			timeStop: new Date(new Date().setHours(23, 59, 59, 999)).getTime(),
			slides: [
				{
					id: '',
					question: '',
					order: 1,
					maxAnswerCount: 0,
					answers: [
						{ order: 1, answer: '' },
						{ order: 2, answer: '' },
						{ order: 3, answer: '' },
						{ order: 4, answer: '' },
					],
				},
				{
					id: '',
					question: '',
					order: 2,
					maxAnswerCount: 0,
					answers: [
						{ order: 1, answer: '' },
						{ order: 2, answer: '' },
						{ order: 3, answer: '' },
						{ order: 4, answer: '' },
					],
				},
				{
					id: '',
					question: '',
					order: 3,
					maxAnswerCount: 0,
					answers: [
						{ order: 1, answer: '' },
						{ order: 2, answer: '' },
						{ order: 3, answer: '' },
						{ order: 4, answer: '' },
					],
				},
			],
		},
	}),
	getters: {},
	mutations: {
		UPDATE_BRAND_LIFTS(state, payload) {
			state.brandLifts = payload;
		},
		SET_DEFAULT_BRAND_LIFTS(state) {
			state.brandLifts = [];
		},
		UPDATE_GALLERY_SELECTED_INDEX(state, { value }) {
			state.gallerySelectedIndex = value;
		},
		TOGGLE_IS_BRAND_LIFT_POPUP_VISIBLE(state, { value, mode }) {
			state.isBrandLiftPopupVisible = value;
			state.popupMode = value ? mode : '';
			state.gallerySelectedIndex = value ? state.gallerySelectedIndex : 0;
		},
		SET_DEFAULT_BRAND_LIFT_DATA(state) {
			state.timeStart = new Date();
			state.timeStop = new Date().setHours(23, 59, 59, 999);
			state.brandLiftData.id = '';
			state.brandLiftData.name = '';
			state.brandLiftData.cpm = 0;
			state.brandLiftData.beforeAfterSwitcher = true;
			state.brandLiftData.slides = [];
			for (let i = 0; i < 3; i += 1) {
				const defaultSlide = {
					id: '',
					order: i + 1,
					question: '',
					maxAnswerCount: 0,
					answers: [
						{ order: 1, answer: '' },
						{ order: 2, answer: '' },
						{ order: 3, answer: '' },
						{ order: 4, answer: '' },
					],
				};
				state.brandLiftData.slides.push({ ...defaultSlide });
			}
		},
		UPDATE_BRAND_LIFT_DATA(state, payload) {
			state.brandLiftData.id = payload.id;
			state.brandLiftData.name = payload.name;
			state.brandLiftData.cpm = payload.cpm;
			state.brandLiftData.beforeAfterSwitcher = payload.beforeAfterSwitcher;
			state.brandLiftData.slides = payload.slides;
		},
		UPDATE_BRAND_LIFT_DATA_BY_FIELD(state, { fieldName, value }) {
			state.brandLiftData[fieldName] = value;
		},
		UPDATE_BRAND_LIFT_DATA_SLIDE_QUESTION(state, { value, slideIndex }) {
			state.brandLiftData.slides[slideIndex].question = value;
		},
		UPDATE_BRAND_LIFT_DATA_SLIDE_ANSWER(state, { value, answerIndex }) {
			state.brandLiftData.slides[state.gallerySelectedIndex].answers[answerIndex].answer = value;
		},
		UPDATE_BRAND_LIFT_DATA_SLIDE_ANSWERS_ARR(state, { answersLength, slideIndex }) {
			const resultAnswers = [];
			for (let i = 1; i <= answersLength; i += 1) {
				resultAnswers.push({
					order: i,
					answer: '',
				});
			}
			state.brandLiftData.slides[slideIndex].answers = resultAnswers;
		},
		UPDATE_BRAND_LIFT_DATA_SLIDES_ARR(state, { slidesLength }) {
			const resultSlides = [];
			for (let i = 1; i <= slidesLength; i += 1) {
				resultSlides.push({
					question: '',
					order: i,
					maxAnswerCount: 0,
					answers: [
						{ order: 1, answer: '' },
						{ order: 2, answer: '' },
						{ order: 3, answer: '' },
						{ order: 4, answer: '' },
					],
				});
			}
			state.brandLiftData.slides = resultSlides;
		},
		UPDATE_BRAND_LIFT_DATA_SLIDE_MAX_ANSWER_COUNT(state, { value, slideIndex }) {
			state.brandLiftData.slides[slideIndex].maxAnswerCount = value;
		},
	},
	actions: {
		async createBrandLift({ state }) {
			return this.$fetch.brandLiftAPI.createBrandLift(state.brandLiftData);
		},
		async editBrandLift({ state }) {
			return this.$fetch.brandLiftAPI.editBrandLift(state.brandLiftData);
		},
		async getBrandLifts({ commit }) {
			const [err, result] = await this.$fetch.brandLiftAPI.getBrandLifts();
			if (err) {
				return Promise.reject();
			}
			commit('UPDATE_BRAND_LIFTS', result);
			return Promise.resolve();
		},
	},
};
