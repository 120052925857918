class apiCall {
	constructor(axiosInstance) {
		this.axios = axiosInstance;
	}

	async get({
		gate, params = {}, isFatal = false, signal = null,
	}) {
		return this.axios.get(gate, { params, signal, isFatal });
	}

	async post(gate, data) {
		return this.axios.post(gate, data);
	}

	async put(gate, data) {
		return this.axios.put(gate, data);
	}

	async delete(gate, params = {}) {
		return this.axios.delete(gate, { params });
	}
}

export default apiCall;
