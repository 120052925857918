import { noPermissionGuard } from '@/router/utils/router-guards';

const accountRoutes = [
	{
		path: 'campaigns-list',
		name: 'CampaignsList',
		meta: {
			access: ['fullService', 'selfService'],
			roleRequired: 'account',
			guestAllowed: true,
			title: 'Кампании',
		},
		component: () => import('@/views/account/CampaignsList'),
		beforeEnter: [noPermissionGuard],
	},
	{
		path: 'campaign-create',
		name: 'CampaignCreate',
		meta: {
			access: ['fullService', 'selfService'],
			roleRequired: 'account',
			guestAllowed: false,
			title: 'Создание кампании',
		},
		component: () => import('@/views/account/UpsertCampaign'),
		beforeEnter: [noPermissionGuard],
	},
	{
		path: 'campaign-edit/:campaignID',
		props: true,
		name: 'CampaignEdit',
		meta: {
			access: ['fullService', 'selfService'],
			guestAllowed: false,
			roleRequired: 'account',
			title: 'Редактирование кампании',
		},
		component: () => import('@/views/account/UpsertCampaign'),
		beforeEnter: [noPermissionGuard],
	},
	{
		path: 'campaign-statistics/:campaignID',
		props: true,
		name: 'CampaignStatistics',
		meta: {
			access: ['fullService', 'selfService'],
			guestAllowed: true,
			roleRequired: 'account',
			title: 'Статистика кампании',
		},
		component: () => import('@/views/account/CampaignStatistics'),
		beforeEnter: [noPermissionGuard],
	},
	{
		path: 'inventory',
		name: 'Inventory',
		meta: {
			access: ['fullService', 'selfService'],
			guestAllowed: false,
			roleRequired: 'account',
			title: 'Инвентарь',
		},
		component: () => import('@/views/account/Inventory'),
		beforeEnter: [noPermissionGuard],
	},
	{
		path: 'tools',
		name: 'Tools',
		redirect: {
			name: 'PlayerCustomization',
		},
		component: () => import('@/views/account/Tools'),
		meta: {
			access: ['fullService', 'selfService'],
			guestAllowed: false,
			roleRequired: 'account',
			title: 'Плеер',
		},
		children: [
			{
				path: 'player-customization',
				name: 'PlayerCustomization',
				meta: {
					access: ['fullService', 'selfService'],
					guestAllowed: false,
					roleRequired: 'account',
					title: 'Плеер',
				},
				component: () => import('@/views/account/Tools/PlayerCustomization'),
				beforeEnter: [noPermissionGuard],
			},
		],
	},
	{
		path: 'reports',
		name: 'Reports',
		meta: {
			access: ['fullService', 'selfService'],
			guestAllowed: false,
			roleRequired: 'account',
			title: 'Отчеты',
		},
		component: () => import('@/views/account/Reports'),
		beforeEnter: [noPermissionGuard],
	},
	{
		path: 'retargeting',
		name: 'Retargeting',
		meta: {
			access: ['fullService'],
			guestAllowed: false,
			roleRequired: 'account',
			title: 'Ретаргетинг',
		},
		component: () => import('@/views/account/Retargeting'),
		beforeEnter: [noPermissionGuard],
	},
	{
		path: 'groups',
		name: 'GroupsManagement',
		meta: {
			access: ['fullService'],
			guestAllowed: false,
			title: 'Управление группами',
			roleRequired: 'account',
		},
		component: () => import('@/views/account/GroupsManagement'),
		beforeEnter: [noPermissionGuard],
	},
	{
		path: 'group/:groupID',
		props: true,
		name: 'GroupSettings',
		meta: {
			access: ['fullService'],
			guestAllowed: false,
			title: 'Настройки группы',
			roleRequired: 'account',
		},
		component: () => import('@/views/account/GroupSettings'),
		beforeEnter: [noPermissionGuard],
	},
	{
		path: 'brand-lifts',
		name: 'BrandLifts',
		meta: {
			access: ['fullService'],
			guestAllowed: false,
			title: 'Брендлифты',
			roleRequired: 'account',
		},
		component: () => import('@/views/account/BrandLifts'),
		beforeEnter: [noPermissionGuard],
	},
	{
		path: 'provider-panel-segments',
		name: 'ProviderPanelSegments',
		meta: {
			access: ['fullService'],
			guestAllowed: false,
			title: 'Панель',
			roleRequired: 'account',
		},
		component: () => import('@/views/account/ProviderPanelSegments'),
		beforeEnter: [noPermissionGuard],
	},
	{
		path: 'provider-panel-create',
		name: 'ProviderPanelCreate',
		meta: {
			access: ['fullService'],
			guestAllowed: false,
			title: 'Создание сегмента',
			roleRequired: 'account',
		},
		component: () => import('@/views/account/ProviderPanelUpsert'),
		beforeEnter: [noPermissionGuard],
	},
	{
		path: 'provider-panel-edit/:segmentId',
		name: 'ProviderPanelEdit',
		props: true,
		meta: {
			access: ['fullService'],
			guestAllowed: false,
			title: 'Редактирование сегмента',
			roleRequired: 'account',
		},
		component: () => import('@/views/account/ProviderPanelUpsert'),
		beforeEnter: [noPermissionGuard],
	},
];

export default accountRoutes;
