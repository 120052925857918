export default {
	namespaced: true,
	state: () => ({
		retargeting: {
			whiteList: [],
			blackList: [],
		},
		retargetingDataSource: {
			whiteList: [],
			blackList: [],
		},
		allSegments: [],
		segmentsNameByIdMap: {},
	}),
	getters: {
		isRetargetingSelected: (state) => (retargetingType) => state.retargeting[retargetingType].length !== 0,
		selectedFinalRetargetingNames: (state) => (retargetingType) => state.retargeting[retargetingType]
			.map((segmentId) => state.segmentsNameByIdMap[segmentId]),
		selectedRetargeting: (state) => (retargetingType) => state.retargeting[retargetingType],
		specialRetargetingDataSource: (state) => (retargetingType) => state.retargetingDataSource[retargetingType],
	},
	mutations: {
		SET_DEFAULT_RETARGETING(state) {
			state.retargeting.blackList = [];
			state.retargeting.whiteList = [];
		},
		SET_DEFAULT_RETARGETING_DATA_SOURCE(state) {
			state.retargetingDataSource.blackList = [];
			state.retargetingDataSource.whiteList = [];
		},
		SET_DEFAULT_ALL_SEGMENTS(state) {
			state.allSegments = [];
		},
		SET_DEFAULT_SEGMENTS_NAME_BY_ID_MAP(state) {
			state.segmentsNameByIdMap = {};
		},
		UPDATE_ALL_SEGMENTS(state, segments) {
			state.allSegments = segments;
		},
		UPDATE_CAMPAIGN_RETARGETING(state, campaignRetargeting) {
			state.retargeting.whiteList = campaignRetargeting.whiteList;
			state.retargeting.blackList = campaignRetargeting.blackList;
		},
		UPDATE_RETARGETING_DATA_SOURCE(state, retargetingType) {
			const oppositeRetargetingType = retargetingType === 'whiteList' ? 'blackList' : 'whiteList';
			state.retargetingDataSource[oppositeRetargetingType] = state.allSegments
				.filter((segment) => !state.retargeting[retargetingType].includes(segment.id));
		},
		UPDATE_RETARGETING_BY_TYPE(state, { retargetingType, retargetingSegments }) {
			state.retargeting[retargetingType] = retargetingSegments;
		},
		UPDATE_SEGMENTS_NAME_BY_ID_MAP(state) {
			state.allSegments.forEach((segment) => {
				state.segmentsNameByIdMap[segment.id] = segment.name;
			});
		},
	},
	actions: {
		setDefaultCampaignRetargetingStore({ commit }) {
			commit('SET_DEFAULT_RETARGETING');
			commit('SET_DEFAULT_RETARGETING_DATA_SOURCE');
			commit('SET_DEFAULT_ALL_SEGMENTS');
			commit('SET_DEFAULT_SEGMENTS_NAME_BY_ID_MAP');
		},
		async getRetargetingSegments({ commit }) {
			const [err, segments] = await this.$fetch.retargetingAPI.getRetargetingSegments();
			if (err) {
				return Promise.reject(err);
			}
			commit('UPDATE_ALL_SEGMENTS', segments);
			commit('UPDATE_RETARGETING_DATA_SOURCE', 'whiteList');
			commit('UPDATE_RETARGETING_DATA_SOURCE', 'blackList');
			commit('UPDATE_SEGMENTS_NAME_BY_ID_MAP');
			return Promise.resolve();
		},
	},
};
