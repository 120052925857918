export default {
	namespaced: true,
	state: () => ({
		geoByLevel: {
			countries: [],
			regions: [],
			cities: [],
		},
		geoStoreData: {
			countries: [],
			regions: [],
			cities: [],
		},
		helper: {
			byCountry: {},
			byRegion: {},
		},
	}),
};
