export default {
	namespaced: true,
	state: () => ({
		domains: [],
	}),
	getters: {
		domains: (state) => state.domains,
		domainsForCreateSegment: (state) => state.domains.map(({ domain, affinity }) => ({ name: domain, affinity })),
	},
	mutations: {
		UPDATE_DOMAINS(state, payload) {
			state.domains = payload;
		},
		SET_DEFAULT_DOMAINS(state) {
			state.domains = [];
		},
	},
	actions: {},
};
