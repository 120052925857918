class InventoryAPI {
	constructor(apiCall) {
		this.apiCall = apiCall;
	}

	async getAllDomains() {
		try {
			const allDomains = await this.apiCall.get({
				gate: '/inventory/all',
				isFatal: true,
			});
			return [null, allDomains];
		} catch (err) {
			return [err];
		}
	}

	async getDomainsCountByEmotionalBackground(payload) {
		try {
			const result = await this.apiCall.post('/inventory/domain-count', { emotionalBackground: payload });
			return [null, result];
		} catch (err) {
			return [err];
		}
	}
}

export default InventoryAPI;
