import { sortByField } from '@/helpers/common';

class CategorizerAPI {
	constructor(apiCall) {
		this.apiCall = apiCall;
	}

	async getSegments() {
		try {
			const responseData = await this.apiCall.get({
				gate: '/categorizer/segments',
				isFatal: true,
			});
			return [null, responseData.segments ?? []];
		} catch (err) {
			return [err];
		}
	}

	async getSegmentInfo(data) {
		try {
			const segmentInfo = await this.apiCall.get({
				gate: '/categorizer/segment-info',
				params: data,
			});
			return [null, segmentInfo];
		} catch (err) {
			return [err];
		}
	}

	async getCategories() {
		try {
			const responseData = await this.apiCall.get({
				gate: '/categorizer/categories',
				isFatal: true,
			});
			return [null, responseData.categories.sort(sortByField('title')) ?? []];
		} catch (err) {
			return [err];
		}
	}

	async getFilters() {
		try {
			const responseData = await this.apiCall.get({
				gate: '/categorizer/filters',
				isFatal: true,
			});
			return [null, responseData.filters ?? []];
		} catch (err) {
			return [err];
		}
	}

	async createSegment(segmentData) {
		try {
			await this.apiCall.post('/categorizer/segment', segmentData);
			return [null];
		} catch (err) {
			return [err];
		}
	}

	async removeSegment(segmentId) {
		try {
			await this.apiCall.delete('/categorizer/segment', { id: segmentId });
			return [null];
		} catch (err) {
			return [err];
		}
	}

	async getSegmentFile(segmentId) {
		try {
			const responseData = await this.apiCall.get({
				gate: `/categorizer/segment?id=${segmentId}`,
			});
			return [null, responseData];
		} catch (err) {
			return [err];
		}
	}
}

export default CategorizerAPI;
