import fileToBase64 from '@/helpers/file-to-base64';

class BannerAPI {
	constructor(apiCall, translateBannerTrackingsToBackendKeys) {
		this.apiCall = apiCall;
		this.translateBannerTrackingsToBackendKeys = translateBannerTrackingsToBackendKeys;
	}

	async getBanner({ creativeId }) {
		try {
			const banner = await this.apiCall.get({
				gate: `/banner/${creativeId}`,
			});

			Object.keys(banner.tracking).forEach((key) => {
				if (!banner.tracking[key]) {
					banner.tracking[key] = [];
				}
			});

			return [null, banner];
		} catch (err) {
			return [err];
		}
	}

	async createBanner(fullBanner, campaignID) {
		const { file } = fullBanner;
		const bannerObjectForRequest = JSON.parse(JSON.stringify(fullBanner));
		const bannerFile = file.length !== 0 ? await fileToBase64(file[0]) : '';

		bannerObjectForRequest.campaign = campaignID;

		this.translateBannerTrackingsToBackendKeys(bannerObjectForRequest.tracking);

		delete bannerObjectForRequest.id;
		delete bannerObjectForRequest.file;
		delete bannerObjectForRequest.creativeType;

		const bannerFormData = {
			banner: bannerObjectForRequest,
			file: bannerFile,
			playerConfig: bannerObjectForRequest.configID,
		};

		try {
			await this.apiCall.post('/banner/add', bannerFormData);
			return [null];
		} catch (err) {
			return [err];
		}
	}

	async updateBanner(banner) {
		const bannerObjectForRequest = JSON.parse(JSON.stringify(banner));
		const bannerFormData = { ...bannerObjectForRequest };

		this.translateBannerTrackingsToBackendKeys(bannerObjectForRequest.tracking);

		try {
			await this.apiCall.put('/banner/update', bannerFormData);
			return [null];
		} catch (err) {
			return [err];
		}
	}

	async removeBanner(bannerID, bannerFileID, bannerFileType) {
		try {
			await this.apiCall.delete(`/banner/${bannerID}`, { bannerFile: bannerFileID, bannerFileType });
			return [null];
		} catch (err) {
			return [err];
		}
	}

	async changeBannersCpm(campaignId, bannersIds, neededCpm) {
		try {
			await this.apiCall.put('/banners/rate', {
				campaign: campaignId,
				banners: bannersIds,
				rate: neededCpm,
			});
			return [null];
		} catch (err) {
			return [err];
		}
	}
}

export default BannerAPI;
