import axiosDefaultConfig from '@/api/axios/default-config';
import requestInterceptor from '@/api/axios/request-interceptor';
import { successResponseInterceptor, errorResponseInterceptor } from '@/api/axios/response-interceptors';

function axiosInstanceBuilder(axios, store, router) {
	const axiosInstance = axios.create(axiosDefaultConfig);

	axiosInstance.interceptors.request.use((config) => requestInterceptor(config));
	axiosInstance.interceptors.response.use(
		(response) => successResponseInterceptor(response),
		(error) => errorResponseInterceptor(error, store, router),
	);

	return axiosInstance;
}

export default axiosInstanceBuilder;
