import { getPercents } from '@/helpers/common';

export default {
	namespaced: true,
	state: () => ({
		bannersData: [],
	}),
	getters: {},
	mutations: {
		UPDATE_BANNERS_DATA(state, payload) {
			state.bannersData = payload;
		},
		SET_DEFAULT_BANNERS_DATA(state) {
			state.bannersData = [];
		},
	},
	actions: {
		async getBannersStatistics({ commit, rootGetters }) {
			const [allBannersErr, allBanners] = await this.$fetch.campaignAPI.getBanners(rootGetters['CampaignStatistics/campaignID']);
			if (allBannersErr) {
				return Promise.reject(allBannersErr);
			}
			if (allBanners.length === 0) {
				commit('SET_DEFAULT_BANNERS_DATA');
				return Promise.resolve();
			}
			const [bannerStatisticErr, bannersWithStatsMap] = await this.$fetch.statisticsAPI.getBannersWithStatisticMap(
				rootGetters['CampaignStatistics/campaignID'],
				rootGetters['CampaignStatistics/startDateTime'],
				rootGetters['CampaignStatistics/endDateTime'],
				rootGetters['Campaign/Ssps/sspsForStatisticsRequest'],
			);
			if (bannerStatisticErr) {
				return Promise.reject(bannerStatisticErr);
			}
			allBanners.forEach((banner) => {
				if (bannersWithStatsMap[banner.id]) {
					banner.state = bannersWithStatsMap[banner.id];
				}
				banner.status = banner.approved === '' ? 'moderation' : banner.approved === 'approved' ? 'approved' : 'disapproved';
				banner.state.ctr = getPercents(banner.state.click, banner.state.win, 2);
				// TODO DSP-T-1715 Remove after back problem fix
				banner.state.complete = banner.state.complete > banner.state.win ? banner.state.win : banner.state.complete;
				const calculatedViewPercents = getPercents(banner.state.view, banner.state.win, 2);
				banner.state.viewPercents = calculatedViewPercents >= 100 ? 98 : calculatedViewPercents;
				banner.state.vtr = getPercents(banner.state.complete, banner.state.win, 2);

				banner.state.budget = +banner.state.budget.toFixed(2);
			});
			commit('UPDATE_BANNERS_DATA', allBanners);
			return Promise.resolve();
		},
	},
};
