export default {
	namespaced: true,
	state: () => ({
		chartData: [],
		intervalHelperData: {
			click: 0,
			win: 0,
			complete: 0,
		},
	}),
	getters: {
		methodForGettingChartData(state, getters, rootState, rootGetters) {
			return rootGetters['CampaignStatistics/isOneDaySelected'] ? 'hourly' : 'dayly';
		},
	},
	mutations: {
		UPDATE_CHART_DATA(state, payload) {
			state.chartData = payload;
		},
		SET_DEFAULT_CHART_DATA(state) {
			state.chartData = [];
		},
		SET_DEFAULT_INTERVAL_HELPER_DATA(state) {
			Object.keys(state.intervalHelperData).forEach((key) => {
				state.intervalHelperData[key] = 0;
			});
		},
		SET_INTERVAL_HELPER_DATA(state, { click, win, complete }) {
			state.intervalHelperData.click = click;
			state.intervalHelperData.win = win;
			state.intervalHelperData.complete = complete;
		},
	},
	actions: {
		async getChartData({ commit, getters, rootGetters }) {
			commit('SET_DEFAULT_CHART_DATA');
			const [err, chartData] = await this.$fetch.statisticsAPI.getStatistics(
				getters.methodForGettingChartData,
				rootGetters['CampaignStatistics/campaignID'],
				rootGetters['CampaignStatistics/startDateTime'],
				rootGetters['CampaignStatistics/endDateTime'],
				rootGetters['Campaign/Ssps/sspsForStatisticsRequest'],
			);
			if (err) {
				return Promise.reject(err);
			}
			commit('UPDATE_CHART_DATA', chartData);
			return Promise.resolve();
		},
		async getIntervalChartData({ state, commit, rootGetters }) {
			const [err, intervalChartData] = await this.$fetch.campaignAPI.getCampaignState(rootGetters['CampaignStatistics/campaignID']);
			if (err) {
				return Promise.reject(err);
			}
			const localChartData = [...state.chartData];

			let newElem;

			if (localChartData.length === 0) {
				newElem = {
					click: 0,
					win: 0,
					complete: 0,
					date: new Date(),
				};
			} else {
				newElem = {
					click: intervalChartData.click - state.intervalHelperData.click,
					win: intervalChartData.win - state.intervalHelperData.win,
					complete: intervalChartData.complete - state.intervalHelperData.complete,
					date: new Date(),
				};
			}

			commit('SET_INTERVAL_HELPER_DATA', {
				click: intervalChartData.click,
				win: intervalChartData.win,
				complete: intervalChartData.complete,
			});

			localChartData.push(newElem);

			commit('UPDATE_CHART_DATA', localChartData);
			return Promise.resolve();
		},
	},
};
