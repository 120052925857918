import { getPercents, sortByField } from '@/helpers/common';

function transformedBrandLiftStatistics(brandLift) {
	brandLift.slides.sort(sortByField('order'));
	return brandLift.slides.map((slide) => {
		slide.beforeTotalCount = 0;
		slide.afterTotalCount = 0;
		slide.answers.reduce((acc, answer) => {
			acc.beforeTotalCount += answer.beforeCount;
			acc.afterTotalCount += answer.afterCount;
			return acc;
		}, slide);

		slide.answers.sort(sortByField('order'));
		slide.answers.forEach((answer) => {
			answer.beforePercent = getPercents(answer.beforeCount, slide.beforeTotalCount);
			answer.afterPercent = getPercents(answer.afterCount, slide.afterTotalCount);
		});
		return slide;
	});
}

class BrandLiftAPI {
	constructor(apiCall) {
		this.apiCall = apiCall;
	}

	async getBrandLift(id) {
		try {
			const result = await this.apiCall.get({
				gate: `/brandlift-preview/${id}`,
				isFatal: true,
			});
			return [null, result];
		} catch (err) {
			return [err];
		}
	}

	async getBrandLifts() {
		try {
			const result = await this.apiCall.get({
				gate: '/brandlifts',
				isFatal: true,
			});
			const resultBrandlifts = result.brandlifts.map((brandLift) => {
				brandLift.slides.sort(sortByField('order'));
				brandLift.slides.forEach((slide) => {
					slide.answers.sort(sortByField('order'));
				});
				return brandLift;
			});
			return [null, resultBrandlifts];
		} catch (err) {
			return [err];
		}
	}

	async getBrandLiftStatistics(brandLiftId) {
		try {
			const result = await this.apiCall.get({
				gate: `/brandlift/${brandLiftId}`,
				isFatal: true,
			});
			return [null, transformedBrandLiftStatistics(result)];
		} catch (err) {
			return [err];
		}
	}

	async createBrandLift(brandLift, vpaid = null) {
		const brandLiftData = { ...brandLift };
		delete brandLiftData.id;
		const brandLiftFormData = new FormData();
		brandLiftFormData.append('brandlift', JSON.stringify(brandLiftData));
		if (vpaid) {
			brandLiftFormData.append('vpaid-brandlift', vpaid);
		}
		try {
			const result = await this.apiCall.post('/brandlift', brandLiftFormData);
			return [null, result];
		} catch (err) {
			return [err];
		}
	}

	async editBrandLift(brandLift) {
		try {
			const editBrandLiftData = { ...brandLift };

			delete editBrandLiftData.timeStart;
			delete editBrandLiftData.timeStop;

			const result = await this.apiCall.put('/brandlift', editBrandLiftData);
			return [null, result];
		} catch (err) {
			return [err];
		}
	}
}

export default BrandLiftAPI;
