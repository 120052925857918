export default {
	namespaced: true,
	state: () => ({
		age: {},
		gender: {},
		income: {},
	}),
	getters: {
		selectedFilterIdsByType: (state) => (filterType) => Object.keys(state[filterType]).filter((key) => state[filterType][key].value),
		isSelfServiceAccount(state, getters, rootState, rootGetters) {
			return rootGetters['User/userRole'] === 'account' && rootGetters['User/userAccess'] === 'selfService';
		},
		ageByProvider(state, getters, rootState, rootGetters) {
			return Object.values(state.age).filter((age) => age.provider.includes(rootGetters['ProviderPanel/provider']));
		},
		genderByProvider(state, getters, rootState, rootGetters) {
			return Object.values(state.gender).filter((gender) => gender.provider.includes(rootGetters['ProviderPanel/provider']));
		},
		incomeByProvider(state, getters, rootState, rootGetters) {
			return Object.values(state.income).filter((income) => income.provider.includes(rootGetters['ProviderPanel/provider']));
		},
	},
	mutations: {
		SET_DEFAULT_SELECTED_FILTERS_BY_TYPE(state, filterType) {
			Object.keys(state[filterType]).forEach((key) => {
				state[filterType][key].value = false;
			});
		},
		UPDATE_FILTER_VALUE_BY_FIELD(state, { filterType, fieldName, value }) {
			state[filterType][fieldName].value = value;
		},
		UPDATE_FILTERS(state, filters) {
			filters.forEach((filter) => {
				if (!state[filter.group]) {
					return;
				}
				state[filter.group][filter.id] = { ...filter, value: false };
			});
		},
	},
	actions: {
		async getFilters({ commit }) {
			const [err, filters] = await this.$fetch.categorizerAPI.getFilters();
			if (err) {
				return Promise.reject();
			}
			commit('UPDATE_FILTERS', filters);
			return Promise.resolve();
		},
	},
};
