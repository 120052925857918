<template>
  <div
    class="zero-state-overlay"
    :class="{
      'global-loading-layout': globalIsLoading,
      'fatal-error-layout': isFatalErrorVisible,
    }"
  >
    <slot />
    <transition name="fade" mode="out-in">
      <div v-if="isFatalErrorVisible" class="fatal-error-wrapper">
        <h3 class="fatal-error-wrapper__heading">{{ textFatalError }}</h3>
        <div class="fatal-error-wrapper__buttonSection">
          <p-button
            text="Вернуться назад"
            icon="back.svg"
            @p-click="goBack"
          />
          <p-button
            text="Обновить страницу"
            icon="refresh.svg"
            secondary
            @p-click="reloadPage"
          />
          <p-button
            text="Выйти"
            icon="go-outside.svg"
            @p-click="logOut"
          />
        </div>
        <div class="fatal-error-wrapper__error-info">
          <span>Code: {{error.code}}</span>
          <span>Message: {{error.message}}</span>
        </div>
      </div>
    </transition>
  </div>

  <div
    v-if="globalIsLoading"
    class="loader-bar"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PButton from '@/components/PButton';

export default {
	name: 'PZeroState',
	components: {
		PButton,
	},
	props: {},
	data() {
		return {};
	},
	computed: {
		...mapGetters({
			globalIsLoading: 'Loading/globalIsLoading',
			isFatalErrorVisible: 'FatalError/isFatalErrorVisible',
			textFatalError: 'FatalError/textFatalError',
			error: 'FatalError/error',
		}),
	},
	watch: {},
	mounted() {
	},
	methods: {
		...mapActions({
			getRelevantLoginRoute: 'User/getRelevantLoginRoute',
		}),
		reloadPage() {
			this.$router.go(0);
		},
		goBack() {
			this.$router.back();
		},
		async logOut() {
			const relevantLoginRoute = await this.getRelevantLoginRoute();
			await this.$router.push(relevantLoginRoute);
		},
	},
};
</script>

<style lang="less" scoped>
@import url('~@/assets/style/variables');

.zero-state-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	max-width: 100%;
	height: 100vh;
	z-index: 11;

	&:before {
		content: '';
		opacity: 0;
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: -1;
		background: transparent;
		pointer-events: none;
	}

	&.fatal-error-layout {
		overflow: hidden;

		&:before {
			opacity: 1;
			z-index: 45;
			pointer-events: auto;
			background: rgba(0, 0, 0, .5);
			transition: opacity .5s;
		}
	}

	&.global-loading-layout {
		overflow: hidden;

		&:before {
			top: @header-height;
			opacity: 1;
			z-index: 45;
			pointer-events: auto;
		}
	}
}

.fatal-error-layout {
	overflow: hidden;

	&:before {
		content: '';
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 45;
		background: rgba(0, 0, 0, .5);
	}
}

.loader-bar {
	position: absolute;
	top: @header-height;
	z-index: 11;
	height: 5px;
	//background-color: @secondary-color;
	width: 100%;

	background: repeating-linear-gradient(to right, @secondary-color 0%, black 50%, @secondary-color 100%);
	background-size: 200% auto;
	background-position: 0 100%;
	animation: gradient 2s infinite;
	animation-fill-mode: forwards;
	animation-timing-function: linear;
}

@keyframes gradient {
	0% {
		background-position: 0 0;
	}
	100% {
		background-position: -200% 0;
	}
}

.fatal-error-wrapper {
	width: calc(100% - 30px);
	padding: 35px 30px 18px 30px;
	background: rgba(19, 19, 19, .85);
	border-radius: 4px;
	position: absolute;
	left: 15px;
	bottom: 15px;
	z-index: 55;

	&__heading {
		font-size: 22px;
		font-weight: 600;
		padding: 0 200px;
		color: #fff;
		margin: 0;
		text-align: center;
	}

	&__buttonSection {
		padding-top: 25px;
		display: flex;
		width: 100%;
		justify-content: center;
		gap: 0 20px;
	}

	&__error-info {
		display: flex;
		justify-content: center;
		color: #747474;
		font-size: 13px;
		align-items: center;
		gap: 20px;
		height: 40px;
		margin: 10px 0;
	}
}

.fade-enter-active {
	bottom: 15px;
	transition: all 1s ease-out;
	transition-delay: .3s;
}

.fade-enter-from {
	bottom: -100%;
}
</style>
