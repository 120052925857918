export default {
	namespaced: true,
	state: () => ({
		preRoll: true,
		contentRoll: true,
	}),
	getters: {
		preRoll: (state) => state.preRoll,
		contentRoll: (state) => state.contentRoll,
		rollType: (state) => state,
		selectedRollType: (state) => {
			const selectedRollType = [];
			if (state.preRoll) {
				selectedRollType.push('pre-roll');
			}
			if (state.contentRoll) {
				selectedRollType.push('content-roll');
			}
			return selectedRollType;
		},
	},
	mutations: {
		SET_DEFAULT_ROLL_TYPE(state) {
			state.preRoll = true;
			state.contentRoll = true;
		},
		UPDATE_ROLL_TYPE(state, { fieldName, value }) {
			state[fieldName] = value;
		},
	},
};
