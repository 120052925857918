export default {
	namespaced: true,
	state: () => ({
		plazDemoAccountID: '60740a14d41e06b9bf4ed61d',
		plazTestAccountID: '6037987bd41e06a43803a4f7',
		plazTiburonID: '60f59801d41e064f1e207ca5',
		plazkartSelfServiceAccountID: '61693996d41e063c66fe4780',
		plazkartFullServiceAccountID: '6194d5edf0e015eab65f23a8',
		plazPepsicoAccountID: '60e5b9cba897d80bfdf566f7',
		plazArenaAccount: '62dfdb78d41e06f2e88e2a6f',
		reportsAccountID: '6124bc52d41e061479caecd7',
		mdpSelfAccountID: '61af8fb0a897d895651c9c4c',
		sberPaoAccountId: '64004926d41e060001be8eef',
		dopRkAccount: '62251172d41e06f73cb522a9',

		plazDevAccountID: '611f735dd41e0652bd7c1051',
		dev2222Account: '61953474d41e06cfce04b9c8',
		dev2222testAccount: '61953578d41e06cfce0aaf42',
		selfTestAccount: '61e9480fd41e0648c304921c',
	}),
	getters: {
		isSelfServiceAccount(state, getters, rootState, rootGetters) {
			return rootGetters['User/userRole'] === 'account' && rootGetters['User/userAccess'] === 'selfService';
		},
		isSelfServiceAgency(state, getters, rootState, rootGetters) {
			return rootGetters['User/userRole'] === 'agency' && rootGetters['User/userAccess'] === 'selfService';
		},
		isCreateCampaignButtonVisible(state, getters, rootState, rootGetters) {
			return rootGetters['User/userRole'] === 'account' && !rootGetters['User/isGuest'];
		},
		isNotVisibleForGuest(state, getters, rootState, rootGetters) {
			return !rootGetters['User/isGuest'];
		},
		reachIsVisible(state, getters, rootState, rootGetters) {
			return getters.isNotVisibleForGuest || rootGetters['User/guestAccessProperty']('showReach');
		},
		isCpvVisible(state, getters, rootState, rootGetters) {
			return rootGetters['User/isGuest'] && rootGetters['User/guestAccessProperty']('showCPV');
		},
		budgetIsVisible(state, getters, rootState, rootGetters) {
			return getters.isNotVisibleForGuest || rootGetters['User/guestAccessProperty']('showBudget');
		},
		cpcIsVisible(state, getters, rootState, rootGetters) {
			return getters.isNotVisibleForGuest || rootGetters['User/guestAccessProperty']('showCPC');
		},
		cpmIsVisible(state, getters, rootState, rootGetters) {
			return getters.isNotVisibleForGuest || rootGetters['User/guestAccessProperty']('showCPM');
		},
		campaignStatusIsVisible(state, getters, rootState, rootGetters) {
			return getters.isNotVisibleForGuest || rootGetters['User/guestAccessProperty']('showStatus');
		},
		actionColumnIsVisible(state, getters, rootState, rootGetters) {
			return getters.isNotVisibleForGuest || rootGetters['User/guestAccessProperty']('showActionColumn');
		},
		winIsVisible(state, getters, rootState, rootGetters) {
			return getters.isNotVisibleForGuest || rootGetters['User/guestAccessProperty']('showWin');
		},
		vtrIsVisible(state, getters, rootState, rootGetters) {
			return getters.isNotVisibleForGuest || rootGetters['User/guestAccessProperty']('showVtr');
		},
		quartilesIsVisible(state, getters, rootState, rootGetters) {
			return getters.isNotVisibleForGuest || rootGetters['User/guestAccessProperty']('showQuartiles');
		},
		ctrIsVisible(state, getters, rootState, rootGetters) {
			return getters.isNotVisibleForGuest || rootGetters['User/guestAccessProperty']('showCtr');
		},
		clickIsVisible(state, getters, rootState, rootGetters) {
			return getters.isNotVisibleForGuest || rootGetters['User/guestAccessProperty']('showClick');
		},
		platformsTabIsVisible(state, getters, rootState, rootGetters) {
			return getters.isNotVisibleForGuest || rootGetters['User/guestAccessProperty']('showPlatformsTab');
		},
		campaignLogsBtnIsVisible(state, getters, rootState, rootGetters) {
			return getters.isNotVisibleForGuest || rootGetters['User/guestAccessProperty']('showCampaignLogsBtn');
		},
		banColumnIsVisible(state, getters, rootState, rootGetters) {
			return getters.isNotVisibleForGuest || rootGetters['User/guestAccessProperty']('showBanColumn');
		},
		platformTypeSelectorIsVisible(state, getters, rootState, rootGetters) {
			return getters.isNotVisibleForGuest || rootGetters['User/guestAccessProperty']('showPlatformTypeSelector');
		},
		campaignListCpmVisible(state, getters, rootState, rootGetters) {
			return rootGetters['User/isGuest'] && rootGetters['User/guestAccessProperty']('showCPM');
		},
		viewPercentsVisible(state, getters) {
			return !getters.isSelfServiceAccount && getters.isNotVisibleForGuest && !getters.isReportsAccount;
		},
		viewsVisible(state, getters) {
			return getters.isNotVisibleForGuest && !getters.isReportsAccount;
		},
		isOnlyForFullService(state, getters) {
			return getters.isNotVisibleForGuest && !getters.isSelfServiceAccount && !getters.isReportsAccount;
		},
		// createEditCampaign - ssp block visible
		accountsWithoutAccessToSspsBlock(state) {
			return [state.plazDemoAccountID, state.plazkartSelfServiceAccountID, state.plazkartFullServiceAccountID];
		},
		isSspsBlockVisible(state, getters, rootState, rootGetters) {
			const userID = rootGetters['User/userID'];
			return !getters.accountsWithoutAccessToSspsBlock.includes(userID) && !getters.isSelfServiceAccount;
		},
		// Taxonomy
		accountsWithoutAccessToCommonSegments(state) {
			return [state.plazkartSelfServiceAccountID, state.plazkartFullServiceAccountID];
		},
		isCommonSegmentsVisible(state, getters, rootState, rootGetters) {
			const userID = rootGetters['User/userID'];
			return !getters.accountsWithoutAccessToCommonSegments.includes(userID) && !getters.isSelfServiceAccount;
		},
		isPlazPepsicoAccountID(state, getters, rootState, rootGetters) {
			const userID = rootGetters['User/userID'];
			return userID === state.plazPepsicoAccountID;
		},
		isPlazArenaGuestAccount(state, getters, rootState, rootGetters) {
			const userID = rootGetters['User/userID'];
			const isGuestAccess = rootGetters['User/isGuest'];
			return userID === state.plazArenaAccount && isGuestAccess;
		},
		isSberPaoAccount(state, getters, rootState, rootGetters) {
			const userID = rootGetters['User/userID'];
			return state.sberPaoAccountId === userID;
		},
		isDopRkAccount(state, getters, rootState, rootGetters) {
			const userID = rootGetters['User/userID'];
			return state.dopRkAccount === userID;
		},
		// Reports
		isReportsAccount(state, getters, rootState, rootGetters) {
			const userID = rootGetters['User/userID'];
			return state.reportsAccountID === userID;
		},
		accountsToHideInReportsAccount(state) {
			return [
				state.plazTestAccountID,
				state.plazTiburonID,
				state.plazDevAccountID,
				state.plazDemoAccountID,
				state.reportsAccountID,
				state.dev2222Account,
				state.dev2222testAccount];
		},
	},
	mutations: {},
	actions: {},
};
