import showMessage from '@/helpers/messaging-service';

export default {
	namespaced: true,
	state: () => ({
		emotionalBackground: null,
		emptyEmotionalBackground: {
			negative: {
				min: 0,
				max: 1,
			},
			neutral: {
				min: 0,
				max: 1,
			},
			positive: {
				min: 0,
				max: 1,
			},
		},
		countDomain: 0,
	}),
	getters: {
		resultedEmotionalBackground: (state) => (state.emotionalBackground),
		emotionalBackgroundRangeByType: (state) => (emotionalType) => [
			state.emotionalBackground[emotionalType].min,
			state.emotionalBackground[emotionalType].max,
		],
		emotionalBackgroundRangeByTypeFormatted: (state) => (emotionalType) => ({
			min: (state.emotionalBackground[emotionalType].min * 100).toFixed(0),
			max: (state.emotionalBackground[emotionalType].max * 100).toFixed(0),
		}),
		countDomain: (state) => state.countDomain,
		isCountDomainErr: (state) => state.countDomain === -1,
	},
	mutations: {
		UPDATE_EMOTIONAL_BCKG: (state, { emotionalType, rangeType, value }) => {
			state.emotionalBackground[emotionalType][rangeType] = value;
		},
		SET_DEFAULT_EMOTIONAL_BACKGROUND(state) {
			state.emotionalBackground = null;
		},
		UPDATE_EMOTIONAL_BACKGROUND_BY_CAMPAIGN(state, campaignEmotionalBackground) {
			state.emotionalBackground = campaignEmotionalBackground;
		},
		SET_EMPTY_EMOTIONAL_BACKGROUND(state) {
			state.emotionalBackground = JSON.parse(JSON.stringify(state.emptyEmotionalBackground));
		},
		SET_DEFAULT_COUNT_DOMAIN: (state) => {
			state.countDomain = 0;
		},
		UPDATE_COUNT_DOMAIN: (state, value) => {
			state.countDomain = value;
		},
	},
	actions: {
		async getDomainsCountByEmotionalBackground({ commit, getters }) {
			const [err, { domains }] = await this.$fetch.inventoryAPI.getDomainsCountByEmotionalBackground(getters.resultedEmotionalBackground);
			if (err) {
				showMessage('getDomainsCountByEmotionalBackgroundErrorMSG');
				commit('UPDATE_COUNT_DOMAIN', -1);
				return;
			}
			commit('UPDATE_COUNT_DOMAIN', domains);
		},
	},
};
