export default {
	namespaced: true,
	state: () => ({
		ipBigLists: [],
		bigLists: {
			ip: {
				white: [],
				black: [],
			},
		},
	}),
	getters: {
		ipBigLists: (state) => state.ipBigLists,
		selectedIpsLists: (state) => (type) => state.bigLists.ip[type],
	},
	mutations: {
		UPDATE_IP_BIG_LISTS: (state, data) => {
			state.ipBigLists = data;
		},
		UPDATE_SELECTED_IP_BIG_LISTS_BY_TYPE: (state, { type, ids }) => {
			state.bigLists.ip[type] = ids;
		},
		UPDATE_SELECTED_IP_BIG_LISTS: (state, campaignIpBigLists) => {
			state.bigLists.ip.white = !campaignIpBigLists.white || (campaignIpBigLists.white.length === 1 && campaignIpBigLists.white[0] === '')
				? []
				: campaignIpBigLists.white;
			state.bigLists.ip.black = !campaignIpBigLists.black || (campaignIpBigLists.black.length === 1 && campaignIpBigLists.black[0] === '')
				? []
				: campaignIpBigLists.black;
		},
		SET_DEFAULT_IP_LISTS(state) {
			state.bigLists.ip.white = [];
			state.bigLists.ip.black = [];
		},
	},
	actions: {
		async getIpBigLists({ commit }) {
			const [err, data] = await this.$fetch.bigListAPI.getAllIpBigList();
			if (err) {
				commit('UPDATE_IP_BIG_LISTS', []);
				return Promise.reject(err);
			}
			commit('UPDATE_IP_BIG_LISTS', data);
			return Promise.resolve();
		},
	},
};
