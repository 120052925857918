import notify from 'devextreme/ui/notify';

const messages = {
	customMSG: (type, message) => ({ type, message }),
	commonSuccessMSG: () => ({ type: 'error', message: 'Операция прошла успешно' }),
	commonErrorMSG: () => ({ type: 'error', message: 'Что-то пошло не так...' }),
	invalidFormMSG: () => ({ type: 'error', message: 'Форма заполнена частично' }),

	/**
	 * src\components\Login\PLoginCard
	 * метод signIn карточка входа
	 */
	wrongCredentialsErrorMSG: () => ({ type: 'error', message: 'Неправильный логин или пароль' }),

	/**
	 * src\api\axios\response-interceptors.js
	 * обработчики 400-ых ошибок
	 */
	sessionExpiredErrorMSG: () => ({ type: 'error', message: 'Ваша сессия истекла, повторите вход в систему' }),
	notFoundErrorMSG: () => ({ type: 'error', message: 'Страница не найдена' }),

	/**
	 * src\components\AccountsList\PAccountsListTableBox
	 * метод по созданию аккаунта createAccount
	 * метод по изменению маржи аккаунта setCharge
	 * метод по изменению баланса аккаунта doMoneyTransfer
	 */
	noEmptyFieldsWarningMSG: () => ({ type: 'warning', message: 'Заполните все поля' }),
	createAccountSuccessMSG: () => ({ type: 'success', message: 'Аккаунт создан' }),
	createAccountErrorMSG: () => ({
		type: 'error',
		message: 'При создании аккаунта произошла ошибка, повторите попытку позже',
	}),
	createAccountDuplicateNameErrorMSG: () => ({
		type: 'error',
		message: 'Аккаунт с таким именем уже существует',
	}),
	updateChargeSuccessMSG: (nameAccount) => ({ type: 'success', message: `Маржа аккаунта ${nameAccount} изменена` }),
	updateChargeErrorMSG: () => ({
		type: 'error',
		message: 'При изменении маржи произошла ошибка, повторите попытку позже',
	}),
	updateBalanceSuccessMSG: (nameAccount) => ({ type: 'success', message: `Баланс аккаунта ${nameAccount} изменён` }),
	updateBalanceErrorMSG: () => ({
		type: 'error',
		message: 'При изменении баланса произошла ошибка, повторите попытку позже',
	}),
	noSelectedActionWarningMSG: () => ({ type: 'warning', message: 'Выберите действие' }),

	/**
	 * src\views\agency\AccountSettings
	 * метод по настройке гостевых доступов saveGuestConfig
	 * метод по сохранению гостевого пароля addGuestPassword
	 * метод по удалению гостевого пароля removeGuestPassword
	 * метод по изменению пароля для аккаунта saveNewMainPassword
	 */
	saveSettingsSuccessMSG: () => ({ type: 'success', message: 'Настройки гостевых доступов сохранены' }),
	saveSettingsErrorMSG: () => ({
		type: 'error',
		message: 'При изменении настроек произошла ошибка, повторите попытку позже',
	}),
	guestPasswordSuccessMSG: () => ({ type: 'success', message: 'Гостевой пароль добавлен' }),
	guestPasswordErrorMSG: () => ({
		type: 'error',
		message: 'При добавлении пароля произошла ошибка, повторите попытку позже',
	}),
	questPasswordMinLengthWarningMSG: () => ({
		type: 'warning',
		message: 'Минимальная длина пароля должна быть не менее 4 символов',
	}),
	removeGuestPasswordSuccessMSG: () => ({ type: 'success', message: 'Гостевой пароль удалён' }),
	removeGuestPasswordErrorMSG: () => ({
		type: 'error',
		message: 'При удалении пароля произошла ошибка, повторите попытку позже',
	}),
	updateMainPasswordSuccessMSG: () => ({ type: 'success', message: 'Пароль изменён' }),
	updateMainPasswordErrorMSG: () => ({
		type: 'error',
		message: 'При изменении пароля произошла ошибка, повторите попытку позже',
	}),
	noEmptyFieldWarningMessage: () => ({ type: 'warning', message: 'Поле не должно быть пустым' }),
	passwordMinLengthErrorMSG: () => ({
		type: 'error',
		message: 'Минимальная длина пароля должна быть не менее 6 символов',
	}),
	noEmptyPasswordFieldErrorMessage: () => ({ type: 'error', message: 'Паролль не должен быть пустым' }),
	noSpacesPasswordFieldErrorMessage: () => ({ type: 'error', message: 'Пароль не должен содержать пробелы' }),
	noCyrillicPasswordFieldErrorMessage: () => ({ type: 'error', message: 'Допускаются только латинские символы' }),
	passwordsNotEqualErrorMessage: () => ({ type: 'error', message: 'Пароли должны совпадать' }),
	/**
	 * src\components\specified\CampaignStatistics\PStatisticBoxPlatformsTab
	 * метод по применению настройки блока saveBanSettings
	 */
	saveBanSettingsSuccessMSG: () => ({ type: 'success', message: 'Площадки успешно заблокированы' }),
	saveBanSettingsErrorMSG: () => ({
		type: 'error',
		message: 'При блоке площадок произошла ошибка, повторите попытку позже',
	}),

	/**
	 * src\components\specified\CampaignStatistics\PStatisticBoxBannersTab
	 * метод editBanner по изменению ставки креатива
	 * метод removeBanner по удалению объявления
	 */
	editRateBannerErrorMSG: () => ({
		type: 'error',
		message: 'При изменении ставки произошла ошибка, повторите попытку позже',
	}),
	editCreativeBannerSuccessMSG: (creativeName) => ({
		type: 'success',
		message: `Ставка для креатива ${creativeName} успешно изменена`,
	}),
	removeBannerErrorMSG: () => ({
		type: 'error',
		message: 'При удалении объявления произошла ошибка, повторите попытку позже',
	}),
	removeBannerStatisticErrorMSG: () => ({
		type: 'error',
		message: 'Ошибка. Нельзя удалить объявление, если по нему уже есть статистика торгов',
	}),
	removeBannerExtraErrorMSG: () => ({
		type: 'error',
		message: 'Ошибка. Нельзя удалить объявление, если у него есть дополнительные объявления.',
	}),
	removeBannerSuccessMSG: () => ({ type: 'success', message: 'Объявление удалено' }),

	/**
	 * src\store\modules\campaign\campaign-banners.js
	 * action createBanner по созданию объявления для страницы создания кампании
	 * action editBanner по редактированию объявления для страницы создания кампании
	 * action saveBanner по добавлении и редактированию объявления без сохранения
	 * action removeBanner по удалению объявления
	 */
	createBannerErrorMSG: () => ({
		type: 'error',
		message: 'При создании объявления произошла ошибка, повторите попытку позже',
	}),
	minCpmBannerErrorMSG: (cpm) => ({ type: 'error', message: `Установленная ставка для объявления меньше минимальной. Минимальная ставка: ${cpm}` }),
	createBannerSuccessMSG: () => ({ type: 'success', message: 'Объявление успешно создано' }),
	editBannerErrorMSG: () => ({
		type: 'error',
		message: 'При редактировании объявления произошла ошибка, повторите попытку позже',
	}),
	editBannerSuccessMSG: () => ({ type: 'success', message: 'Настройки объявления успешно сохранены' }),
	createBannerForCreatingCampaignWarningMSG: (bannerName) => (
		{ type: 'warning', message: `Объявление ${bannerName} добавлено, не забудьте сохранить изменения` }
	),
	editBannerForCreatingCampaignWarningMSG: (bannerName) => (
		{ type: 'warning', message: `Объявление ${bannerName} обновлено, не забудьте сохранить изменения` }
	),
	removeBannerForCreatingCampaignSuccessMSG: (bannerName) => ({
		type: 'success',
		message: `Объявление ${bannerName} успешно удалено`,
	}),
	removeBannerForCreatingCampaignErrorMSG: () => ({
		type: 'error',
		message: 'При удалении объявления произошла ошибка, повторите попытку позже',
	}),
	bannerWithStatisticsErrorMSG: () => (
		{ type: 'error', message: 'Ошибка. Нельзя удалить объявление, если по нему уже есть статистика торгов' }
	),
	targetCampaignNotValidErrorMSG: () => ({
		type: 'error',
		message: 'Невалидный ID рекламной кампании',
	}),
	/**
	 * src\components\Inventory\PBigListsTableBox
	 * метод editBigList по редактированию списка площадок на странице инвентарь
	 * метод removeBigList по удалению списка на странице инвентарь
	 * метод removeSelectedDomainsFromBigListContentTable по удалению списка доменов при редактировании
	 * метод removeOneDomainFromBigListContentTable по удалению одного домена при редактировании
	 */
	editBigListSuccessMSG: () => ({ type: 'success', message: 'Изменения успешно сохранены' }),
	editBigListErrorMSG: () => ({ type: 'error', message: 'При редактировании произошла ошибка, повторите попытку позже' }),
	removeBigListErrorMSG: () => ({ type: 'error', message: 'При удалении произошла ошибка' }),
	removeBigListSuccessMSG: () => ({ type: 'success', message: 'Список успешно удален' }),
	removeSelectedDomainsFromBigListContentTableSuccessMSG: () => ({
		type: 'success',
		messages: 'Выбранные домены удалены,  не забудьте сохранить изменения',
	}),
	removeOneDomainFromBigListContentTableSuccessMSG: () => ({
		type: 'success',
		messages: 'Домен удалён, не забудьте сохранить изменения',
	}),
	/**
	 * src\components\Inventory\PDomainsTableBox
	 * метод createDomainsList по созданию списка площадок на странице инвентарь
	 */
	noNameDomainsListWarningMSG: () => ({ type: 'warning', message: 'Введите имя списка площадок' }),
	noNameIpListWarningMSG: () => ({ type: 'warning', message: 'Введите имя списка IP' }),
	noSpecialSymbolsDomainsListWarningMSG: () => ({
		type: 'warning',
		message: 'Название списка площадок не должно содержать специальные символы',
	}),
	noSpecialSymbolsIpListWarningMSG: () => ({
		type: 'warning',
		message: 'Название списка IP не должно содержать специальные символы',
	}),
	emptyListAreasWarningMSG: () => ({ type: 'warning', message: 'Не выбрано ни одной площадки' }),
	emptyIpListWarningMSG: () => ({ type: 'warning', message: 'Список IP пуст' }),
	createBigListErrorMSG: () => ({ type: 'error', message: 'При создании произошла ошибка' }),
	createBigListSuccessMSG: () => ({ type: 'success', message: 'Список площадок успешно создан' }),
	createIpBigListSuccessMSG: () => ({ type: 'success', message: 'Список IP успешно создан' }),
	emptyListDomainsWarningMSG: () => ({ type: 'warning', message: 'Не выбрано ни одного домена' }),
	/**
	 * src\components\specified\CampaignStatistics\PStatusBox
	 * метод updateCampaignStatus по изменению состояния кампании
	 * метод copyCampaign по созданию копии кампании
	 */
	noNameCopyCampaignWarningMSG: () => ({ type: 'warning', message: 'Введите имя кампании' }),
	specialSymbolsCopyCampaingNameWarningMSG: () => ({
		type: 'warning',
		message: 'Имя кампании не должно содержать специальные символы',
	}),
	copyCampaignDataErrorMSG: () => ({
		type: 'error',
		message: 'При копировании кампании произошла ошибка, повторите попытку позже',
	}),
	createCampaignCloneSuccessMSG: () => ({ type: 'success', message: 'Кампания успешно скопирована' }),

	/**
	 * src\store\modules\campaign-statistics.js
	 * action updateCampaignStatus
	 */
	updateCampaignStatusErrorMSG: () => ({
		type: 'error',
		message: 'При изменении статуса произошла ошибка, повторите попытку позже',
	}),
	updateCampaignStatusSuccessMSG: (statusForMessage) => ({
		type: 'success',
		message: `Кампания успешно ${statusForMessage}`,
	}),

	/**
	 * src\store\modules\inventory.js
	 * action getAllDomains
	 */
	getAllDomainsErrorMSG: () => ({
		type: 'error',
		message: 'При получении доменов произошла ошибка. Повторите попытку позже',
	}),

	/**
	 * src\components\PlayerCustomization\PlayerCustomizationPopup
	 * метод createConfig по созданию шаблон кастомизации плеера
	 * метод saveConfig по редактированию шаблон кастомизации плеера
	 */
	noNameConfigErrorMSG: () => ({ type: 'error', message: 'Невозможно сохранить конфигурацию без имени' }),
	createCustomizationConfigErrorMSG: () => ({
		type: 'error',
		message: 'При создании шаблона кастомизации произошла ошибка, повторите попытку позже',
	}),
	createCustomizationConfigSuccessMSG: (configName) => ({
		type: 'success',
		message: `Шаблон кастомизации ${configName} успешно создан`,
	}),
	editCustomizationConfigErrorMSG: () => ({
		type: 'error',
		message: 'При сохранении шаблона кастомизации произошла ошибка, повторите попытку позже',
	}),
	editCustomizationConfigSuccessMSG: (configName) => ({
		type: 'success',
		message: `Шаблон кастомизации ${configName} успешно обновлен`,
	}),
	maxFileSizeErrorMSG: (fileSize, fileSizeUnit) => ({
		type: 'error',
		message: `Максимальный размер файла ${fileSize} ${fileSizeUnit}`,
	}),
	specialSymbolsCustomizationConfigNameErrorMSG: () => ({
		type: 'error',
		message: 'Название шаблона не должно содержать специальные символы',
	}),
	playerUploadingErrorMSG: () => ({
		type: 'error',
		message: 'При загрузке плеера произошла ошибка',
	}),
	updateCartItemsErrorMSG: () => ({
		type: 'error',
		message: 'При редактировании механики витрин произошла ошибка, повторите попытку позже',
	}),
	updateCartItemsSuccessMSG: () => ({
		type: 'success',
		message: 'Механика витрин успешна изменена',
	}),
	cartItemsBtnTextErrorMSG: () => ({
		type: 'error',
		message: 'Введите текст для кнопки перехода',
	}),
	cartItemsDescriptionErrorMSG: () => ({
		type: 'error',
		message: 'Поле описание должно быть заполнено',
	}),
	cartItemsBannerErrorMSG: () => ({
		type: 'error',
		message: 'Загрузите баннер товара',
	}),
	cartItemsUrlErrorMSG: () => ({
		type: 'error',
		message: 'Некорректная ссылка на посадочную страницу',
	}),
	cartItemsTrackingsErrorMSG: (trackingKey) => ({ type: 'error', message: `Некорректная ссылка трекинга ${trackingKey}` }),
	overlayTrackingsErrorMSG: (trackingKey) => ({ type: 'error', message: `Некорректная ссылка трекинга ${trackingKey}` }),
	overlayUrlErrorMSG: () => ({
		type: 'error',
		message: 'Некорректная ссылка на посадочную страницу для кнопки',
	}),
	logoTrackingsErrorMSG: (trackingKey) => ({ type: 'error', message: `Некорректная ссылка трекинга ${trackingKey}` }),
	logoUrlErrorMSG: () => ({
		type: 'error',
		message: 'Некорректная ссылка на посадочную страницу для кнопки',
	}),
	textUrlErrorMSG: () => ({
		type: 'error',
		message: 'Некорректная ссылка на посадочную страницу для текста',
	}),

	/**
	 * src\components\PlayerCustomization\PPlayerCustomizationTableBox
	 * метод removeCustomizationConfig по удалению шаблона кастомизации плеера
	 */
	removeCustomizationConfigErrorMSG: () => ({
		type: 'error',
		message: 'При удалении шаблона кастомизации произошла ошибка, повторите попытку позже',
	}),
	removeCustomizationConfigSuccessMSG: (configName) => ({
		type: 'success',
		message: `Шаблон кастомизации ${configName} успешно удалён`,
	}),

	/**
	 * src\components\UpsertCampaign\PTabs\AdsTab\AdsTab
	 * метод isBannerValid на проверку валидности введенных данных
	 */
	emptyCurrentBannerNameErrorMSG: () => ({ type: 'error', message: 'Введите название объявления' }),
	specialSymbolsBannerNameErrorMSG: () => ({
		type: 'error',
		message: 'Название объявления не должно содержать специальные символы',
	}),
	emptyLinkVastVpaidErrorMSG: () => ({ type: 'error', message: 'Введите VAST/VPAID ссылку' }),
	invalidLinkVastVpaidErrorMSG: () => ({ type: 'error', message: 'Некорректная VAST/VPAID ссылка' }),
	emptyDownloadFileErrorMSG: () => ({ type: 'error', message: 'Загрузите файл объявления' }),
	videoDurationErrorMSG: () => ({ type: 'error', message: 'Укажите длительность видео' }),
	emptyLinkToWebsiteErrorMSG: () => ({ type: 'error', message: 'Введите ссылку на сайт' }),
	invalidLinkToWebsiteErrorMSG: () => ({ type: 'error', message: 'Некорректная ссылка на сайт' }),
	emptyTldErrorMSG: () => ({ type: 'error', message: 'Введите TLD' }),
	invalidTldErrorMSG: () => ({ type: 'error', message: 'Некорректный TLD' }),
	invalidTrackingsErrorMSG: (trackingKey) => ({ type: 'error', message: `Некорректная ссылка трекинга ${trackingKey}` }),
	changeBannersCpmErrorMSG: () => ({ type: 'error', message: 'При изменении ставки произошла ошибка, повторите попытку позже' }),
	changeBannersCpmSuccessMSG: () => ({ type: 'success', message: 'Ставка успешно изменена' }),

	/**
	 * src\components\CampaignsList\PCampaignsListTableBox
	 * метод updateCampaignStatus по обновлению статуса рекламы на странице кампании
	 */
	updateCampaignStatusInTableErrorMSG: () => ({
		type: 'error',
		message: 'При изменении статуса произошла ошибка, повторите попытку позже',
	}),
	updateCampaignStatusInTableSuccessMSG: (campaignName, statusForMessage) => (
		{ type: 'success', message: `Кампания ${campaignName} успешно ${statusForMessage}` }
	),

	/**
	 * src\store\modules\Reports.js
	 * action getFullReport
	 */
	getFullReportWarningMSG: () => ({ type: 'warning', message: 'Отчёт формируется. Это займёт некоторое время' }),
	generateReportSuccessMSG: () => ({ type: 'success', message: 'Отчёт успешно сформирован' }),
	generateReportErrorMSG: () => ({ type: 'error', message: 'При формировании отчёта произошла ошибка' }),

	/**
	 * src\components\UpsertCampaign\PTabs\AdsTab\PAdsTabBannerFormFileUploader
	 * computed -> file()
	 */
	videoCheckResultWarningMSG: (warningMessage) => ({ type: 'warning', message: warningMessage }),

	/**
	 * src\components\Reports\PReportsCampaignsListTableBox
	 * метод getReport
	 */
	getReportWarningMSG: () => ({
		type: 'warning',
		message: 'Для формирования отчёта необходимо выбрать как минимум одну кампанию',
	}),

	/**
	 * src\views\account\UpsertCampaign
	 * метод saveCampaign (создание РК)
	 */
	createCampaignErrorMSG: () => ({
		type: 'error',
		message: 'При создании рекламной кампании произошла ошибка, повторите попытку позже',
	}),
	createCampaignSuccessMSG: (campaignName) => ({
		type: 'success',
		message: `Кампания ${campaignName} успешно создана`,
	}),
	saveCampaignSuccessMSG: () => ({
		type: 'success',
		messages: 'Настройки компании успешно изменены',
	}),
	saveCampaignErrorMSG: () => ({
		type: 'error',
		messages: 'При изменении настроек РК произошла ошибка. Повторите попытку позже',
	}),
	saveCampaignHasStatisticErrorMSG: () => ({
		type: 'error',
		messages: 'Ошибка при изменении основной кампании. У текущей РК есть статистика.',
	}),
	saveCampaignHasBannersErrorMSG: () => ({
		type: 'error',
		messages: 'Ошибка при изменении основной кампании. У текущей РК есть связанные объявления.',
	}),
	/**
	 * src\views\account\UpsertCampaign
	 * метод isCampaignValid
	 */
	validateCampaignIncorrectCalendarDatesErrorMSG: () => ({
		type: 'error',
		message: 'Дата запуска рекламной кампании не должна превышать дату остановки',
	}),
	validateCampaignEmptyNameErrorMSG: () => ({
		type: 'error',
		message: 'Введите название кампании',
	}),
	campaignSspNotSelectedErrorMSG: () => ({
		type: 'error',
		message: 'Не выбрано ни одной SSP',
	}),
	specialSymbolsCampaignNameErrorMSG: () => ({
		type: 'error',
		message: 'Название кампании не должно содержать специальные символы',
	}),
	minCampaignNameErrorMSG: () => ({
		type: 'error',
		message: 'Название кампании должно содержать минимум 4 символа',
	}),
	emptyCampaignDeviceTypesErrorMSG: () => ({
		type: 'error',
		message: 'Выберите тип устройства',
	}),
	/**
	 * src\views\unauthorized\PreviewLauncher
	 * lifecycle hooks - mounted
	 * метод createDemoPage
	 */
	disableAdBlockWarningMSG: () => ({
		type: 'warning',
		message: 'Для корректной работы страницы необходимо отключить AdBlock',
	}),
	createDemoPageErrorMSG: () => ({ type: 'error', message: 'Загрузите файл для создания демо' }),
	copiedToClipBoardSuccessMSG: () => ({ type: 'success', message: 'Ссылка скопирована в буфер обмена' }),

	/**
	 * src\views\unauthorized\PreviewDemoPage
	 * метод createDemoPage
	 */
	maxVideoDurationErrorMSG: (seconds) => ({
		type: 'error',
		message: `Длительность видео не может превышать ${seconds} секунд`,
	}),
	fileNotFoundErrorMSG: () => ({ type: 'error', message: 'Файл не обнаружен' }),

	/**
	 * src\components\AccountsList\PAccountsListTableBox
	 * метод setCharge
	 */
	noChangesWarningMSG: () => ({ type: 'warning', message: 'Маржа не была изменена' }),

	/**
	 * src\components\specified\CampaignStatistics\PCampaignLogs
	 * метод getCampaignLogs
	 */
	getCampaignLogsErrorMSG: () => ({
		type: 'error',
		message: 'При выгрузке подробной статистики произошла ошибка. Повторите попытку позже',
	}),

	/**
	 * src\views\account\Retargeting\SegmentsTableBox
	 */
	emptySegmentNameErrorMSG: () => ({
		type: 'error',
		message: 'Введите название сегмента',
	}),
	noSpecialSymbolsSegmentsNameWarningMSG: () => ({
		type: 'warning',
		message: 'Название сегмента не должно содержать специальные символы',
	}),
	createRetargetingSegmentErrorMSG: () => ({
		type: 'error',
		message: 'При создании сегмента произошла ошибка, повторите попытку позже',
	}),
	retargetingSegmentSameNameErrorMSG: () => ({
		type: 'error',
		message: 'Сегмент с таким названием уже существует',
	}),
	editRetargetingSegmentErrorMSG: () => ({
		type: 'error',
		message: 'При редактировании сегмента произошла ошибка, повторите попытку позже',
	}),
	createRetargetingSegmentSuccessMSG: (segmentName) => ({
		type: 'success',
		message: `Сегмент ${segmentName} успешно создан`,
	}),
	removeUsedRetargetingSegmentErrorMSG: (campaignName) => ({
		type: 'error',
		message: `Сегмент нельзя удалить, т.к. он привязан к кампании ${campaignName}`,
	}),
	removeRetargetingSegmentErrorMSG: () => ({
		type: 'error',
		message: 'При удалении сегмента произошла ошибка, повторите попытку позже',
	}),
	removeRetargetingSegmentSuccessMSG: (segmentName) => ({
		type: 'success',
		message: `Сегмент ${segmentName} успешно удален`,
	}),
	editRetargetingSegmentSuccessMSG: () => ({
		type: 'success',
		message: 'Сегмент успешно изменен',
	}),
	copyRetargetingCodeSuccessMSG: () => ({
		type: 'success',
		message: 'Код для вставки успешно скопирован',
	}),
	copyRetargetingCodeErrorMSG: () => ({
		type: 'error',
		message: 'При копировании произошла ошибка, повторите попытку позже',
	}),
	recalculateSegmentCountOfUsersSuccessMSG: () => ({
		type: 'success',
		message: 'Мы приступили к пересчету количества пользователей. Это займет некоторое время',
	}),
	uploadUsersInSegmentErrorMSG: () => ({
		type: 'error',
		message: 'При загрузке пользователей произошла ошибка, повторите попытку позже',
	}),
	uploadUsersInSegmentSuccessMSG: () => ({
		type: 'success',
		message: 'Мы приступили к добавлению пользователей в сегмент. Это займет некоторое время',
	}),
	/**
	 * src\views\account\Retargeting\CampaignsTableBox
	 */
	bindRetargetingSegmentSuccessMSG: () => ({
		type: 'success',
		message: 'Изменения успешно сохранены',
	}),
	bindRetargetingSegmentErrorMSG: () => ({
		type: 'error',
		message: 'При сохранении изменений произошла ошибка, повторите попытку позже',
	}),
	/**
	 * src\views\account\GroupsManagement\UpsertGroupPopup
	 */
	emptyGroupNameErrorMSG: () => ({
		type: 'error',
		message: 'Введите название группы',
	}),
	noSpecialSymbolsGroupNameWarningMSG: () => ({
		type: 'warning',
		message: 'Название группы не должно содержать специальные символы',
	}),
	createGroupSuccessMSG: (groupName) => ({
		type: 'success',
		message: `Группа ${groupName} успешно создана`,
	}),
	createGroupErrorMSG: () => ({
		type: 'error',
		message: 'При создании группы произошла ошибка, повторите попытку позже',
	}),
	editGroupSuccessMSG: (groupName) => ({
		type: 'success',
		message: `Настройки группы ${groupName} успешно изменены`,
	}),
	editGroupErrorMSG: () => ({
		type: 'error',
		message: 'При изменении настроек группы произошла ошибка, повторите попытку позже',
	}),
	removeGroupSuccessMSG: (groupName) => ({
		type: 'success',
		message: `Группа ${groupName} успешно удалена`,
	}),
	removeGroupErrorMSG: () => ({
		type: 'error',
		message: 'При удалении группы произошла ошибка, повторите попытку позже',
	}),
	/**
	 * src\views\account\GroupsSettings\GroupCampaignsTableBox\UpsertGroupCampaignsTableBox
	 */
	upsertGroupCampaignsSuccessMSG: (groupName) => ({
		type: 'success',
		message: `Содержание группы ${groupName} успешно изменено`,
	}),
	upsertGroupCampaignsErrorMSG: () => ({
		type: 'error',
		message: 'При изменении содержания группы произошла ошибка, повторите попытку позже',
	}),

	/**
	 * src\views\account\GroupsSettings\GroupCampaignsTableBox\index
	 */
	removeGroupCampaignsSuccessMSG: (campaignName) => ({
		type: 'success',
		message: `Кампания ${campaignName} успешно удалена из группы`,
	}),
	removeGroupCampaignsErrorMSG: () => ({
		type: 'error',
		message: 'При удалении кампании из группы произошла ошибка, повторите попытку позже',
	}),
	upsertGroupCampaignLimitsSuccessMSG: (campaignName) => ({
		type: 'success',
		message: `Ограничения для кампании ${campaignName} успешно изменены`,
	}),
	upsertGroupCampaignLimitsErrorMSG: () => ({
		type: 'error',
		message: 'При изменении ограничений кампании произошла ошибка, повторите попытку позже',
	}),

	/**
	 * src\views\account\GroupsSettings\GroupCampaignsTableBox\GroupControls
	 */
	upsertGroupStatusSuccessMSG: (groupName, status) => ({
		type: 'success',
		message: status === 'paused' ? `Группа ${groupName} успешно запущена` : `Группа ${groupName} успешно остановлена`,
	}),
	upsertGroupStatusErrorMSG: () => ({
		type: 'error',
		message: 'При изменении статуса группы произошла ошибка, повторите попытку позже',
	}),

	/**
	 * src\helpers\validator
	 */
	emptyNameBrandLiftErrorMSG: () => ({ type: 'error', message: 'Введите название брендлифта' }),
	specialSymbolsNameBrandLiftErrorMSG: () => ({ type: 'error', message: 'Название брендлифта не должно содержать специальные символы' }),
	emptySlideQuestionBrandLiftErrorMSG: (slideIndex) => ({
		type: 'error',
		message: `Введите вопрос для ${slideIndex + 1}-го слайда`,
	}),
	emptySlideAnswersBrandLiftErrorMSG: (slideIndex) => ({
		type: 'error',
		message: `Введите все ответы для ${slideIndex + 1}-го слайда`,
	}),

	/**
	 * src\helpers\panelSegmentsValidator.js
	 */
	emptyGenderPanelInfoErrorMSG: () => ({ type: 'error', message: 'Выберите соц.дем' }),
	emptyAgePanelInfoErrorMSG: () => ({ type: 'error', message: 'Выберите возраст' }),
	emptyIncomePanelInfoErrorMSG: () => ({ type: 'error', message: 'Выберите доход' }),
	emptyProviderPanelInfoErrorMSG: () => ({ type: 'error', message: 'Не выбран провайдер' }),
	emptyCategoryPanelInfoErrorMSG: () => ({ type: 'error', message: 'Выберите категорию' }),

	emptyDomainsPanelCreateErrorMSG: () => ({ type: 'error', message: 'Список доменов пуст. Получите информацию или измените фильтры' }),
	emptyDataPanelCreateErrorMSG: () => ({ type: 'error', message: 'Для создания сегмента, необходимо сначала получить информацию. Нажмите на кнопку Показать' }),
	emptySegmentNamePanelCreateErrorMSG: () => ({ type: 'error', message: 'Введите название сегмента' }),
	specialSymbolsSegmentNamePanelCreateErrorMSG: () => ({ type: 'error', message: 'Название сегмента не должно содержать специальные символы' }),
	getDomainsCountByEmotionalBackgroundErrorMSG: () => ({ type: 'error', message: 'Не удалось получить прогнозируемое количество доменов' }),

	/**
	 * src\views\account\Tools\BrandLifts\BrandLiftPopup\index.vue
	 */
	createBrandLiftSuccessMSG: (name) => ({
		type: 'success',
		message: `Брендлифт ${name} успешно создан`,
	}),
	createBrandLiftErrorMSG: () => ({
		type: 'error',
		message: 'При создании брендлифта произошла ошибка, повторите попытку позже',
	}),
	editBrandLiftSuccessMSG: (name) => ({
		type: 'success',
		message: `Брендлифт ${name} успешно изменен`,
	}),
	editBrandLiftErrorMSG: () => ({
		type: 'error',
		message: 'При редактировании брендлифта произошла ошибка, повторите попытку позже',
	}),

	/**
	 * src\views\account\ProviderPanelSegments\index.vue
	 */
	removePanelSegmentErrorMSG: (segmentName) => ({
		type: 'error',
		message: `При удалении сегмента ${segmentName} произошла ошибка, повторите попытку позже`,
	}),
	removePanelSegmentSuccessMSG: (segmentName) => ({
		type: 'success',
		message: `Сегмент ${segmentName} успешно удален`,
	}),

	/**
	 * src\views\account\ProviderPanelUpsert\PanelSideBar\index.vue
	 */
	getSegmentInfoErrorMSG: () => ({
		type: 'error',
		message: 'При попытке получения информации о сегменте произошла ошибка, повторите попытку позже',
	}),
	emptySegmentInfoErrorMSG: () => ({
		type: 'error',
		message: 'По данным категориям нет данных, попробуйте изменить выбранные категории или аффинити',
	}),
	getSegmentInfoSuccessMSG: () => ({
		type: 'success',
		message: 'Информация о сегменте успешно получена',
	}),

	/**
	 * src\views\account\ProviderPanelUpsert\PanelCreateSegmentPopup\index.vue
	 */
	createPanelSegmentErrorMSG: (segmentName) => ({
		type: 'error',
		message: `При создании сегмента ${segmentName} произошла ошибка, повторите попытку позже`,
	}),
	createPanelSegmentAlreadyExistErrorMSG: (segmentName) => ({
		type: 'error',
		message: `Сегмент с именем ${segmentName} уже существует`,
	}),
	createPanelSegmentSuccessMSG: (segmentName) => ({
		type: 'success',
		message: `Сегмент ${segmentName} успешно создан`,
	}),

	exportSegmentErrorMSG: (segmentName) => ({ type: 'success', message: `При выгрузке сегмента ${segmentName} произошла ошибка, повторите попытку позже.` }),

	exportStatisticsErrorMSG: () => ({
		type: 'success',
		message: 'При выгрузке статистики по интерактивам с механикой витрин произошла ошибка, повторите попытку позже.',
	}),

	/**
	 * src\views\agency\AccountSettings\GuestAccessPricesTable
	 */

	campaignGuestAccessPricesUpdateErrorMSG: () => ({
		type: 'error',
		message: 'При изменении цен гостевых доступов произошла ошибка, повторите попытку позже.',
	}),

	campaignGuestAccessPricesUpdateSuccessMSG: () => ({
		type: 'success',
		message: 'Цены гостевых доступов успешно изменены.',
	}),

	campaignGuestAccessPricesNoChangesWarningMSG: () => ({
		type: 'warning',
		message: 'Цены на гостевые доступы кампании не были изменены.',
	}),

	// Update user cabinet favorite messages
	addUserCabinetToFavoritesErrorMSG: () => ({
		type: 'error',
		message: 'При добавления кабинета в избранное произошла ошибка. Повторите попытку позже',
	}),
	addUserCabinetToFavoritesSuccessMSG: () => ({
		type: 'success',
		message: 'Кабинет успешно добавлен в избранное',
	}),
	removeUserCabinetFromFavoritesErrorMSG: () => ({
		type: 'error',
		message: 'При удалении кабинета из избранного произошла ошибка. Повторите попытку позже',
	}),
	removeUserCabinetFromFavoritesSuccessMSG: () => ({
		type: 'success',
		message: 'Кабинет успешно удален из избранного',
	}),
};

const showMessage = (messageKey, ...props) => notify(...Object.values(messages[messageKey](...props)).reverse());

export default showMessage;
