export default {
	namespaced: true,
	state: () => ({
		bigList: [],
		bigLists: {
			domain: {
				white: [],
				black: [],
			},
		},
	}),
	getters: {
		bigList: (state) => state.bigList,
		selectedDomainsLists: (state) => (type) => state.bigLists.domain[type],
	},
	mutations: {
		UPDATE_BIG_LIST: (state, data) => {
			state.bigList = data;
		},
		UPDATE_SELECTED_DOMAINS_BIG_LISTS_BY_TYPE: (state, { type, ids }) => {
			state.bigLists.domain[type] = ids;
		},
		UPDATE_SELECTED_DOMAINS: (state, campaignDomainsBigLists) => {
			state.bigLists.domain.white = !campaignDomainsBigLists.white || (campaignDomainsBigLists.white.length === 1 && campaignDomainsBigLists.white[0] === '')
				? []
				: campaignDomainsBigLists.white;
			state.bigLists.domain.black = !campaignDomainsBigLists.black || (campaignDomainsBigLists.black.length === 1 && campaignDomainsBigLists.black[0] === '')
				? []
				: campaignDomainsBigLists.black;
		},
		SET_DEFAULT_DOMAIN_LIST(state) {
			state.bigLists.domain.white = [];
			state.bigLists.domain.black = [];
		},
	},
	actions: {
		async getBigList({ commit }) {
			const [err, data] = await this.$fetch.bigListAPI.getAllDomainsBigList();
			if (err) {
				commit('UPDATE_BIG_LIST', []);
				return Promise.reject(err);
			}
			commit('UPDATE_BIG_LIST', data);
			return Promise.resolve();
		},
	},
};
