export default {
	namespaced: true,
	state: () => ({
		fraudScoreName: '',
	}),
	getters: {
		resultFraudScoreName: (state) => state.fraudScoreName,
	},
	mutations: {
		SET_DEFAULT_FRAUD_SCORE_NAME(state) {
			state.fraudScoreName = '';
		},
		UPDATE_FRAUD_SCORE_NAME(state, payload) {
			state.fraudScoreName = payload;
		},
	},
};
