import MainLayout from '@/layouts/MainLayout';
import GradientLayout from '@/layouts/GradientLayout';

const authRoutes = [
	{
		path: 'login',
		redirect: {
			name: 'Login',
		},
		component: GradientLayout,
		children: [
			{
				name: 'Login',
				path: '',
				meta: {
					title: 'Вход в кабинет',
				},
				component: () => import('@/views/authorization/LoginCard'),
			},
		],
	},
	{
		path: 'cabinets',
		component: MainLayout,
		children: [
			{
				name: 'UserCabinets',
				path: '',
				meta: {
					title: 'Кабинеты',
				},
				component: () => import('@/views/authorization/UserCabinets'),
			},
		],
	},
];

export default authRoutes;
