<template>
  <p-zero-state>
    <div class="main-layout-wrapper">
      <p-header
        :is-create-campaign-btn-visible="!isUserCabinetsRoute"
        :is-nav-items-visible="!isUserCabinetsRoute"
      />
      <router-view :key="$route.fullPath" />
      <p-footer />
    </div>
  </p-zero-state>
</template>

<script>
import PHeader from '@/components/PHeader';
import PFooter from '@/components/PFooter';
import PZeroState from '@/components/PZeroState';

export default {
	name: 'MainLayout',
	components: {
		PZeroState,
		PHeader,
		PFooter,
	},
	computed: {
		isUserCabinetsRoute() {
			return this.$route.name === 'UserCabinets';
		},
	},
};
</script>

<style scoped>

</style>
