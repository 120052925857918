import { createStore } from 'vuex';
import User from './modules/user';
import GlobalHooks from '@/store/modules/global-hooks';
import Loading from '@/store/modules/loading';
import FatalError from '@/store/modules/fatal-error';
import PlayerCustomization from './modules/player-customization/player-customization';
import Campaign from './modules/campaign/campaign';
import CampaignList from './modules/campaigns-list';
import CampaignStatistics from './modules/campaign-statistics/campaign-statistics';
import Inventory from './modules/inventory';
import Reports from './modules/reports';
import PreviewDemoPages from './modules/preview-demo-pages';
import Retargeting from './modules/retargeting';
import GroupsManagement from './modules/groupsManagement';
import GroupSettings from './modules/groupSettings';
import GroupStatistics from './modules/groupStatistics';
import BrandLifts from './modules/brandLifts';
import AccountSettings from './modules/account-settings';
import ProviderPanel from './modules/provider-panel/provider-panel';

export default createStore({
	state: () => ({}),
	getters: {},
	mutations: {},
	actions: {},
	modules: {
		User,
		GlobalHooks,
		Loading,
		FatalError,
		PlayerCustomization,
		Campaign,
		CampaignList,
		CampaignStatistics,
		Inventory,
		Reports,
		PreviewDemoPages,
		Retargeting,
		GroupsManagement,
		GroupSettings,
		GroupStatistics,
		BrandLifts,
		AccountSettings,
		ProviderPanel,
	},
	strict: process.env.NODE_ENV !== 'production',
});
