import {
	checkSession, setDefaultZeroStateValues, setPageTitle, getIsGuest, getIsReportsAccount, getIsSelfService,
} from '@/router/utils/router-helpers';

export const noPermissionGuard = (to, from, next) => {
	const { guestAllowed, access } = to.meta;
	const isGuest = getIsGuest();
	const isSelf = getIsSelfService();
	const isSelfServiceAllowed = !isSelf || access.includes('selfService');
	const isReportsAccount = getIsReportsAccount();
	!isSelfServiceAllowed || (!isReportsAccount && (isGuest && !guestAllowed))
		? next({ name: 'NotFound' })
		: next();
};

export const setCabinetIdGuard = (to, from, next) => {
	const { cabinetId } = to.params;
	sessionStorage.setItem('cabinet-id', cabinetId);
	next();
};

export const sessionCheckGuard = async (to, from, next) => {
	await checkSession();
	next();
};

export const wrongParamGuard = (to, from, next) => {
	const { props } = to.meta;
	const pathHasRightProp = Object.values(to.params).some((prop) => props.indexOf(prop) !== -1);
	props && !pathHasRightProp
		? next({ name: 'NotFound' })
		: next();
};

export const beforeEachGuard = async () => {
	setDefaultZeroStateValues();
};

export const afterEachGuard = async (to) => {
	setPageTitle(to);
};
