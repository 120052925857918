class RetargetingAPI {
	constructor(apiCall) {
		this.apiCall = apiCall;
	}

	async getRetargetingSegments() {
		try {
			const retargetingSegments = await this.apiCall.get({
				gate: '/retargeting/segments',
				isFatal: true,
			});
			retargetingSegments.segmentsCountItems.forEach((segment) => {
				if (!segment.countUsers) {
					segment.countUsers = 0;
				}
			});
			return [null, retargetingSegments.segmentsCountItems];
		} catch (err) {
			return [err];
		}
	}

	async createSegment({ name, ttl }) {
		try {
			await this.apiCall.post('/retargeting/segment', { name, ttl });
			return [null];
		} catch (err) {
			return [err];
		}
	}

	async editSegment(segmentData) {
		try {
			await this.apiCall.put('/retargeting/segment', segmentData);
			return [null];
		} catch (err) {
			return [err];
		}
	}

	async recalculateCountOfUsers(segmentsIds) {
		try {
			await this.apiCall.put('/retargeting/segment/recalculation', { ids: segmentsIds });
			return [null];
		} catch (err) {
			return [err];
		}
	}

	async bindSegment(data) {
		const requestData = {
			campaignID: data.campaignID,
			options: {
				win: data.options.win ? [data.options.win] : [],
				click: data.options.click ? [data.options.click] : [],
			},
		};
		try {
			await this.apiCall.post('/retargeting/options', requestData);
			return [null];
		} catch (err) {
			return [err];
		}
	}

	async removeSegment(segmentID) {
		try {
			await this.apiCall.delete(`/retargeting/segment/${segmentID}`);
			return [null];
		} catch (err) {
			return [err];
		}
	}

	async uploadUsersForSegment({ id, fileUserIds }) {
		try {
			await this.apiCall.post(`/retargeting/segment/users-upload/${id}`, { fileUserIds });
			return [null];
		} catch (err) {
			return [err];
		}
	}
}

export default RetargetingAPI;
