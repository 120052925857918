class GroupAPI {
	constructor(apiCall) {
		this.apiCall = apiCall;
	}

	async getGroups() {
		try {
			const result = await this.apiCall.get({
				gate: '/groups',
				isFatal: true,
			});
			return [null, result];
		} catch (err) {
			return [err];
		}
	}

	async getGroupById(groupId) {
		try {
			const result = await this.apiCall.get({
				gate: `/group/${groupId}`,
				isFatal: true,
			});
			return [null, result];
		} catch (err) {
			return [err];
		}
	}

	async getCampaignsByGroupId(groupId) {
		try {
			const result = await this.apiCall.get({
				gate: `/group/campaigns/${groupId}`,
				isFatal: true,
			});
			return [null, result];
		} catch (err) {
			return [err];
		}
	}

	async getGroupStatistics(groupId, startTime, endTime) {
		try {
			const result = await this.apiCall.get({
				gate: `/group/statistics/${groupId}`,
				params: { startTime, endTime },
				isFatal: true,
			});
			return [null, result];
		} catch (err) {
			return [err];
		}
	}

	async createGroup(groupData) {
		try {
			await this.apiCall.post('/group', groupData);
			return [null];
		} catch (err) {
			return [err];
		}
	}

	async updateGroup(groupData) {
		try {
			await this.apiCall.put('/group', groupData);
			return [null];
		} catch (err) {
			return [err];
		}
	}

	async updateGroupStatus(groupID, status) {
		try {
			await this.apiCall.put('/group/status', { groupID, status });
			return [null];
		} catch (err) {
			return [err];
		}
	}

	async updateCampaignLimits(groupID, campaignData) {
		const upsertCampaignLimitsRequestData = {
			groupID,
			limitsByCampaign: {},
		};
		upsertCampaignLimitsRequestData.limitsByCampaign[campaignData.campaignID] = {
			totalBudget: campaignData.totalBudget,
			dayBudget: campaignData.dayBudget,
		};
		try {
			await this.apiCall.put('/group/campaign-limits', upsertCampaignLimitsRequestData);
			return [null];
		} catch (err) {
			return [err];
		}
	}

	async updateGroupCampaigns(groupID, campaignIDs) {
		const requestData = {
			campaignIDs,
			group: groupID,
		};
		try {
			await this.apiCall.put('/group/campaigns', requestData);
			return [null];
		} catch (err) {
			return [err];
		}
	}

	async removeGroup(groupId) {
		try {
			await this.apiCall.delete('/group', { id: groupId });
			return [null];
		} catch (err) {
			return [err];
		}
	}
}

export default GroupAPI;
