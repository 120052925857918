<template>
  <div
    v-if="linksByRole.length > 1"
    class="header-links-wrapper"
  >
    <div
      v-for="link in linksByRole"
      :key="link.meta.title"
      class="header-links-container"
      :class="{ 'header-links-container_enormous': linksByRole.length > 6 }"
    >
      <router-link
        :to="{ name: link.name }"
        class="header-links-link"
        active-class="header-links-active-link"
      >
        {{ link.meta.title }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'PHeaderNavItems',
	data() {
		return {
			routesForLinks: [],
			routeNames: ['CampaignsList', 'Tools', 'Inventory', 'AccountsList', 'Reports', 'Retargeting', 'BrandLifts', 'ProviderPanelSegments'],
		};
	},
	computed: {
		...mapGetters({
			isReportsAccount: 'GlobalHooks/isReportsAccount',
		}),
		userAccess() {
			return this.$store.state.User.access;
		},
		userRole() {
			return this.$store.state.User.role;
		},
		isGuest() {
			return this.$store.state.User.isGuest;
		},
		linksByRole() {
			return this.routesForLinks.filter((route) => {
				const { roleRequired, access, guestAllowed } = route.meta;

				const generalCheck = access.includes(this.userAccess) && roleRequired === this.userRole;

				const guestCheck = !this.isGuest || (this.isGuest && guestAllowed);

				const routesForReportsAccount = route.name === 'Reports' || route.name === 'ProviderPanelSegments';
				const reportsAccountCondition = this.isReportsAccount && routesForReportsAccount;

				const routeVisible = (generalCheck && guestCheck) || reportsAccountCondition;
				return routeVisible;
			});
		},
	},
	mounted() {
		this.routesForLinks = this.$router.getRoutes().filter((route) => this.routeNames.includes(route.name));
	},
	methods: {},
};
</script>

<style lang="less" scoped>
@import url('~@/assets/style/variables');

.header-links {
	&-wrapper {
		position: absolute;
		left: 50%;
		transform: translate(-50%, 0);
		display: flex;
		height: @header-height;
	}

	&-container {
		height: 100%;
		padding: 0 10px;
		display: flex;
		align-items: center;
	}

	&-link {
		text-decoration: none;
		font-size: 15px;
		font-weight: bold;
		color: #ffffff;
		text-transform: uppercase;

		&:hover {
			color: @secondary-color;
		}
	}

	&-active-link {
		color: @secondary-color;
	}
}

.header-links-container_enormous {
	padding: 0 8px;

	.header-links-link {
		font-size: 12px;
	}
}
</style>
