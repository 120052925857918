import { MAX_VIDEO_FILE_SIZE } from '@/constants/maxVideoFileSize';

export function toggleSlider(currentIndex, total, repeat) {
	let index = currentIndex;
	if (currentIndex === total) {
		index = repeat ? 0 : total - 1;
	}
	if (currentIndex < 0) {
		index = repeat ? total - 1 : 0;
	}
	return index;
}

export function getKeyToObjectByValue(obj, value) {
	let objKey = null;
	Object.keys(obj).forEach((key) => {
		if (obj[key] === value) {
			objKey = key;
		}
	});
	return objKey;
}

/**
 * Форматирование чисел
 * @param {number} number - форматируемое число
 * @param {string} style - принимает одно из трех значений для форматировани 'currency' - валюта, 'percent' - проценты, 'decimal' - числа
 * @param {number} toFixed - количество цифр после десятичной запятой
 */
export function formatNumber(number, style, toFixed = 0) {
	const incomingNumber = !Number.isNaN(+number) ? number : 0;
	const options = {
		style,
		maximumFractionDigits: incomingNumber !== 0 ? toFixed : incomingNumber,
	};
	if (style === 'currency') {
		options.currency = 'RUB';
	}
	return new Intl.NumberFormat('ru-RU', options).format(incomingNumber);
}

export function formatDate(date) {
	return new Intl.DateTimeFormat('ru-RU').format(date);
}

export function sortByField(field, isDescending) {
	function toLowerCaseIfString(value) {
		if (typeof value === 'string') {
			return value.toLowerCase();
		}
		return value;
	}

	if (isDescending === undefined) {
		return (a, b) => (toLowerCaseIfString(a[field]) > toLowerCaseIfString(b[field]) ? 1 : -1);
	}
	return (a, b) => (toLowerCaseIfString(a[field]) < toLowerCaseIfString(b[field]) ? 1 : -1);
}

export function sortByDate() {
	return (a, b) => (new Date(a.date).getTime() > new Date(b.date).getTime() ? 1 : -1);
}

export function getPercents(ratio, total, fixed) {
	return total !== 0 ? +((ratio / total) * 100).toFixed(fixed ?? 0) : 0;
}

export function twoSymbolsString(int) {
	return int <= 9 ? `0${int}` : int;
}

export function resetNumberBoxValue(e) {
	if (e.event.target.value === '') {
		const min = e.component.option('min') ?? 0;
		e.component.option('value', min);
	}
}

export async function getVideoDuration(file) {
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.onload = async (e) => {
			const video = document.createElement('video');
			video.preload = 'metadata';
			video.src = e.target.result;
			video.onloadedmetadata = async () => {
				resolve(Math.round(video.duration));
			};
		};
		reader.readAsDataURL(file);
	});
}

export async function checkVideoFile(file, maxVideoDuration = 45, maxFileSize = MAX_VIDEO_FILE_SIZE) {
	const videoDuration = await getVideoDuration(file);
	if (videoDuration > maxVideoDuration) {
		return { err: `Длительность видео креатива не должна превышать ${maxVideoDuration} секунд` };
	}
	if (file.size > maxFileSize) {
		return { err: 'Размер файла превышает 4Мб. Пожалуйста, выберите другой файл.' };
	}
	return {
		duration: videoDuration,
		file,
		fileType: file.type.split('/')[1],
	};
}

export function translateBannerTrackingsToBackendKeys(trackings) {
	Object.keys(trackings).forEach((key) => {
		trackings[key] = trackings[key].filter((trackingURL) => trackingURL.trim() !== '');
		if (trackings[key].length === 0) {
			trackings[key] = null;
		}
	});
}

export function triggerAbortController(controller) {
	const isOneController = controller instanceof AbortController;
	if (!isOneController) {
		Object.keys(controller).forEach((key) => {
			if (controller[key]) {
				controller[key].abort();
			}
		});
		return;
	}

	controller.abort();
}

/**
 * @description Определяет, содержит ли массив, один или несколько элементов из другого массива
 * @param {array} haystack Массив для поиска.
 * @param {array} targetArray Массив, содержащий элементы для проверки в haystack.
 * @return {boolean} true|false Если haystack содержит один или несколько элементов из targetArray.
 */
export function findOne(targetArray, haystack) {
	return targetArray.some((item) => haystack.includes(item));
}

/**
 * @description Добавляет дополнительный текст к title страницы.
 * @param {string} additionalString Строка которая будет добавлена в title страницы.
 */
export function updatePageTitle(additionalString) {
	const currentPageTitle = document.title;
	document.title = `${currentPageTitle} ${additionalString}`;
}

/**
 * @description Функция для удаления неразрывных пробелов (non-breaking spaces).
 * @param {string} value
 */
export function removeNBSP(value) {
	return typeof value === 'string' ? value.split('').filter((symbol) => symbol !== ' ').join('') : value;
}

export function translatedCampaignStatus(rawStatus) {
	const statusMap = {
		active: 'Активная',
		stopped: 'Остановленная',
		paused: 'На паузе',
	};
	return statusMap[rawStatus];
}

/**
 * @description Функция для формирования query параметров для обвложенных структур.
 */
export function setQuery(obj, parentKey) {
	let localParentKey = '';
	return Object.keys(obj)
		.map((key) => {
			if (typeof obj[key] !== 'object') {
				if (!parentKey) {
					return `${key}=${encodeURIComponent(obj[key])}`;
				}
				return `${parentKey}[${key}]=${encodeURIComponent(obj[key])}`;
			}
			localParentKey = parentKey ? `${parentKey}[${key}]` : key;
			return setQuery(obj[key], localParentKey);
		})
		.flat(Infinity)
		.join('&');
}
