import Categories from './provider-panel-categories';
import Segments from './provider-panel-segments';
import Filters from './provider-panel-filters';
import Statistic from './provider-panel-statistic';
import Domains from './provider-panel-domains';
import showMessage from '@/helpers/messaging-service';
import PanelSegmentValidator from '@/helpers/panelSegmentsValidator';

export default {
	namespaced: true,
	state: () => ({
		name: '',
		affinity: 50,
		lastRequestAffinity: null,
		provider: 'tiburon',
		allProviders: [
			{
				id: 'tiburon',
				name: 'Tiburon',
			},
			{
				id: 'omi',
				name: 'OMI',
			},
		],
	}),
	getters: {
		segmentName: (state) => state.name,
		segmentAffinity: (state) => state.affinity,
		provider: (state) => state.provider,
		requestDataForInfo: (state, getters) => ({
			affinity: state.affinity,
			genderFilters: getters['Filters/selectedFilterIdsByType']('gender').join(','),
			incomeFilters: getters['Filters/selectedFilterIdsByType']('income').join(','),
			ageFilters: getters['Filters/selectedFilterIdsByType']('age').join(','),
			provider: state.provider,
			category: getters['Categories/selectedCategoryBySelectedProvider'].id,
		}),
		requestDataForCreate: (state, getters) => ({
			affinity: state.lastRequestAffinity,
			category: getters['Categories/selectedCategoryBySelectedProvider'].id,
			domains: getters['Domains/domainsForCreateSegment'],
			filters: {
				age: getters['Filters/selectedFilterIdsByType']('age'),
				gender: getters['Filters/selectedFilterIdsByType']('gender'),
				income: getters['Filters/selectedFilterIdsByType']('income'),
			},
			name: state.name,
			provider: state.provider,
		}),
	},
	mutations: {
		SET_DEFAULT_SEGMENT_NAME(state) {
			state.name = '';
		},
		SET_DEFAULT_SEGMENT_AFFINITY(state) {
			state.affinity = 50;
		},
		UPDATE_SEGMENT_NAME(state, payload) {
			state.name = payload;
		},
		UPDATE_SEGMENT_AFFINITY(state, payload) {
			state.affinity = payload;
		},
		UPDATE_LAST_REQUEST_AFFINITY(state, lastRequestAffinity) {
			state.lastRequestAffinity = lastRequestAffinity;
		},
		SET_DEFAULT_LAST_REQUEST_AFFINITY(state) {
			state.lastRequestAffinity = null;
		},
		SET_DEFAULT_PROVIDER(state) {
			state.provider = 'tiburon';
		},
		UPDATE_PROVIDER(state, provider) {
			state.provider = provider;
		},
	},
	actions: {
		setDefaultPanelData({ commit }) {
			commit('SET_DEFAULT_SEGMENT_AFFINITY');
			commit('SET_DEFAULT_PROVIDER');
			commit('SET_DEFAULT_LAST_REQUEST_AFFINITY');
			commit('SET_DEFAULT_SEGMENT_NAME');
			commit('Filters/SET_DEFAULT_SELECTED_FILTERS_BY_TYPE', 'gender');
			commit('Filters/SET_DEFAULT_SELECTED_FILTERS_BY_TYPE', 'age');
			commit('Filters/SET_DEFAULT_SELECTED_FILTERS_BY_TYPE', 'income');
			commit('Categories/SET_DEFAULT_SELECTED_CATEGORIES');
			commit('Categories/SET_DEFAULT_ALL_CATEGORIES');
			commit('Statistic/SET_DEFAULT_STATISTIC_BY_TYPE', 'gender');
			commit('Statistic/SET_DEFAULT_STATISTIC_BY_TYPE', 'age');
			commit('Statistic/SET_DEFAULT_STATISTIC_BY_TYPE', 'income');
			commit('Domains/SET_DEFAULT_DOMAINS');
		},
		async getPanelHelpersData({ dispatch }) {
			await Promise.all([
				dispatch('Filters/getFilters'),
				dispatch('Categories/getCategories'),
			]);
		},
		async getSegmentInfo({ getters, commit }) {
			const requestData = getters.requestDataForInfo;
			if (!PanelSegmentValidator.isSegmentForInfoValid(requestData)) {
				return false;
			}
			const [err, result] = await this.$fetch.categorizerAPI.getSegmentInfo(requestData);
			if (err) {
				showMessage('getSegmentInfoErrorMSG');
				return false;
			}
			if (Object.keys(result).length === 0 || result.domains.length === 0) {
				showMessage('emptySegmentInfoErrorMSG');
				return false;
			}
			commit('UPDATE_LAST_REQUEST_AFFINITY', requestData.affinity);
			commit('Domains/UPDATE_DOMAINS', result.domains);
			commit('Statistic/UPDATE_STATISTIC_BY_TYPE', { type: 'age', statistic: result.age });
			commit('Statistic/UPDATE_STATISTIC_BY_TYPE', { type: 'gender', statistic: result.gender });
			commit('Statistic/UPDATE_STATISTIC_BY_TYPE', { type: 'income', statistic: result.income });
			return !err;
		},
		async createSegment({ getters }) {
			const requestData = getters.requestDataForCreate;
			if (!PanelSegmentValidator.isSegmentForCreateValid(requestData)) {
				return false;
			}
			const [err] = await this.$fetch.categorizerAPI.createSegment(getters.requestDataForCreate);
			if (err) {
				if (err.message.includes('duplicate key error collection')) {
					showMessage('createPanelSegmentAlreadyExistErrorMSG', requestData.name);
					return false;
				}
				showMessage('createPanelSegmentErrorMSG', requestData.name);
				return false;
			}
			return !err;
		},
	},
	modules: {
		Categories,
		Segments,
		Filters,
		Statistic,
		Domains,
	},
};
