export default {
	namespaced: true,
	state: () => ({
		taxonomy: [],
		interests: [],
		interestsNames: [],
		gender: {
			'10:939': {
				value: true,
				title: 'male',
			},
			'10:940': {
				value: true,
				title: 'female',
			},
		},
		ages: {
			'10:935': {
				value: false,
				title: '18-',
			},
			'10:936': {
				value: false,
				title: '19-24',
			},
			'10:937': {
				value: false,
				title: '25-34',
			},
			'10:949': {
				value: false,
				title: '35-44',
			},
			'10:938': {
				value: false,
				title: '45-54',
			},
			'10:950': {
				value: false,
				title: '55+',
			},
		},
	}),
	getters: {
		taxonomy: (state) => JSON.parse(JSON.stringify(state.taxonomy)),
		gender: (state) => state.gender,
		ages: (state) => state.ages,
		isInterestsSelected: (state) => state.interests.length !== 0,
		isAgesSelected: (state, getters) => getters.selectedAges.length !== 0,
		selectedAges: (state) => {
			const selectedAges = [];
			Object.keys(state.ages).forEach((key) => {
				if (state.ages[key].value) {
					selectedAges.push(state.ages[key].title);
				}
			});
			return selectedAges;
		},
		dmpEngine: (state) => {
			const segmentState = { ...state.ages, ...state.gender };
			const dmpEngine = [...state.interests];
			Object.keys(segmentState).forEach((key) => {
				if (segmentState[key].value) {
					dmpEngine.push(key);
				}
			});
			return dmpEngine;
		},
		selectedInterestsNames: (state) => state.interestsNames,
	},
	mutations: {
		UPDATE_GENDER(state, { fieldName, value }) {
			state.gender[fieldName].value = value;
		},
		UPDATE_AGE(state, { fieldName, value }) {
			state.ages[fieldName].value = value;
		},
		SET_DEFAULT_SELECTED_AGES(state) {
			Object.keys(state.ages).forEach((key) => {
				state.ages[key].value = false;
			});
		},
		SET_DEFAULT_SELECTED_GENDER(state) {
			Object.keys(state.gender).forEach((key) => {
				state.gender[key].value = true;
			});
		},
		UPDATE_TAXONOMY(state, taxonomyList) {
			state.taxonomy = taxonomyList;
		},
		CLEAR_SELECTED_TAXONOMY(state) {
			state.taxonomy = state.taxonomy.map((taxonomy) => ({ ...taxonomy, selected: false }));
		},
		UPDATE_SELECTED_INTERESTS(state, selectedInterests) {
			state.interests = [...selectedInterests];
		},
		UPDATE_SELECTED_INTERESTS_NAMES(state, interestsNames) {
			state.interestsNames = [...interestsNames];
		},
		SET_DEFAULT_SELECTED_INTERESTS(state) {
			state.interests = [];
			state.interestsNames = [];
		},
	},
	actions: {
		async getTaxonomy({
			state, commit,
		}) {
			const [err, taxonomy] = await this.$fetch.dictionaryAPI.getTaxonomy();
			if (err) {
				return Promise.reject(err);
			}
			const agesSegmentsKeys = Object.keys(state.ages);
			const genderSegmentsKeys = Object.keys(state.gender);
			const customSegments = [...agesSegmentsKeys, ...genderSegmentsKeys];

			const taxonomyList = [];
			taxonomy.forEach((taxonomyItem) => {
				if (customSegments.includes(taxonomyItem.ID)) {
					return;
				}

				const copiedTaxonomyItem = { ...taxonomyItem };
				copiedTaxonomyItem.selected = state.interests.includes(taxonomyItem.ID);
				taxonomyList.push(copiedTaxonomyItem);
			});
			commit('UPDATE_TAXONOMY', taxonomyList);
			return Promise.resolve();
		},
	},
};
