const agencyRoutes = [
	{
		path: 'accounts-list',
		name: 'AccountsList',
		meta: {
			access: ['fullService', 'selfService'],
			title: 'Рекламодатели',
			roleRequired: 'agency',
		},
		component: () => import('@/views/agency/AccountsList'),
	},
	{
		path: 'account-settings/:accountID',
		props: true,
		name: 'AccountSettings',
		meta: {
			access: ['fullService', 'selfService'],
			title: 'Управление доступом',
			roleRequired: 'agency',
		},
		component: () => import('@/views/agency/AccountSettings'),
	},
];

export default agencyRoutes;
