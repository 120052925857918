import { formatNumber } from '@/helpers/common';

export default {
	namespaced: true,
	state: () => ({
		currentGroupID: null,
		groupStatistics: {
			budget: 0,
			win: 0,
			viewPercents: 0,
			ecpc: 0,
			ecpv: 0,
			vtr: 0,
			ctr: 0,
			click: 0,
		},
	}),
	getters: {
		formattedGroupStatistics: (state) => ({
			budget: formatNumber(state.groupStatistics.budget, 'currency', 2),
			win: formatNumber(state.groupStatistics.budget, 'decimal'),
			click: formatNumber(state.groupStatistics.click, 'decimal'),
			viewPercents: formatNumber(state.groupStatistics.viewPercents, 'percent', 2),
			ecpc: formatNumber(state.groupStatistics.ecpc, 'currency', 2),
			ecpv: formatNumber(state.groupStatistics.ecpv, 'currency', 2),
			vtr: formatNumber(state.groupStatistics.vtr, 'percent', 2),
			ctr: formatNumber(state.groupStatistics.ctr, 'percent', 2),
		}),
	},
	mutations: {
		UPDATE_CURRENT_GROUP_ID(state, value) {
			state.currentGroupID = value;
		},
		SET_DEFAULT_CURRENT_GROUP_ID(state) {
			state.currentGroupID = null;
		},
		SET_DEFAULT_GROUP_STATISTICS(state) {
			Object.keys(state.groupStatistics).forEach((key) => {
				state.groupStatistics[key] = 0;
			});
		},
		UPDATE_GROUP_STATISTICS(state, rawGroupStatistics) {
			state.groupStatistics.budget = rawGroupStatistics.budget;
			state.groupStatistics.win = rawGroupStatistics.win;
			state.groupStatistics.click = rawGroupStatistics.click;
			state.groupStatistics.viewPercents = rawGroupStatistics.win ? rawGroupStatistics.view / rawGroupStatistics.win : 0;
			state.groupStatistics.ecpc = rawGroupStatistics.click ? rawGroupStatistics.budget / rawGroupStatistics.click : 0;
			state.groupStatistics.ecpv = rawGroupStatistics.complete ? rawGroupStatistics.budget / rawGroupStatistics.complete : 0;
			state.groupStatistics.vtr = rawGroupStatistics.win ? rawGroupStatistics.complete / rawGroupStatistics.win : 0;
			state.groupStatistics.ctr = rawGroupStatistics.win ? rawGroupStatistics.click / rawGroupStatistics.win : 0;
		},
	},
	actions: {
		async getGroupStatistic({ state, commit }, { timeStart, timeEnd }) {
			if (!state.currentGroupID) {
				commit('SET_DEFAULT_GROUP_STATISTICS');
				return;
			}
			const [err, groupStatistics] = await this.$fetch.groupAPI.getGroupStatistics(state.currentGroupID, timeStart, timeEnd);
			if (err) {
				commit('SET_DEFAULT_GROUP_STATISTICS');
				return;
			}
			commit('UPDATE_GROUP_STATISTICS', groupStatistics.state);
		},
	},
};
