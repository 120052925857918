export default {
	namespaced: true,
	state: () => ({
		buyingType: 'evenly',
	}),
	getters: {
		finalBuyingType: (state) => state.buyingType,
	},
	mutations: {
		SET_DEFAULT_BUYING_TYPE(state) {
			state.buyingType = 'evenly';
		},
		UPDATE_BUYING_TYPE(state, newBuyingType) {
			state.buyingType = newBuyingType;
		},
	},
	actions: {},
};
