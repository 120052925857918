export default {
	namespaced: true,
	state: () => ({
		vtr: {
			enabled: false,
			value: 0,
		},
		ctr: {
			enabled: false,
			value: 0,
		},
		va: {
			enabled: false,
			value: 0,
		},
		br: {
			enabled: false,
			value: 0,
		},
		pi: {
			enabled: false,
			value: 0,
		},
		cpi: {
			enabled: false,
			value: 0,
		},
	}),
	getters: {
		finalKpi: (state) => {
			const resultedKpi = {};
			Object.keys(state).forEach((key) => {
				if (!state[key].enabled) {
					resultedKpi[key] = 0;
					return;
				}
				resultedKpi[key] = state[key].value;
			});
			return resultedKpi;
		},
	},
	mutations: {
		SET_DEFAULT_KPI(state) {
			Object.keys(state).forEach((key) => {
				state[key].enabled = false;
				state[key].value = 0;
			});
		},
		UPDATE_KPI(state, campaignKpi) {
			Object.keys(campaignKpi).forEach((key) => {
				state[key].enabled = campaignKpi[key] !== 0;
				state[key].value = campaignKpi[key];
			});
		},
		UPDATE_KPI_ENABLED_BY_FIELD(state, { fieldName, value }) {
			state[fieldName].enabled = value;
		},
		UPDATE_KPI_VALUE_BY_FIELD(state, { fieldName, value }) {
			state[fieldName].value = value;
		},
	},
};
