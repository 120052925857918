import { sortByField } from '@/helpers/common';

export default {
	namespaced: true,
	state: () => ({
		ssps: {},
	}),
	getters: {
		ssps: (state) => state.ssps,
		isAllSspSelected: (state, getters) => getters.selectedSsps.length === getters.sortedSsps.length,
		isNoSspSelected: (state, getters) => getters.selectedSsps.length === 0,
		sortedSsps: (state) => Object.values(state.ssps)
			.sort(sortByField('name')),
		sortedSspsByType: (state, getters) => (type) => getters.sortedSsps.filter((ssp) => ssp.type === type),
		selectedSsps: (state, getters, rootState, rootGetters) => {
			if (rootGetters['GlobalHooks/isSelfServiceAccount']) {
				const sspsByDeviceType = rootGetters['Campaign/DeviceType/isSmartTvSelected']
					?	getters.sortedSspsByType('smartTV')
					: [...getters.sortedSspsByType('app'), ...getters.sortedSspsByType('web')];
				return sspsByDeviceType.map((ssp) => ssp.sysname);
			}
			return Object.values(state.ssps)
				.filter((ssp) => ssp.value)
				.map((ssp) => ssp.sysname);
		},
		sspsForStatisticsRequest: (state, getters, rootState, rootGetters) => (getters.isAllSspSelected || rootGetters['GlobalHooks/isSelfServiceAccount']
			? []
			: getters.selectedSsps),
	},
	mutations: {
		UPDATE_SSPS(state, { fieldName, value }) {
			state.ssps[fieldName].value = value;
		},
		DESELECT_ALL_SSPS(state) {
			Object.keys(state.ssps).forEach((key) => {
				state.ssps[key].value = false;
			});
		},
		SELECT_ALL_SSPS(state) {
			Object.keys(state.ssps).forEach((key) => {
				state.ssps[key].value = true;
			});
		},
		SET_SSPS(state, sspsList) {
			sspsList.forEach((ssp) => {
				state.ssps[ssp.sysname] = { ...ssp, value: false };
			});
		},
	},

	actions: {
		async getCampaignSsps({ commit }, campaignType) {
			const [err, result] = await this.$fetch.dictionaryAPI.getSsps();
			if (err) {
				return Promise.reject(err);
			}

			commit('SET_SSPS', result.filter((ssp) => ssp[campaignType]));
			return Promise.resolve();
		},
	},
};
