import Form from './player-customization-popup-form';
import PopupVisibility from './player-customization-popup-visibility';

export default {
	namespaced: true,
	state: () => ({
		playerCustomizationConfigs: [],
		playerCustomizationConfigsByID: {},
	}),
	getters: {
		playerCustomizationConfigs: (state) => state.playerCustomizationConfigs,
		playerCustomizationConfigsByID: (state) => state.playerCustomizationConfigsByID,
	},
	mutations: {
		SET_PLAYER_CUSTOMIZATION_CONFIGS(state, payload) {
			state.playerCustomizationConfigs = payload;
		},
		SET_PLAYER_CUSTOMIZATION_CONFIGS_BY_ID(state) {
			state.playerCustomizationConfigsByID = {};
			state.playerCustomizationConfigs.forEach((playerCustomizationConfig) => {
				state.playerCustomizationConfigsByID[playerCustomizationConfig.id] = playerCustomizationConfig.videoConfig;
			});
		},
	},
	actions: {
		async getPlayerCustomizationConfigs({ commit, state }, needDefaultConfig = false) {
			const [err, playerCustomizationConfigs] = await this.$fetch.playerConfigAPI.getAllPlayerCustomizationConfigs();
			if (err) {
				return Promise.reject(err);
			}
			if (needDefaultConfig) {
				commit('SET_PLAYER_CUSTOMIZATION_CONFIGS', [{ ...state.Form.defaultPlayerCustomizationConfig }, ...playerCustomizationConfigs]);
				commit('SET_PLAYER_CUSTOMIZATION_CONFIGS_BY_ID');
				return Promise.resolve();
			}
			commit('SET_PLAYER_CUSTOMIZATION_CONFIGS', playerCustomizationConfigs);
			return Promise.resolve();
		},
	},
	modules: {
		Form,
		PopupVisibility,
	},
};
