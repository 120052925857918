export default {
	namespaced: true,
	state: () => ({
		brandLiftStatistics: [],
		selectedBrandLiftType: 'before',
	}),
	getters: {
		questionByIndex: (state) => (index) => state.brandLiftStatistics[index],
	},
	mutations: {
		UPDATE_BRAND_LIFT_STATISTICS(state, payload) {
			state.brandLiftStatistics = payload;
		},
		SET_DEFAULT_BRAND_LIFT_STATISTICS(state) {
			state.brandLiftStatistics = [];
		},
		SET_DEFAULT_SELECTED_BRAND_LIFT_TYPE(state) {
			state.selectedBrandLiftType = 'before';
		},
		UPDATE_SELECTED_BRAND_LIFT_TYPE(state, brandLiftType) {
			state.selectedBrandLiftType = brandLiftType;
		},
	},
	actions: {
		async getBrandLiftStatistics({ commit }, { id }) {
			const [err, result] = await this.$fetch.brandLiftAPI.getBrandLiftStatistics(id);
			if (err) {
				return Promise.reject(err);
			}
			commit('UPDATE_BRAND_LIFT_STATISTICS', result);
			return Promise.resolve();
		},
	},
};
