export default {
	namespaced: true,
	state: () => ({
		positive: {
			id: 0,
			value: false,
		},
		neutral: {
			id: 1,
			value: false,
		},
		negative: {
			id: 2,
			value: false,
		},
	}),
	getters: {
		brandSafety: (state) => state,
		selectedBrandSafety: (state) => {
			const selectedEmotionalBckg = [];
			Object.keys(state).forEach((fieldName) => {
				if (state[fieldName].value) {
					selectedEmotionalBckg.push(state[fieldName].id);
				}
			});
			return selectedEmotionalBckg;
		},
	},
	mutations: {
		UPDATE_BRAND_SAFETY: (state, { fieldName, value }) => {
			state[fieldName].value = value;
		},
		SET_DEFAULT_BRAND_SAFETY(state) {
			Object.keys(state).forEach((fieldName) => {
				state[fieldName].value = false;
			});
		},
	},
};
