export default {
	namespaced: true,
	state: () => ({
		name: '',
		id: '',
		status: '',
		limitReached: false,
		isBrandLift: false,
		type: 'vast',
	}),
	getters: {
		campaignName: (state) => state.name,
		campaignID: (state) => state.id,
		campaignStatus: (state) => state.status,
		campaignLimitReached: (state) => state.limitReached,
		isBrandLift: (state) => state.isBrandLift,
		campaignType: (state) => state.type,
	},
	mutations: {
		UPDATE_CAMPAIGN_NAME: (state, newName) => {
			state.name = newName;
		},
		UPDATE_CAMPAIGN_ID: (state, campaignID) => {
			state.id = campaignID;
		},
		UPDATE_CAMPAIGN_TYPE: (state, campaignType) => {
			state.type = campaignType;
		},
		UPDATE_CAMPAIGN_STATUS: (state, campaignStatus) => {
			state.status = campaignStatus;
		},
		UPDATE_CAMPAIGN_LIMIT_REACHED: (state, campaignLimitReached) => {
			state.limitReached = campaignLimitReached;
		},
		UPDATE_IS_BRAND_LIFT: (state, isBrandLift) => {
			state.isBrandLift = isBrandLift;
		},
		SET_DEFAULT_BASE_INFO(state) {
			state.name = '';
			state.id = '';
			state.status = '';
			state.limitReached = false;
			state.isBrandLift = false;
			state.type = 'vast';
		},
	},
};
