class PlayerConfigAPI {
	constructor(apiCall, sortByField) {
		this.apiCall = apiCall;
		this.sortByField = sortByField;
	}

	async getPlayerCustomizationConfigPreview(configId) {
		try {
			const result = await this.apiCall.get({ gate: `/player-config-preview/${configId}` });
			return [null, result];
		} catch (err) {
			return [err];
		}
	}

	async getPlayerCustomizationConfig(configId) {
		try {
			const result = await this.apiCall.get({ gate: `/player-config/${configId}` });
			return [null, result];
		} catch (err) {
			return [err];
		}
	}

	async getAllPlayerCustomizationConfigs() {
		try {
			const result = await this.apiCall.get({
				gate: '/player-configs',
				isFatal: true,
			});
			return [null, result.PlayerGonfigs?.sort(this.sortByField('lastUpdate', true))];
		} catch (err) {
			return [err];
		}
	}

	async createPlayerCustomizationConfig(playerConfig, vpaidScript, files) {
		const data = JSON.stringify({ ...playerConfig, vpaidScript, files: [...files] });
		try {
			await this.apiCall.post('/player-config', data);
			return [null];
		} catch (err) {
			return [err];
		}
	}

	async editPlayerCustomizationConfig(playerConfig, vpaidScript, files) {
		const data = JSON.stringify({ ...playerConfig, vpaidScript, files: [...files] });
		try {
			await this.apiCall.put('/player-config', data);
			return [null];
		} catch (err) {
			return [err];
		}
	}

	async removePlayerCustomizationConfig(payload) {
		try {
			await this.apiCall.delete(`/player-config/${payload.id}`);
			return [null];
		} catch (err) {
			return [err];
		}
	}

	async updateCartItems({ playerConfigId, cartItems }) {
		const data = { playerConfigID: playerConfigId, cartItems };
		try {
			await this.apiCall.put('/player-config/carts', data);
			return [null];
		} catch (err) {
			return [err];
		}
	}
}

export default PlayerConfigAPI;
