export default {
	namespaced: true,
	state: () => ({
		visiblePlayerCustomizationPopup: false,
		playerCustomizationPopupModes: {
			create: 'create',
			edit: 'edit',
		},
		currentPlayerCustomizationPopupMode: '',
	}),
	getters: {
		visibleCustomizationPopup: (state) => state.visibleCustomizationPopup,
		currentPlayerCustomizationPopupMode: (state) => state.currentPlayerCustomizationPopupMode,
		createPlayerCustomizationPopupMode: (state) => state.playerCustomizationPopupModes.create,
		editPlayerCustomizationPopupMode: (state) => state.playerCustomizationPopupModes.edit,
	},
	mutations: {
		UPDATE_VISIBLE_CUSTOMIZATION_POPUP(state) {
			state.visibleCustomizationPopup = !state.visibleCustomizationPopup;
		},
		SET_CURRENT_PLAYER_CUSTOMIZATION_POPUP_MODE(state, payload) {
			state.currentPlayerCustomizationPopupMode = payload;
		},
	},
	actions: {},
};
