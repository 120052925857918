<template>
  <content-loader
    v-if="simpleItem"
    :width="width ?? 1300"
    :height="height ?? 350"
    :speed="1.5"
    primaryColor="#c7c7c7"
    secondaryColor="#ddd"
  >
    <rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />
  </content-loader>
  <content-loader
    v-if="tableBox"
    width="1300"
    height="556"
    :speed="1.5"
    primaryColor="#c7c7c7"
    secondaryColor="#ddd"
  >
    <rect x="0" y="0" rx="4" ry="4" width="225" height="41" />
    <rect x="240" y="0" rx="4" ry="4" width="195" height="41" />
    <rect x="450" y="0" rx="4" ry="4" width="130" height="41" />
    <rect x="895" y="0" rx="4" ry="4" width="190" height="41" />
    <rect x="1100" y="0" rx="4" ry="4" width="200" height="41" />
    <rect x="0" y="56" rx="4" ry="4" width="1300" height="500" />
  </content-loader>
  <content-loader
    v-if="smallTableBox"
    width="950"
    height="556"
    :speed="1.5"
    primaryColor="#c7c7c7"
    secondaryColor="#ddd"
  >
    <rect x="0" y="0" rx="4" ry="4" width="225" height="41" />
    <rect x="240" y="0" rx="4" ry="4" width="195" height="41" />
    <rect x="0" y="56" rx="4" ry="4" width="950" height="500" />
  </content-loader>
  <content-loader
    v-if="mediumTableBox"
    width="1140"
    height="556"
    :speed="1.5"
    primaryColor="#c7c7c7"
    secondaryColor="#ddd"
  >
    <rect x="0" y="0" rx="4" ry="4" width="225" height="41" />
    <rect x="240" y="0" rx="4" ry="4" width="195" height="41" />
    <rect x="0" y="56" rx="4" ry="4" width="1140" height="500" />
  </content-loader>
  <content-loader
    v-if="videoInStreamPlayer"
    width="640"
    height="360"
    :speed="1.5"
    primaryColor="#e5e5e5"
    secondaryColor="#f4f4f4"
  >
    <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
  </content-loader>
  <content-loader
    v-if="videoOutStreamPlayer"
    width="640"
    height="360"
    :speed="1.5"
    primaryColor="#e5e5e5"
    secondaryColor="#f4f4f4"
  >
    <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
  </content-loader>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';

export default {
	name: 'PSkeleton',
	components: { ContentLoader },
	props: {
		simpleItem: {
			type: Boolean,
			default: false,
		},
		width: {
			type: [Number, String],
		},
		height: {
			type: [Number, String],
		},
		tableBox: {
			type: Boolean,
			default: false,
		},
		smallTableBox: {
			type: Boolean,
			default: false,
		},
		mediumTableBox: {
			type: Boolean,
			default: false,
		},
		videoInStreamPlayer: {
			type: Boolean,
			default: false,
		},
		videoOutStreamPlayer: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {};
	},
	computed: {},
	watch: {},
	mounted() {
	},
	methods: {},
};
</script>

<style lang="less" scoped>

</style>
