export default {
	namespaced: true,
	state: () => ({
		bidType: 'show',
		bidTypesStore: [{ id: 'show', title: 'Показы (CPM)' }],
	}),
	getters: {
		finalBidType: (state) => state.bidType,
	},
	mutations: {
		SET_DEFAULT_BID_TYPE(state) {
			state.bidType = 'show';
		},
		UPDATE_BID_TYPE(state, newBidType) {
			state.bidType = newBidType;
		},
	},
	actions: {},
};
