export default {
	namespaced: true,
	state: () => ({
		isUpsertGroupPopupVisible: false,
		groups: [],
		currentGroup: {
			id: '',
			name: '',
			limit: {
				totalBudget: 0,
				dayBudget: 0,
				totalClick: 0,
				dayClick: 0,
				totalShow: 0,
				dayLoad: 0,
				totalComplete: 0,
				dayComplete: 0,
				userTotalWin: 0,
				userWinInterval: 0,
			},
		},
	}),
	getters: {
		specialCurrentGroupLimit: (state) => (fieldName) => state.currentGroup.limit[fieldName],
	},
	mutations: {
		UPDATE_IS_UPSERT_GROUP_POPUP_VISIBLE(state, value) {
			state.isUpsertGroupPopupVisible = value;
		},
		UPDATE_GROUPS(state, groups) {
			state.groups = groups;
		},
		SET_DEFAULT_GROUPS(state) {
			state.groups = [];
		},
		SET_DEFAULT_CURRENT_GROUP(state) {
			state.currentGroup.id = '';
			state.currentGroup.name = '';
			state.currentGroup.limit.totalBudget = 500;
			state.currentGroup.limit.dayBudget = 300;
			state.currentGroup.limit.totalClick = 0;
			state.currentGroup.limit.dayClick = 0;
			state.currentGroup.limit.totalShow = 3000;
			state.currentGroup.limit.dayLoad = 0;
			state.currentGroup.limit.totalComplete = 1000;
			state.currentGroup.limit.dayComplete = 0;
			state.currentGroup.limit.userTotalWin = 0;
			state.currentGroup.limit.userWinInterval = 0;
		},
		UPDATE_CURRENT_GROUP(state, payload) {
			state.currentGroup.id = payload.id;
			state.currentGroup.name = payload.name;
			Object.keys(state.currentGroup.limit).forEach((key) => {
				state.currentGroup.limit[key] = payload.limit[key];
			});
		},
		UPDATE_CURRENT_GROUP_NAME(state, name) {
			state.currentGroup.name = name;
		},
		UPDATE_CURRENT_GROUP_LIMITS_BY_FIELD(state, { fieldName, value }) {
			state.currentGroup.limit[fieldName] = value;
		},
	},
	actions: {
		async getGroups({ commit }) {
			const [err, result] = await this.$fetch.groupAPI.getGroups();
			if (err) {
				commit('UPDATE_GROUPS', []);
				return;
			}
			commit('UPDATE_GROUPS', result);
		},
	},
};
