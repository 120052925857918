<template>
  <dx-button
    :text="text"
    :width="width"
    :disabled="disabled"
    :icon="icon ? require(`@/assets/icons/${icon}`) : undefined"
    :elementAttr="{ class: classes }"
    :height="40"
    :hoverStateEnabled="false"
    :focusStateEnabled="false"
    :use-submit-behavior="useSubmitBehavior"
    @click="customClickHandler"
  />
</template>

<script>
import DxButton from 'devextreme-vue/ui/button';

export default {
	name: 'PButton',
	components: {
		DxButton,
	},
	props: {
		text: {
			type: String,
			required: false,
		},
		width: [Number, String],
		disabled: {
			type: Boolean,
			default: false,
		},
		secondary: {
			type: Boolean,
			default: false,
		},
		square: {
			type: Boolean,
			default: false,
		},
		boxShadow: {
			type: Boolean,
			default: false,
		},
		login: {
			type: Boolean,
			default: false,
		},
		black: {
			type: Boolean,
			default: false,
		},
		icon: {
			type: String,
			default: undefined,
		},
		useSubmitBehavior: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	emits: ['p-click'],
	computed: {
		classes() {
			const classObject = {
				button: true,
				disabled: this.disabled,
				secondary: this.secondary,
				login: this.login,
				black: this.black,
				square: this.square,
				'box-shadow': this.boxShadow,
			};
			const classes = [];
			Object.keys(classObject).forEach((key) => {
				if (!classObject[key]) {
					return;
				}
				classes.push(key);
			});
			return classes.join(' ');
		},
	},
	methods: {
		customClickHandler() {
			this.$emit('p-click');
		},
	},
};
</script>

<style lang="less">
.button {
	.dx-button-text {
		font-weight: 700 !important;
	}
}
</style>

<style lang="less" scoped>
@import url('~@/assets/style/variables');

.button {
	background-color: @primary-color;
	color: #fff;
	line-height: 40px;
	font-size: 14px;
	box-shadow: @default-box-shadow;
	transition: background-color 0.2s ease-in, color 0.2s ease-in;

	&:hover {
		background-color: @primary-color-variant;
		box-shadow: @default-box-shadow;
	}

	&.disabled {
		background-color: @neutral-color !important;
	}

	&.login {
		background: #000;
	}

	&.black {
		background-color: #000;

		&:hover {
			background-color: @secondary-color;
		}
	}

	&.square {
		border-radius: @default-border-radius !important;
		padding: 0 5px 0 !important;
		margin: -2px auto;
	}

	&.box-shadow {
		box-shadow: @default-box-shadow !important;
	}

	&.secondary {
		background-color: @secondary-color;

		&:hover {
			background-color: @secondary-color-variant;
		}
	}
}
</style>
