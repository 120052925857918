import showMessage from '@/helpers/messaging-service';
import { specialSymbolsCheck } from '@/helpers/validator';

export default class PanelSegmentValidator {
	static isSegmentForInfoValid = (requestDataForInfo) => {
		const isValid = true;
		if (requestDataForInfo.genderFilters === '') {
			showMessage('emptyGenderPanelInfoErrorMSG');
			return !isValid;
		}
		if (requestDataForInfo.ageFilters === '') {
			showMessage('emptyAgePanelInfoErrorMSG');
			return !isValid;
		}
		if (requestDataForInfo.incomeFilters === '') {
			showMessage('emptyIncomePanelInfoErrorMSG');
			return !isValid;
		}
		if (requestDataForInfo.provider === '') {
			showMessage('emptyProviderPanelInfoErrorMSG');
			return !isValid;
		}
		if (!requestDataForInfo.category) {
			showMessage('emptyCategoryPanelInfoErrorMSG');
			return !isValid;
		}
		return isValid;
	};

	static isSegmentForCreateValid = (requestDataForCreate) => {
		const isValid = true;
		if (requestDataForCreate.domains.length === 0) {
			showMessage('emptyDomainsPanelCreateErrorMSG');
			return !isValid;
		}
		if (!requestDataForCreate.affinity) {
			showMessage('emptyDataPanelCreateErrorMSG');
			return !isValid;
		}
		if (requestDataForCreate.name === '') {
			showMessage('emptySegmentNamePanelCreateErrorMSG');
			return !isValid;
		}
		if (!specialSymbolsCheck(requestDataForCreate.name)) {
			showMessage('specialSymbolsSegmentNamePanelCreateErrorMSG');
			return !isValid;
		}
		return isValid;
	};
}
