const emptyStatisticItem = {
	id: 'empty',
	title: 'Нет данных',
	value: 1,
};

export default {
	namespaced: true,
	state: () => ({
		gender: [
			{
				id: 'empty',
				title: 'Нет данных',
				value: 1,
			},
		],
		age: [
			{
				id: 'empty',
				title: 'Нет данных',
				value: 1,
			},
		],
		income: [
			{
				id: 'empty',
				title: 'Нет данных',
				value: 1,
			},
		],
	}),
	getters: {
		gender: (state) => state.gender,
		age: (state) => state.age,
		income: (state) => state.income,
	},
	mutations: {
		SET_DEFAULT_STATISTIC_BY_TYPE(state, type) {
			state[type] = [emptyStatisticItem];
		},
		UPDATE_STATISTIC_BY_TYPE(state, { type, statistic }) {
			// TODO Remove condition after backend updates
			if (type === 'income') {
				statistic.forEach((item) => {
					if (item.title.includes('руб')) {
						item.title = item.title.split(' ').filter((part) => part !== 'руб.').join(' ');
					}
				});
			}
			state[type] = statistic.length === 0 ? [emptyStatisticItem] : statistic;
		},
	},
	actions: {},
};
