class SiteAPI {
	constructor(apiCall) {
		this.apiCall = apiCall;
	}

	async getSiteCategories() {
		try {
			const result = await this.apiCall.get({
				gate: '/site/categories',
				isFatal: true,
			});
			return [null, result];
		} catch (err) {
			return [err];
		}
	}
}

export default SiteAPI;
