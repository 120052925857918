export default {
	namespaced: true,
	state: () => ({
		operators: [],
		operatorsStore: [],
	}),
	getters: {
		finalOperators: (state) => state.operators,
	},
	mutations: {
		SET_DEFAULT_OPERATORS(state) {
			state.operators = [];
		},
		UPDATE_OPERATORS_STORE(state, operatorsData) {
			state.operatorsStore = operatorsData;
		},
		UPDATE_OPERATORS(state, selectedOperators) {
			state.operators = selectedOperators;
		},
	},
	actions: {
		async getOperators({ commit }) {
			const [err, operatorsData] = await this.$fetch.dictionaryAPI.getOperators();
			if (err) {
				return Promise.reject(err);
			}
			commit('UPDATE_OPERATORS_STORE', operatorsData);
			return Promise.resolve();
		},
	},
};
