<template>
  <div class="card" :style="style">
    <slot />
  </div>
</template>

<script>
export default {
	name: 'PCard',
	components: {},
	props: {
		flat: {
			type: Boolean,
			default: false,
		},
		rounded: {
			type: [Number, String],
		},
		width: {
			type: [Number, String],
		},
		minWidth: {
			type: [Number, String],
		},
		maxWidth: {
			type: [Number, String],
		},
		height: {
			type: [Number, String],
		},
		minHeight: {
			type: [Number, String],
		},
		maxHeight: {
			type: [Number, String],
		},
		padding: {
			type: [Number, String],
		},
	},
	data() {
		return {};
	},
	computed: {
		style() {
			return {
				boxShadow: this.flat && 'none',
				borderRadius: this.rounded && (typeof this.rounded === 'string' ? this.rounded : `${this.rounded}px`),
				minWidth: this.minWidth && (typeof this.minWidth === 'string' ? this.minWidth : `${this.minWidth}px`),
				maxWidth: this.maxWidth && (typeof this.maxWidth === 'string' ? this.maxWidth : `${this.maxWidth}px`),
				minHeight: this.minHeight && (typeof this.minHeight === 'string' ? this.minHeight : `${this.minHeight}px`),
				maxHeight: this.maxHeight && (typeof this.maxHeight === 'string' ? this.maxHeight : `${this.maxHeight}px`),
				height: this.height && (typeof this.height === 'string' ? this.height : `${this.height}px`),
				width: this.width && (typeof this.width === 'string' ? this.width : `${this.width}px`),
				padding: this.padding && (typeof this.padding === 'string' ? this.padding : `${this.padding}px`),
			};
		},
	},
	watch: {},
	mounted() {
	},
	methods: {},
};
</script>

<style lang="less" scoped>
@import url('~@/assets/style/variables');

.card {
	background: #fff;
	box-shadow: @default-box-shadow-variant;
	padding: 20px;
	border-radius: @default-border-radius;
}
</style>
