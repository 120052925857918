import showMessage from '@/helpers/messaging-service';

export default {
	namespaced: true,
	state: () => ({
		groupID: '',
		group: {
			name: '',
		},
		isUpsertGroupCampaignsPopupVisible: false,

		allCampaignsRequestData: {
			startDate: new Date().setHours(0, 0, 0, 0),
			endDate: new Date().setHours(23, 59, 59, 999),
			type: 'all',
			status: 'all',
		},

		groupCampaigns: [],
		allCampaignsMap: {},
	}),
	getters: {
		groupName: (state) => state.group.name,
		allCampaigns: (state) => Object.values(state.allCampaignsMap),
		campaignsIdsForUpsertGroup: (state) => Object.keys(state.allCampaignsMap).filter((campaignID) => state.allCampaignsMap[campaignID].isInGroup),
		campaignsIdsInGroup: (state) => state.groupCampaigns.map((campaign) => campaign.id),
	},
	mutations: {
		SET_DEFAULT_GROUP_ID(state) {
			state.groupID = '';
		},
		UPDATE_GROUP_ID(state, groupID) {
			state.groupID = groupID;
		},
		UPDATE_GROUP(state, payload) {
			state.group.name = payload.name;
		},
		TOGGLE_IS_UPSERT_GROUP_CAMPAIGNS_POPUP_VISIBLE(state, value) {
			state.isUpsertGroupCampaignsPopupVisible = value;
		},
		SET_DEFAULT_GROUP_CAMPAIGNS(state) {
			state.groupCampaigns = [];
		},
		SET_DEFAULT_ALL_CAMPAIGNS(state) {
			state.allCampaignsMap = {};
		},
		UPDATE_ALL_CAMPAIGNS_MAP(state, { campaignID, value }) {
			state.allCampaignsMap[campaignID].isInGroup = value;
		},
		UPDATE_GROUP_CAMPAIGNS(state, payload) {
			state.groupCampaigns = payload;
		},
		UPDATE_CAMPAIGNS_STORES(state, payload) {
			const { allCampaigns, groupCampaigns } = payload;
			const initialAllCampaignsMap = {};
			allCampaigns.forEach((campaign) => {
				const isCampaignInGroup = groupCampaigns.findIndex((groupCampaign) => groupCampaign.id === campaign.id) !== -1;
				if (!isCampaignInGroup) {
					initialAllCampaignsMap[campaign.id] = campaign;
				}
				campaign.isInGroup = isCampaignInGroup;
			});
			state.allCampaignsMap = initialAllCampaignsMap;
		},
		UPDATE_ALL_CAMPAIGNS_REQUEST_DATA_DATES(state, { startDate, endDate }) {
			state.allCampaignsRequestData.startDate = startDate;
			state.allCampaignsRequestData.endDate = endDate;
		},
		SET_DEFAULT_ALL_CAMPAIGNS_REQUEST_DATA_DATES(state) {
			state.allCampaignsRequestData.startDate = new Date().setHours(0, 0, 0, 0);
			state.allCampaignsRequestData.endDate = new Date().setHours(23, 59, 59, 999);
		},
	},
	actions: {
		async getGroupById({ commit, state }) {
			const [err, result] = await this.$fetch.groupAPI.getGroupById(state.groupID);
			if (err) {
				commit('UPDATE_GROUP', { name: '' });
				return;
			}
			commit('UPDATE_GROUP', result);
		},
		async getCampaignsByGroupId({ commit, state }) {
			const [err, result] = await this.$fetch.groupAPI.getCampaignsByGroupId(state.groupID);
			if (err) {
				commit('SET_DEFAULT_GROUP_CAMPAIGNS');
				return Promise.reject(err);
			}
			commit('UPDATE_GROUP_CAMPAIGNS', result);
			return Promise.resolve(result);
		},
		async getFullGroupCampaignsData({ commit, dispatch, state }) {
			const startTime = new Date(state.allCampaignsRequestData.startDate).getTime();
			const endTime = new Date(state.allCampaignsRequestData.endDate).getTime();
			const type = 'all';
			const status = 'all';

			const allCampaignsPromise = new Promise((resolve, reject) => {
				this.$fetch.campaignAPI.getList(startTime, endTime, type, status).then(([err, result]) => {
					err ? reject(err) : resolve(result);
				});
			});
			const groupCampaignsPromise = dispatch('getCampaignsByGroupId');
			const fullGroupCampaignsDataPromises = [
				allCampaignsPromise,
				groupCampaignsPromise,
			];
			try {
				const [allCampaigns, groupCampaigns] = await Promise.all(fullGroupCampaignsDataPromises);
				commit('UPDATE_CAMPAIGNS_STORES', {
					allCampaigns,
					groupCampaigns,
				});
			} catch (err) {
				showMessage('customMSG', 'error', err.message);
				console.error(err);
			}
		},
	},
};
