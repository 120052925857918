<template>
  <footer class="footer">
    <div>Плацкарт</div>
    <div>support@plazkart.ru</div>
  </footer>
</template>

<script>
export default {
	name: 'PFooter',
};
</script>

<style lang="less" scoped>
@import url('~@/assets/style/variables');

.footer {
	background-color: #000;
	width: 100%;
	padding: 0 40px;
	color: #fff;
	font-size: 14.4px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: @footer-height;
}
</style>
