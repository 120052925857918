import MainLayout from '@/layouts/MainLayout';
import EmptyLayout from '@/layouts/EmptyLayout';

import errorRoutes from '@/router/routes/modules/error-routes';
import previewRoutes from '@/router/routes/modules/preview-routes';
import agencyRoutes from '@/router/routes/modules/agency-routes';
import accountRoutes from '@/router/routes/modules/account-routes';
import authRoutes from '@/router/routes/modules/auth-routes';
import { setCabinetIdGuard, sessionCheckGuard } from '@/router/utils/router-guards';

/** children routing map are split into modules * */
const routes = [
	{
		alias: ['/login'],
		path: '/auth',
		name: 'Auth',
		redirect: {
			name: 'Login',
		},
		children: authRoutes,
	},
	{
		path: '/:cabinetId',
		name: 'CabinetRoot',
		beforeEnter: [setCabinetIdGuard, sessionCheckGuard],
		children: [
			{
				path: 'account',
				name: 'Account',
				redirect: {
					name: 'CampaignsList',
				},
				meta: { roleRequired: 'account' },
				component: MainLayout,
				children: accountRoutes,
			},
			{
				path: 'agency',
				name: 'Agency',
				redirect: {
					name: 'AccountsList',
				},
				meta: { roleRequired: 'agency' },
				component: MainLayout,
				children: agencyRoutes,
			},
		],
	},
	{
		path: '/preview',
		name: 'Preview',
		redirect: {
			name: 'PreviewLauncher',
			params: {
				pageMode: 'launcher',
			},
		},
		component: EmptyLayout,
		children: previewRoutes,
	},
	{
		path: '/error',
		name: 'Error',
		redirect: {
			name: 'NotFound',
		},
		component: EmptyLayout,
		children: errorRoutes,
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'UnknownPathHandler',
		redirect: {
			name: 'NotFound',
		},
	},
	{
		path: '',
		name: 'EmptyPathHandler',
		redirect: {
			name: 'Login',
		},
	},
];

export default routes;
