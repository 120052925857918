export default {
	namespaced: true,
	state: () => ({
		trafType: 'web',
	}),
	getters: {
		resultedTrafType: (state) => state.trafType,
	},
	mutations: {
		UPDATE_TRAF_TYPE(state, trafType) {
			state.trafType = trafType;
		},
		SET_DEFAULT_TRAF_TYPE(state) {
			state.trafType = 'web';
		},
	},
	actions: {},
};
