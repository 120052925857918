import { createApp } from 'vue';
import axios from 'axios';
import VueAwesomePaginate from 'vue-awesome-paginate';
import App from './App';
import axiosInstanceBuilder from '@/api/axios';
import router from './router';
import store from './store';
import './assets/style/devexpress/dx.material.custom-scheme.css';
import './assets/style/base.less';

import apiSetup from '@/api/api-setup';
import ApiCall from '@/api/api-call';

const axiosInstance = axiosInstanceBuilder(axios, store, router);
const fetch = apiSetup(new ApiCall(axiosInstance));

store.$fetch = fetch;

const app = createApp(App);

app.config.globalProperties.$fetch = fetch;

app.use(store).use(router).use(VueAwesomePaginate).mount('#app');
