import { createRouter, createWebHistory } from 'vue-router';
import { afterEachGuard, beforeEachGuard } from '@/router/utils/router-guards';
import routes from '@/router/routes';

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	scrollBehavior: () => ({ top: 0 }),
	routes,
});
router.beforeEach(beforeEachGuard);
router.afterEach(afterEachGuard);

export default router;
