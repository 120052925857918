class BanAPI {
	constructor(apiCall) {
		this.apiCall = apiCall;
		this.platformTypeDecodeMap = {
			domain: {
				forRequest: 'site',
				forGettingItems: 'sites',
				forOneItem: 'domain',
			},
			bundle: {
				forRequest: 'app',
				forGettingItems: 'bundles',
				forOneItem: 'bundle',
			},
		};
	}

	async getBanListMapByCampaign(campaignID, type) {
		const typeForRequest = this.platformTypeDecodeMap[type].forRequest;
		const typeForGettingItems = this.platformTypeDecodeMap[type].forGettingItems;
		const typeForOneItem = this.platformTypeDecodeMap[type].forOneItem;
		try {
			const result = await this.apiCall.get({
				gate: `/${typeForRequest}/campaign/${campaignID}`,
				isFatal: true,
			});
			const resultedMap = {};
			result[typeForGettingItems].forEach((platform) => {
				resultedMap[platform[typeForOneItem]] = {
					ban: platform.ban,
					id: platform.id,
				};
			});
			return [null, resultedMap];
		} catch (err) {
			return [err];
		}
	}

	async updateBanPlatforms(type, banIDs, unBanIDs) {
		const typeForRequest = this.platformTypeDecodeMap[type].forRequest;
		try {
			const body = {
				ban: banIDs,
				unban: unBanIDs,
			};
			await this.apiCall.put(`/${typeForRequest}/banList`, body);
			return [null];
		} catch (err) {
			return [err];
		}
	}
}

export default BanAPI;
