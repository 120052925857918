import showMessage from '@/helpers/messaging-service';

export default {
	namespaced: true,
	state: () => ({
		mainCampaignInfo: {
			name: '',
			start: 0,
			finish: 0,
			limitReached: false,
			limitReachedStatus: false,
			status: 'stopped',
			isBrandLift: false,
			type: 'vast',
			brandLiftTotalWin: 0,
			guestPrices: {
				cpc: 0,
				cpm: 0,
				cpv: 0,
			},
		},
		summaryCampaignInfo: {
			budget: 0,
			reach: 0,
			totalBudget: 0,
			win: 0,
			view: 0,
			click: 0,
			complete: 0,
		},
	}),
	getters: {
		isCampaignActive(state) {
			return state.mainCampaignInfo.status !== 'stopped';
		},
		campaignName(state) {
			return state.mainCampaignInfo.name;
		},
		campaignType(state) {
			return state.mainCampaignInfo.type;
		},
		campaignStatus(state) {
			return state.mainCampaignInfo.status;
		},
		isBrandLift(state) {
			return state.mainCampaignInfo.isBrandLift;
		},
	},
	mutations: {
		UPDATE_MAIN_CAMPAIGN_INFO(state, payload) {
			state.mainCampaignInfo.name = payload.name;
			state.mainCampaignInfo.status = payload.status;
			state.mainCampaignInfo.limitReached = payload.limitReached;
			state.mainCampaignInfo.limitReachedStatus = payload.limitReachedStatus;
			state.mainCampaignInfo.start = payload.dates ? payload.dates[0].from : 0;
			state.mainCampaignInfo.finish = payload.dates ? payload.dates[0].to : 0;
			state.mainCampaignInfo.isBrandLift = payload.isBrandLift;
			state.mainCampaignInfo.guestPrices = payload.guestPrices;
			state.mainCampaignInfo.type = payload.type;
		},
		SET_DEFAULT_MAIN_CAMPAIGN_INFO(state) {
			state.mainCampaignInfo.name = '';
			state.mainCampaignInfo.start = 0;
			state.mainCampaignInfo.finish = 0;
			state.mainCampaignInfo.limitReached = false;
			state.mainCampaignInfo.limitReachedStatus = [];
			state.mainCampaignInfo.status = 'stopped';
			state.mainCampaignInfo.isBrandLift = false;
			state.mainCampaignInfo.type = 'vast';
			state.mainCampaignInfo.guestPrices = {
				cpc: 0,
				cpm: 0,
				cpv: 0,
			};
		},
		UPDATE_BRAND_LIFT_TOTAL_WIN(state, win) {
			state.mainCampaignInfo.brandLiftTotalWin = win;
		},
		UPDATE_SUMMARY_CAMPAIGN_INFO_DATA(state, payload) {
			Object.keys(state.summaryCampaignInfo).forEach((key) => {
				if (key !== 'reach' && key !== 'totalBudget') {
					state.summaryCampaignInfo[key] = payload[key];
				}
			});
		},
		UPDATE_TOTAL_BUDGET(state, totalBudget) {
			state.summaryCampaignInfo.totalBudget = totalBudget.toFixed(2);
		},
		UPDATE_REACH(state, reach) {
			state.summaryCampaignInfo.reach = reach;
		},
		SET_DEFAULT_SUMMARY_CAMPAIGN_INFO(state) {
			Object.keys(state.summaryCampaignInfo).forEach((key) => {
				state.summaryCampaignInfo[key] = 0;
			});
		},
	},
	actions: {
		async getCampaignMainInfo({ commit, rootGetters }, { isFirstCall, needRecalculateRealTimeUpdate }) {
			if (isFirstCall) {
				commit('CampaignStatistics/SET_LOADED', false, { root: true });
			}
			const [err, result] = await this.$fetch.campaignAPI.getCampaignInfo(rootGetters['CampaignStatistics/campaignID']);
			if (err) {
				commit('CampaignStatistics/SET_LOADED', true, { root: true });
				return Promise.reject(err);
			}
			if (needRecalculateRealTimeUpdate) {
				commit('CampaignStatistics/UPDATE_NEED_REAL_TIME_UPDATE_BY_CAMPAIGN_INFO', result, { root: true });
			}
			if (isFirstCall && !result.ssp) {
				showMessage('customMSG', 'warning', 'Не выбраны SSP. Перейдите в настройки кампании.');
			}
			commit('UPDATE_MAIN_CAMPAIGN_INFO', result);
			commit('UPDATE_TOTAL_BUDGET', result.totalBudget);
			commit('CampaignStatistics/SET_LOADED', true, { root: 'true' });
			return Promise.resolve();
		},
		async getCampaignSummaryInfo({ commit, rootGetters, getters }) {
			const [err, summaryInfo] = await this.$fetch.statisticsAPI.getCampaignSummary(
				rootGetters['CampaignStatistics/campaignID'],
				rootGetters['CampaignStatistics/startDateTime'],
				rootGetters['CampaignStatistics/endDateTime'],
				rootGetters['Campaign/Ssps/sspsForStatisticsRequest'],
			);
			if (err) {
				return Promise.reject(err);
			}
			commit('UPDATE_SUMMARY_CAMPAIGN_INFO_DATA', summaryInfo);
			if (getters.isBrandLift) {
				commit('UPDATE_BRAND_LIFT_TOTAL_WIN', summaryInfo.win);
			}
			return Promise.resolve();
		},
		async getFullCampaignInfo({ dispatch }, { isFirstCall, needRecalculateRealTimeUpdate }) {
			const mainCampaignInfoPromise = dispatch('getCampaignMainInfo', {
				isFirstCall,
				needRecalculateRealTimeUpdate,
			});
			const summaryCampaignInfoPromise = dispatch('getCampaignSummaryInfo');
			const fullCampaignInfoPromises = [mainCampaignInfoPromise, summaryCampaignInfoPromise];
			try {
				await Promise.all(fullCampaignInfoPromises);
				return Promise.resolve();
			} catch (err) {
				console.error(err);
				return Promise.reject(err);
			}
		},
		async getCampaignReach({ commit, rootGetters }) {
			if (rootGetters['CampaignStatistics/MainInfo/isBrandLift']) {
				return Promise.resolve();
			}
			const [err, result] = await this.$fetch.campaignAPI.getCampaignReach(
				rootGetters['CampaignStatistics/campaignID'],
			);
			const resultReach = err ? 0 : result.reach;
			commit('UPDATE_REACH', resultReach);
			return Promise.resolve();
		},
		async updateCampaignStatus({ rootGetters }, status) {
			const [err] = await this.$fetch.campaignAPI.updateCampaignStatus(rootGetters['CampaignStatistics/campaignID'], status);
			if (err) {
				showMessage('updateCampaignStatusErrorMSG');
				return;
			}

			const statusForMessage = status !== 'stopped' ? 'запущена' : 'остановлена';
			showMessage('updateCampaignStatusSuccessMSG', statusForMessage);
		},
	},
};
