export default {
	namespaced: true,
	state: () => ({
		osv: {
			from: { major: 0, minor: 0, micro: 0 },
			to: { major: 0, minor: 0, micro: 0 },
		},
	}),
	getters: {
		selectedOsVersion: (state) => state.osv,
		fromOsVersion: (state) => state.osv.from,
		toOsVersion: (state) => state.osv.to,
	},
	mutations: {
		UPDATE_OS_VERSION: (state, osVersion) => {
			state.osv = osVersion;
		},
		UPDATE_SPECIAL_OS_VERSION: (state, { type, level, value }) => {
			state.osv[type][level] = value;
		},
		SET_DEFAULT_OS_VERSION(state) {
			state.osv = {
				from: { major: 0, minor: 0, micro: 0 },
				to: { major: 0, minor: 0, micro: 0 },
			};
		},
	},
};
