import showMessage from '@/helpers/messaging-service';

const unauthorizedErrorHandler = async (store, router) => {
	const noSessionUser = store.state.User.role === 'unauthorized';
	if (noSessionUser && router.currentRoute.value.name === 'Login') {
		return;
	}
	// Save info about current user in session storage
	if (!noSessionUser && router.currentRoute.value.name !== 'NotFound') {
		store.dispatch('User/saveCurrentUserInfo', router.currentRoute.value);
	}
	// Redirect to login page
	const relevantLoginPage = await store.dispatch('User/getRelevantLoginRoute');
	await router.push(relevantLoginPage);
	// Clear vuex auth store, Clear local storage
	store.dispatch('User/removeAuth');
	showMessage('sessionExpiredErrorMSG');
};

const notFoundErrorHandler = async (router) => {
	await router.push({ name: 'NotFound' });
	showMessage('notFoundErrorMSG');
};

export const successResponseInterceptor = (response) => Promise.resolve(response.data ?? []);

export const errorResponseInterceptor = async (error, store, router) => {
	if (error.message === 'canceled') {
		return Promise.reject(error);
	}
	if (error.response) {
		const { response } = error;
		const { status } = response;
		const needToShowFatalError = error.config?.isFatal && status !== 401 && !store.getters['FatalError/isFatalErrorVisible'];
		if (needToShowFatalError) {
			store.commit('FatalError/UPDATE_IS_FATAL_ERROR_VISIBLE', {
				visibleValue: true,
				errorCode: error.response.data.code,
				errorMessage: error.response.data.message,
			});
		}
		if (status === 401) {
			await unauthorizedErrorHandler(store, router);
		}
		if (status === 404) {
			await notFoundErrorHandler(router);
		}

		return Promise.reject(response.data);
	}
	if (error.config?.isFatal) {
		store.commit('FatalError/UPDATE_IS_FATAL_ERROR_VISIBLE', { visibleValue: true });
	}
	return Promise.reject(error);
};
