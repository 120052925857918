<template>
  <div class="header-info-wrapper">
    <p-button
      v-if="isUserCabinetsRoute"
      text="Изменить пароль"
      class="change-password-btn"
      @p-click="openChangePasswordDialog"
      black
    />
    <div class="header-info">
      <span class="header-info__login">{{ userName }}</span>
      <span v-if="isBalanceVisible" class="header-info__balance">Баланс: {{ balance }}</span>
    </div>
    <div class="header-info-wrapper__icon">
      <div class="dropdown-icon" />
      <div class="dropdown-content">
        <div
          v-if="isAccountSessionFromAgency"
          @click="backToAgency"
          class="dropdown-content__button"
        >
          В агентство
        </div>
        <div
          @click="backToCabinetPage"
          v-if="$route.name !== 'UserCabinets'"
          class="dropdown-content__button"
        >
          На страницу кабинетов
        </div>
        <div
          @click="logOut"
          class="dropdown-content__button"
        >
          Выйти
        </div>
      </div>
    </div>
    <dx-popup
      v-model:visible="isChangePasswordDialogVisible"
      :width="500"
      :height="280"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      :show-title="true"
      title="Изменение пароля"
      @hidden="resetPasswordsFields"
    >
      <div class="popup-wrapper">
        <div class="popup-wrapper-content column">
          <div class="dx-field-label">Новый пароль:</div>
          <dx-text-box
            v-model:value.trim="newPassword"
            placeholder="Введите новый пароль"
            width="100%"
            stylingMode="filled"
          />
          <div class="dx-field-label">Новый пароль:</div>
          <dx-text-box
            v-model:value.trim="newPasswordRepeat"
            width="100%"
            placeholder="Введите пароль повторно"
            stylingMode="filled"
          />
        </div>
        <div class="popup-wrapper-footer">
          <div class="popup-wrapper-footer-right">
            <p-button
              @p-click="submitUserPassword"
              text="Изменить пароль"
            />
          </div>
        </div>
      </div>
    </dx-popup>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DxPopup } from 'devextreme-vue/popup';
import { DxTextBox } from 'devextreme-vue/text-box';
import PButton from '@/components/PButton';
import showMessage from '@/helpers/messaging-service';

export default {
	name: 'PHeaderUserInfo',
	components: {
		DxTextBox,
		DxPopup,
		PButton,
	},
	data() {
		return {
			isChangePasswordDialogVisible: false,
			newPassword: '',
			newPasswordRepeat: '',
		};
	},
	computed: {
		...mapGetters('User', [
			'login',
			'balance',
			'userRole',
			'newAuthUser',
			'isAccountSessionFromAgency',
		]),
		...mapGetters('GlobalHooks', [
			'isNotVisibleForGuest',
		]),
		userName() {
			if (this.$route.name === 'UserCabinets') {
				return this.newAuthUser.name;
			}

			return this.login;
		},
		isBalanceVisible() {
			return this.isNotVisibleForGuest && !this.isUserCabinetsRoute;
		},
		isUserCabinetsRoute() {
			return this.$route.name === 'UserCabinets';
		},
	},
	methods: {
		...mapActions({
			removeAuth: 'User/removeAuth',
			getRelevantLoginRoute: 'User/getRelevantLoginRoute',
			changeUserPassword: 'User/changeUserPassword',
		}),
		async backToCabinetPage() {
			await this.$router.push({ name: 'UserCabinets' });
		},
		async backToAgency() {
			const agencyId = sessionStorage.getItem('agency-id');
			const link = this.$router.resolve({
				name: 'AccountsList',
				params: {
					cabinetId: agencyId,
				},
			}).fullPath;

			window.open(link);
		},
		async logOut() {
			await this.$router.push({ name: 'Login' });
		},
		openChangePasswordDialog() {
			this.isChangePasswordDialogVisible = true;
		},
		validatePasswords() {
			const isPasswordsValid = true;
			if (this.newPassword === '') {
				showMessage('noEmptyPasswordFieldErrorMessage');
				return !isPasswordsValid;
			}

			if (this.newPassword.length < 6) {
				showMessage('passwordMinLengthErrorMSG');
				return !isPasswordsValid;
			}

			if (this.newPassword.includes(' ')) {
				showMessage('noSpacesPasswordFieldErrorMessage');
				return !isPasswordsValid;
			}

			if (!/^[^а-яА-ЯЁё]*$/.test(this.newPassword)) {
				showMessage('noCyrillicPasswordFieldErrorMessage');
				return !isPasswordsValid;
			}

			if (this.newPassword !== this.newPasswordRepeat) {
				showMessage('passwordsNotEqualErrorMessage');
				return !isPasswordsValid;
			}

			return isPasswordsValid;
		},
		async submitUserPassword() {
			const isPasswordsValid = this.validatePasswords();
			if (!isPasswordsValid) {
				return;
			}

			const isChangingSuccessful = await this.changeUserPassword({
				password: this.newPasswordRepeat,
			});
			if (!isChangingSuccessful) {
				return;
			}

			showMessage('updateMainPasswordSuccessMSG');
			this.isChangePasswordDialogVisible = false;
		},
		resetPasswordsFields() {
			this.newPassword = '';
			this.newPasswordRepeat = '';
		},
	},
};
</script>

<style lang="less" scoped>
@import url('~@/assets/style/variables');

.popup-wrapper-content {
	align-items: flex-start;
	.dx-field-label {
		align-self: self-start;
	}
}

.change-password-btn {
	margin-right: 25px;
}

.header-info-wrapper {
	display: flex;
	align-items: center;

	.header-info {
		height: @header-height;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		font-size: 14px;

		.header-info__login {
			color: #fff;
			font-weight: 500;
		}

		.header-info__balance {
			color: @secondary-color;
			font-weight: 700;
		}
	}

	& .header-info-wrapper__icon {
		height: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		position: relative;

		& .dropdown-content {
			position: absolute;
			top: 50px;
			right: 10px;
			max-height: 0;
			min-width: 112px;
			box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
			z-index: -100;
			overflow: hidden;
			-webkit-transition: max-height 0.4s linear;
			-moz-transition: max-height 0.4s linear;
			transition: max-height 0.4s linear;
			border-radius: @default-border-radius;

			& .dropdown-content__button {
				min-width: 210px;
				text-transform: uppercase;
				font-weight: 700;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				background-color: #000;
				cursor: pointer;
				height: 40px;
				transition: background-color 0.2s ease-in, color 0.2s ease-in;
				color: white;
			}

			& .dropdown-content__button:hover {
				background-color: @secondary-color;
			}
		}

		& .dropdown-icon {
			width: 24px;
			height: 24px;
			cursor: pointer;
			background-image: url('~@/assets/icons/chevron-down.svg');
			background-repeat: no-repeat;
			transition: background-image 0.3s ease-in;
		}

		& .dropdown-icon:hover {
			background-image: url("~@/assets/icons/chevron-up.svg");
		}
	}

	& .header-info-wrapper__icon:hover .dropdown-content {
		max-height: 350px;
		z-index: 1;
	}
}
</style>
