import showMessage from '@/helpers/messaging-service';

export default {
	namespaced: true,
	state: () => ({
		targetCampaign: '000000000000000000000000',
		additionalInfo: {
			checkingStatus: 'success',
			agencyId: '000000000000000000000000',
			accountId: '000000000000000000000000',
		},
	}),
	getters: {
		resultTargetCampaign: (state) => state.targetCampaign,
		ownerAccountId: (state) => state.additionalInfo.accountId,
		isTargetCampaignEmpty: (state) => state.targetCampaign === '000000000000000000000000' || state.targetCampaign === '',
	},
	mutations: {
		SET_DEFAULT_TARGET_SETTINGS(state) {
			state.targetCampaign = '000000000000000000000000';
			state.additionalInfo.agencyId = '000000000000000000000000';
			state.additionalInfo.accountId = '000000000000000000000000';
			state.additionalInfo.checkingStatus = 'success';
		},
		SET_CABINET_IDS(state, { agencyId, accountId }) {
			state.additionalInfo.agencyId = agencyId ?? '000000000000000000000000';
			state.additionalInfo.accountId = accountId ?? '000000000000000000000000';
		},
		SET_DEFAULT_CABINET_IDS(state) {
			state.additionalInfo.agencyId = '000000000000000000000000';
			state.additionalInfo.accountId = '000000000000000000000000';
		},
		UPDATE_AGENCY_ID(state, agencyId) {
			state.additionalInfo.agencyId = agencyId;
		},
		UPDATE_ACCOUNT_ID(state, accountId) {
			state.additionalInfo.accountId = accountId;
		},
		UPDATE_TARGET_CAMPAIGN(state, campaignId) {
			state.targetCampaign = campaignId;
		},
		UPDATE_CHECKING_STATUS(state, checkingStatus) {
			state.additionalInfo.checkingStatus = checkingStatus;
		},
	},
	actions: {
		async getCampaignOwner({ commit }, { campaignId }) {
			if (campaignId === '000000000000000000000000') {
				commit('UPDATE_CHECKING_STATUS', 'success');
				return Promise.resolve();
			}

			const [err, result] = await this.$fetch.campaignAPI.getCampaignOwner(campaignId);
			if (err) {
				commit('SET_DEFAULT_CABINET_IDS');

				if (err.message === 'no access to campaign') {
					commit('UPDATE_CHECKING_STATUS', 'no-access');
					return Promise.resolve();
				}

				showMessage('targetCampaignNotValidErrorMSG');
				commit('UPDATE_CHECKING_STATUS', 'not-valid');
				commit('UPDATE_TARGET_CAMPAIGN', '000000000000000000000000');
				return Promise.reject(err);
			}

			commit('SET_CABINET_IDS', { agencyId: result.agencyId, accountId: result.accountId });
			commit('UPDATE_CHECKING_STATUS', 'success');
			return Promise.resolve();
		},
	},
};
