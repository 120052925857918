import { sortByField } from '@/helpers/common';

class DictionaryAPI {
	constructor(apiCall) {
		this.apiCall = apiCall;
	}

	async getTaxonomy() {
		try {
			const result = await this.apiCall.get({
				gate: '/dictionary/taxonomy',
				isFatal: true,
			});
			return [null, result];
		} catch (err) {
			return [err];
		}
	}

	async getCountries() {
		try {
			const result = await this.apiCall.get({
				gate: '/dictionary/countries',
				isFatal: true,
			});
			return [null, result];
		} catch (err) {
			return err;
		}
	}

	async getRegionsByCountries(countries) {
		try {
			const result = await this.apiCall.get({
				gate: '/dictionary/regions',
				params: { countries: countries.join(',') },
				isFatal: true,
			});
			return [null, result];
		} catch (err) {
			return [err];
		}
	}

	async getCitiesByRegions(regions) {
		try {
			const result = await this.apiCall.get({
				gate: '/dictionary/cities-by-regions',
				params: { regions: regions.join(',') },
				isFatal: true,
			});
			return [null, result];
		} catch (err) {
			return [err];
		}
	}

	async getOperators() {
		try {
			const result = await this.apiCall.get({
				gate: '/dictionary/constants',
				params: { dict: 'operators' },
				isFatal: true,
			});
			return [null, result];
		} catch (err) {
			return [err];
		}
	}

	async getManufactures() {
		try {
			const result = await this.apiCall.get({
				gate: '/dictionary/manufacturers',
				isFatal: true,
			});
			return [null, result
				.map((manufacture) => ({ id: manufacture, name: manufacture }))
				.sort(sortByField('name'))];
		} catch (err) {
			return [err];
		}
	}

	async getSsps() {
		try {
			const result = await this.apiCall.get({
				gate: '/dictionary/ssp',
				params: { scope: 'plazkart' },
				isFatal: true,
			});
			return [null, result];
		} catch (err) {
			return [err];
		}
	}
}

export default DictionaryAPI;
