import MainGeo from '@/store/modules/campaign/campaign-geo/campaign-geo-main';
import RestrictiveGeo from '@/store/modules/campaign/campaign-geo/campaign-geo-restrictive';
import { findOne } from '@/helpers/common';

export default {
	namespaced: true,
	state: () => ({
		isRegionsLoading: false,
		isCitiesLoading: false,
	}),
	getters: {
		isGeoSelected: (state, getters) => (geoType) => getters.selectedFinalGeo(geoType).length !== 0,
		geoByLevel: (state) => (geoType) => state[geoType].geoByLevel,
		fullGeoByLevel(state, getters) {
			const restrictiveGeoByLevel = { ...getters.geoByLevel('RestrictiveGeo') };
			const mainGeoByLevel = { ...getters.geoByLevel('MainGeo') };
			Object.keys(restrictiveGeoByLevel).forEach((key) => {
				restrictiveGeoByLevel[key] = restrictiveGeoByLevel[key].map((geoItem) => `restrictive_${geoItem}`);
			});
			return {
				cities: [...restrictiveGeoByLevel.cities, ...mainGeoByLevel.cities],
				regions: [...restrictiveGeoByLevel.regions, ...mainGeoByLevel.regions],
				countries: [...restrictiveGeoByLevel.countries, ...mainGeoByLevel.countries],
			};
		},
		selectedFinalGeo: (state) => (geoType) => {
			const selectedFinalGeo = [];
			if (state[geoType].geoByLevel.countries.length === 0) {
				return [];
			}
			if (state[geoType].geoByLevel.regions.length === 0) {
				return state[geoType].geoByLevel.countries;
			}
			if (state[geoType].geoByLevel.cities.length === 0) {
				selectedFinalGeo.push(...state[geoType].geoByLevel.regions);
			} else {
				selectedFinalGeo.push(...state[geoType].geoByLevel.cities);
				const regions = Object.keys(state[geoType].helper.byRegion);
				regions.forEach((region) => {
					const citiesByRegion = state[geoType].helper.byRegion[region];
					if (!findOne(citiesByRegion, state[geoType].geoByLevel.cities)) {
						selectedFinalGeo.push(region);
					}
				});
			}
			if (state[geoType].geoByLevel.countries.length !== 0) {
				const countries = Object.keys(state[geoType].helper.byCountry);
				countries.forEach((country) => {
					const regionsByCountry = state[geoType].helper.byCountry[country];
					if (!findOne(regionsByCountry, state[geoType].geoByLevel.regions)) {
						selectedFinalGeo.push(country);
					}
				});
			}
			return selectedFinalGeo;
		},
		countriesStoreData: (state) => (geoType) => state[geoType].geoStoreData.countries,
		regionsStoreData: (state) => (geoType) => state[geoType].geoStoreData.regions,
		citiesStoreData: (state) => (geoType) => state[geoType].geoStoreData.cities,
		selectedCountries: (state) => (geoType) => state[geoType].geoByLevel.countries,
		selectedRegions: (state) => (geoType) => state[geoType].geoByLevel.regions,
		selectedCities: (state) => (geoType) => state[geoType].geoByLevel.cities,
		helperRegionsByCountry: (state) => ({ country, geoType }) => state[geoType].helper.byCountry[country],
		helperCitiesByRegion: (state) => ({ region, geoType }) => state[geoType].helper.byRegion[region],
	},
	mutations: {
		UPDATE_IS_REGIONS_LOADING(state, payload) {
			state.isRegionsLoading = payload;
		},
		UPDATE_IS_CITIES_LOADING(state, payload) {
			state.isCitiesLoading = payload;
		},
		UPDATE_FULL_GEO_BY_LEVEL(state, { campaignGeoByLevel }) {
			Object.keys(campaignGeoByLevel).forEach((key) => {
				if (!campaignGeoByLevel[key]) {
					state.MainGeo.geoByLevel[key] = [];
					state.RestrictiveGeo.geoByLevel[key] = [];
					return;
				}
				const restrictiveGeo = [];
				const mainGeo = campaignGeoByLevel[key].filter((geoItem) => {
					if (geoItem.includes('restrictive')) {
						restrictiveGeo.push(geoItem.split('_')[1]);
					}
					return !geoItem.includes('restrictive');
				});
				state.MainGeo.geoByLevel[key] = [...mainGeo];
				state.RestrictiveGeo.geoByLevel[key] = [...restrictiveGeo];
			});
		},
		UPDATE_SPECIAL_GEO_BY_LEVEL(state, { geoType, fieldName, value }) {
			state[geoType].geoByLevel[fieldName] = value;
		},
		SET_DEFAULT_GEO_BY_LEVEL(state, geoType) {
			Object.keys(state[geoType].geoByLevel).forEach((key) => {
				state[geoType].geoByLevel[key] = [];
			});
		},
		UPDATE_GEO_STORE_DATA(state, { geoType, fieldName, data }) {
			const initialData = state[geoType].geoStoreData[fieldName];
			state[geoType].geoStoreData[fieldName] = [...initialData, ...data];
		},
		UPDATE_HELPER_REGIONS_BY_COUNTRY(state, { geoType, country, regions }) {
			state[geoType].helper.byCountry[country] = regions;
		},
		UPDATE_HELPER_CITIES_BY_REGION(state, { geoType, region, cities }) {
			state[geoType].helper.byRegion[region] = cities;
		},
		SET_DEFAULT_SPECIAL_GEO_STORE_DATA(state, { geoType, fieldName }) {
			state[geoType].geoStoreData[fieldName] = [];
		},
		SET_DEFAULT_HELPER(state, geoType) {
			Object.keys(state[geoType].helper).forEach((fieldName) => {
				state[geoType].helper[fieldName] = {};
			});
		},
		SET_DEFAULT_SPECIAL_HELPER(state, { geoType, fieldName }) {
			state[geoType].helper[fieldName] = {};
		},
	},
	actions: {
		async getGeoStoresData({ dispatch, state }) {
			await dispatch('getCountries');
			if (state.MainGeo.geoByLevel.countries.length !== 0) {
				await dispatch('getRegionsByCountries', { geoType: 'MainGeo', countries: state.MainGeo.geoByLevel.countries });
			}
			if (state.RestrictiveGeo.geoByLevel.countries.length !== 0) {
				await dispatch('getRegionsByCountries', {
					geoType: 'RestrictiveGeo',
					countries: state.RestrictiveGeo.geoByLevel.countries,
				});
			}
			if (state.MainGeo.geoByLevel.regions.length !== 0) {
				await dispatch('getCitiesByRegions', { geoType: 'MainGeo', regions: state.MainGeo.geoByLevel.regions });
			}
			if (state.RestrictiveGeo.geoByLevel.regions.length !== 0) {
				await dispatch('getCitiesByRegions', {
					geoType: 'RestrictiveGeo',
					regions: state.RestrictiveGeo.geoByLevel.regions,
				});
			}
		},
		async getCountries({ commit }) {
			commit('SET_DEFAULT_SPECIAL_GEO_STORE_DATA', { geoType: 'MainGeo', fieldName: 'countries' });
			commit('SET_DEFAULT_SPECIAL_GEO_STORE_DATA', { geoType: 'RestrictiveGeo', fieldName: 'countries' });
			const [err, countries] = await this.$fetch.dictionaryAPI.getCountries();
			if (err) {
				return Promise.reject(err);
			}
			commit('UPDATE_GEO_STORE_DATA', { geoType: 'MainGeo', fieldName: 'countries', data: countries });
			commit('UPDATE_GEO_STORE_DATA', { geoType: 'RestrictiveGeo', fieldName: 'countries', data: countries });
			return Promise.resolve();
		},
		async getRegionsByCountries({ commit, state }, { geoType, countries }) {
			commit('SET_DEFAULT_SPECIAL_GEO_STORE_DATA', { geoType, fieldName: 'regions' });
			commit('SET_DEFAULT_SPECIAL_HELPER', { geoType, fieldName: 'byCountry' });
			if (countries.length === 0) {
				return Promise.resolve();
			}
			commit('UPDATE_IS_REGIONS_LOADING', true);
			const [err, regionsByCountry] = await this.$fetch.dictionaryAPI.getRegionsByCountries(countries);
			if (err) {
				commit('UPDATE_IS_REGIONS_LOADING', false);
				return Promise.reject(err);
			}
			Object.keys(regionsByCountry).forEach((country) => {
				const { regions } = regionsByCountry[country];
				if (!state[geoType].helper.byCountry.hasOwnProperty(country)) {
					commit('UPDATE_HELPER_REGIONS_BY_COUNTRY', { geoType, country, regions });
				}
				commit('UPDATE_GEO_STORE_DATA', { geoType, fieldName: 'regions', data: regions });
			});
			commit('UPDATE_IS_REGIONS_LOADING', false);
			return Promise.resolve();
		},
		async getCitiesByRegions({ commit, state }, { geoType, regions }) {
			commit('SET_DEFAULT_SPECIAL_GEO_STORE_DATA', { geoType, fieldName: 'cities' });
			commit('SET_DEFAULT_SPECIAL_HELPER', { geoType, fieldName: 'byRegion' });
			if (regions.length === 0) {
				return Promise.resolve();
			}
			commit('UPDATE_IS_CITIES_LOADING', true);
			const [err, citiesByRegion] = await this.$fetch.dictionaryAPI.getCitiesByRegions(regions);
			if (err) {
				commit('UPDATE_IS_CITIES_LOADING', false);
				return Promise.reject(err);
			}
			Object.keys(citiesByRegion).forEach((region) => {
				const { cities } = citiesByRegion[region];
				if (!state[geoType].helper.byRegion.hasOwnProperty(region)) {
					commit('UPDATE_HELPER_CITIES_BY_REGION', { geoType, region, cities });
				}
				commit('UPDATE_GEO_STORE_DATA', { geoType, fieldName: 'cities', data: cities });
			});
			commit('UPDATE_IS_CITIES_LOADING', false);
			return Promise.resolve();
		},
	},
	modules: {
		MainGeo,
		RestrictiveGeo,
	},
};
