const fileToBase64 = (file) => new Promise((resolve) => {
	const reader = new FileReader();
	let result = null;

	(async () => {
		const getResult = () => new Promise((fileResolve) => {
			reader.readAsDataURL(file);

			reader.onload = () => {
				result = reader.result;
				fileResolve(result);
			};
		});

		await getResult();
		resolve(result);
	})();
});

export default fileToBase64;
