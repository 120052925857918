import showMessage from '@/helpers/messaging-service';
import { sortByField } from '@/helpers/common';

export default {
	namespaced: true,
	state: () => ({
		accounts: [],
		selectedAccountID: '',
		reportsCampaignsDailyStore: [],
		reportsBannersDailyStore: [],
		reportsCampaignsTotalStore: [],
		offersReport: [],
		campaignNameByIdMap: {},
	}),
	getters: {
		campaignNameByIdMap: (state) => state.campaignNameByIdMap,
		reportsCampaignsDailyStore: (state) => state.reportsCampaignsDailyStore,
		reportsBannersDailyStore: (state) => state.reportsBannersDailyStore,
		reportsCampaignsTotalStore: (state) => state.reportsCampaignsTotalStore,
		offersReport: (state) => state.offersReport,
		filteredAccounts(state, getters, rootState, rootGetters) {
			return state.accounts.filter((account) => !rootGetters['GlobalHooks/accountsToHideInReportsAccount'].includes(account.id));
		},
	},
	mutations: {
		SET_DEFAULT_REPORTS_MODULE_STATE(state) {
			state.reportsCampaignsDailyStore = [];
			state.reportsBannersDailyStore = [];
			state.reportsCampaignsTotalStore = [];
			state.offersReport = [];
			state.campaignNameByIdMap = {};
		},
		SET_ACCOUNTS(state, payload) {
			state.accounts = payload;
		},
		SET_CAMPAIGNS_STATISTICS_DAILY(state, payload) {
			state.reportsCampaignsDailyStore = payload;
		},
		SET_BANNERS_STATISTICS_DAILY(state, payload) {
			state.reportsBannersDailyStore = payload;
		},
		SET_CAMPAIGNS_STATISTICS_TOTAL(state, payload) {
			state.reportsCampaignsTotalStore = payload;
		},
		SET_OFFERS_REPORT(state, payload) {
			state.offersReport = payload;
		},
		SET_CAMPAIGN_NAME_BY_ID_MAP(state, payload) {
			state.campaignNameByIdMap = payload;
		},
	},
	actions: {
		async getAccounts({ commit }) {
			const [err, accounts] = await this.$fetch.reportsAPI.getAccountsList();
			if (err) {
				return;
			}
			commit('SET_ACCOUNTS', accounts.sort(sortByField('login')));
		},
		async getFullReport({ commit }, data) {
			showMessage('getFullReportWarningMSG');

			const campaignsStatDailyPromise = new Promise((resolve, reject) => {
				this.$fetch.reportsAPI.getCampaignsStatisticsDaily(data.startTime, data.endTime, data.campaignIDs).then(([err, result]) => {
					err ? reject(err) : resolve(result);
				});
			});
			const bannersStatDailyPromise = new Promise((resolve, reject) => {
				this.$fetch.reportsAPI.getBannersStatisticsDaily(data.startTime, data.endTime, data.campaignIDs).then(([err, result]) => {
					err ? reject(err) : resolve(result);
				});
			});
			const campaignsStatTotalPromise = new Promise((resolve, reject) => {
				this.$fetch.reportsAPI.getCampaignsStatistics(data.startTime, data.endTime, data.campaignIDs).then(([err, result]) => {
					err ? reject(err) : resolve(result);
				});
			});

			try {
				// eslint-disable-next-line max-len
				const [
					campaignsStatisticsDaily,
					bannersStatisticsDaily,
					campaignsStatisticsTotal,
				] = await Promise.all([campaignsStatDailyPromise, bannersStatDailyPromise, campaignsStatTotalPromise]);
				this.$fetch.reportsAPI.setDailyAmountIFA(campaignsStatisticsDaily);
				this.$fetch.reportsAPI.setDailyAmountIFA(bannersStatisticsDaily, true);

				commit('SET_CAMPAIGNS_STATISTICS_DAILY', campaignsStatisticsDaily);
				commit('SET_BANNERS_STATISTICS_DAILY', bannersStatisticsDaily);
				commit('SET_CAMPAIGNS_STATISTICS_TOTAL', campaignsStatisticsTotal);
				showMessage('generateReportSuccessMSG');
			} catch (err) {
				showMessage('generateReportErrorMSG');
				console.error(err);
			}
		},
		async getOffersReport({ commit }, data) {
			const [err, offersReport] = await this.$fetch.reportsAPI.getOffersReport(
				new Date(data.startTime).toISOString(),
				new Date(data.endTime).toISOString(),
				180,
				data.campaignIDs,
			);

			if (err) {
				return Promise.reject(err);
			}

			commit('SET_OFFERS_REPORT', offersReport);
			return Promise.resolve();
		},
	},
};
