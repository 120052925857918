import {
	sortByField, getPercents, sortByDate, translateBannerTrackingsToBackendKeys,
	triggerAbortController,
} from '@/helpers/common';

import AuthAPI from '@/api/modules/auth-api';
import AgencyAPI from '@/api/modules/agency-api';
import InventoryAPI from '@/api/modules/inventory-api';
import RetargetingAPI from '@/api/modules/retargeting-api';
import BanAPI from '@/api/modules/ban-api';
import PlayerConfigAPI from '@/api/modules/player-config-api';
import BigListAPI from '@/api/modules/big-list-api';
import ReportsAPI from '@/api/modules/reports-api';
import DictionaryAPI from '@/api/modules/dictionary-api';
import SiteAPI from '@/api/modules/site-api';
import StatisticsAPI from '@/api/modules/statistics-api';
import BannerAPI from '@/api/modules/banner-api';
import CampaignAPI from '@/api/modules/campaign-api';
import GroupAPI from '@/api/modules/group-api';
import BrandLiftAPI from '@/api/modules/brandlift-api';
import CategorizerAPI from '@/api/modules/categorizer-api';

// eslint-disable-next-line func-names
export default function (apiCall) {
	return {
		authAPI: new AuthAPI(apiCall),
		agencyAPI: new AgencyAPI(apiCall),
		inventoryAPI: new InventoryAPI(apiCall),
		retargetingAPI: new RetargetingAPI(apiCall),
		banAPI: new BanAPI(apiCall),
		playerConfigAPI: new PlayerConfigAPI(apiCall, sortByField),
		bigListAPI: new BigListAPI(apiCall),
		reportsAPI: new ReportsAPI(apiCall, getPercents),
		dictionaryAPI: new DictionaryAPI(apiCall),
		siteAPI: new SiteAPI(apiCall),
		statisticsAPI: new StatisticsAPI(apiCall, sortByDate),
		bannerAPI: new BannerAPI(apiCall, translateBannerTrackingsToBackendKeys),
		groupAPI: new GroupAPI(apiCall),
		campaignAPI: new CampaignAPI(apiCall, getPercents),
		brandLiftAPI: new BrandLiftAPI(apiCall),
		categorizerAPI: new CategorizerAPI(apiCall),
		triggerAbortController,
	};
}
