<template>
  <div class="empty-layout-wrapper">
    <router-view />
  </div>
</template>

<script>
export default {
	name: 'EmptyLayout',
};
</script>

<style lang="less" scoped>
.empty-layout-wrapper {
	position: relative;
	min-height: 100vh;
	overflow: hidden;
}
</style>
