<template>
  <header class="header">
    <router-link :to="mainPageLink">
      <img
        src="@/assets/images/logo-simplified-white.svg"
        alt=""
        class="header-logo"
      />
    </router-link>
    <div class="header-menu">
      <div v-if="isCreateCampaignBtnVisible" class="header-menu-create-button-container">
        <router-link :to="createCampaignLink">
          <p-button
            v-if="isCreateCampaignButtonVisible"
            text="Создать кампанию"
            black
          />
        </router-link>
      </div>
      <p-header-nav-items v-if="isNavItemsVisible" />
      <p-header-user-info />
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import PHeaderNavItems from '@/components/PHeader/PHeaderNavItems';
import PHeaderUserInfo from '@/components/PHeader/PHeaderUserInfo';
import PButton from '@/components/PButton';
import { mainPathByRole } from '@/router/utils/router-helpers';

export default {
	name: 'PHeader',
	components: {
		PHeaderNavItems,
		PHeaderUserInfo,
		PButton,
	},
	props: {
		isNavItemsVisible: {
			type: Boolean,
		},
		isCreateCampaignBtnVisible: {
			type: Boolean,
		},
	},
	data() {
		return {};
	},
	computed: {
		...mapGetters({
			userRole: 'User/userRole',
			isCreateCampaignButtonVisible: 'GlobalHooks/isCreateCampaignButtonVisible',
		}),
		mainPageLink() {
			if (this.$route.name === 'UserCabinets') {
				return { name: 'UserCabinets' };
			}

			return mainPathByRole[this.userRole];
		},
		createCampaignLink() {
			return { name: 'CampaignCreate' };
		},
	},
	mounted() {
	},
	methods: {},
};
</script>

<style lang="less" scoped>
@import url('~@/assets/style/variables');

.header {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 77777;
	height: @header-height;
	background-color: @primary-color;
	padding: 0 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-shadow: 1px 21px 22px rgba(0, 0, 0, 0.14), 0 9px 10px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2);

	&-menu {
		display: flex;
		align-items: center;

		&-create-button-container {
			display: flex;
			position: absolute;
			left: 144px;
		}
	}

	&-logo {
		height: 27px;
		display: block;
		cursor: pointer;
	}
}
</style>
