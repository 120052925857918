class BigListAPI {
	constructor(apiCall) {
		this.apiCall = apiCall;
	}

	async getAllDomainsBigList() {
		try {
			const result = await this.apiCall.get({
				gate: '/biglist-all',
				isFatal: true,
			});
			return [null, result.filter((list) => list.listType === 'dm')];
		} catch (err) {
			return [err];
		}
	}

	async getAllIpBigList() {
		try {
			const result = await this.apiCall.get({
				gate: '/biglist-all',
				isFatal: true,
			});
			return [null, result.filter((list) => list.listType === 'ip')];
		} catch (err) {
			return [err];
		}
	}

	async getBigListContent(listID) {
		try {
			const result = await this.apiCall.get({
				gate: `/biglist/${listID}`,
				isFatal: true,
			});
			return [null, result.content];
		} catch (err) {
			return [err];
		}
	}

	async updateBigList(addValues, delValues, listID) {
		const body = {
			addValues,
			delValues,
			listID,
		};
		try {
			await this.apiCall.put('/biglist', body);
			return [null];
		} catch (err) {
			return [err];
		}
	}

	async removeBigList(listID) {
		try {
			await this.apiCall.delete('/biglist', { listID });
			return [null];
		} catch (err) {
			return [err];
		}
	}

	async createBigList(file, listName, listType) {
		const bigListFormData = new FormData();
		bigListFormData.append('fileName', 'domainsListFile');
		bigListFormData.append('listType', listType);
		bigListFormData.append('listName', listName);
		bigListFormData.append('file', file);
		try {
			await this.apiCall.post('/biglist', bigListFormData);
			return [null];
		} catch (err) {
			return [err];
		}
	}
}

export default BigListAPI;
