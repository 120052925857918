const errorRoutes = [
	{
		path: '404',
		name: 'NotFound',
		meta: {
			title: 'Страница не найдена',
		},
		component: () => import('@/views/errors/Error404'),
	},
	{
		path: '502',
		name: 'BadGateway',
		component: () => import('@/views/errors/Error502'),
	},
];

export default errorRoutes;
