export default {
	namespaced: true,
	state: () => ({
		accountID: '',
		guestConfig: {
			login: '',
			showReach: false,
			showBudget: false,
			showCPC: false,
			showCPM: false,
			showWithMargin: false,
			showStatus: false,
			showActionColumn: false,
			showWin: false,
			showVtr: false,
			showQuartiles: false,
			showCtr: false,
			showClick: false,
			showPlatformsTab: false,
			showCampaignLogsBtn: false,
			showBanColumn: false,
			showPlatformTypeSelector: false,
			showCPV: false,
		},
		guestPasswords: [],
	}),
	getters: {
		accountLogin: (state) => state.guestConfig.login,
		specialGuestConfigAccess: (state) => (fieldName) => state.guestConfig[fieldName],
	},
	mutations: {
		SET_DEFAULT_GUEST_CONFIG(state) {
			state.guestConfig.login = '';
			Object.keys(state.guestConfig).forEach((key) => {
				if (key !== 'login') {
					state.guestConfig[key] = false;
				}
			});
		},
		SET_DEFAULT_GUEST_PASSWORDS(state) {
			state.accountID = [];
		},
		SET_DEFAULT_ACCOUNT_ID(state) {
			state.accountID = '';
		},
		UPDATE_GUEST_CONFIG(state, payload) {
			Object.keys(state.guestConfig).forEach((key) => {
				state.guestConfig[key] = payload[key];
			});
		},
		UPDATE_SPECIAL_GUEST_CONFIG_ACCESS(state, { fieldName, value }) {
			state.guestConfig[fieldName] = value;
		},
		UPDATE_GUEST_PASSWORDS(state, payload) {
			state.guestPasswords = payload;
		},
		UPDATE_ACCOUNT_ID(state, payload) {
			state.accountID = payload;
		},
	},
	actions: {
		async getGuestConfig({ state, commit }) {
			const [err, guestConfig] = await this.$fetch.agencyAPI.getGuestConfig(state.accountID);
			if (err) {
				return Promise.reject(err);
			}
			commit('UPDATE_GUEST_CONFIG', guestConfig);
			return Promise.resolve(guestConfig);
		},
		async getGuestPasswords({ state, commit }) {
			const [err, guestPasswordsList] = await this.$fetch.agencyAPI.getGuestPasswords(state.accountID);
			if (err) {
				return Promise.reject(err);
			}
			commit('UPDATE_GUEST_PASSWORDS', guestPasswordsList);
			return Promise.resolve(guestPasswordsList);
		},
		async getFullAccountSettingsInfo({ dispatch }) {
			const guestConfigPromise = dispatch('getGuestConfig');
			const guestPasswordsPromise = dispatch('getGuestPasswords');
			const accountSettingsPromises = [guestConfigPromise, guestPasswordsPromise];
			try {
				await Promise.all(accountSettingsPromises);
				return Promise.resolve();
			} catch (err) {
				console.error(err);
				return Promise.reject();
			}
		},
	},
};
