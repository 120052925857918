import showMessage from '@/helpers/messaging-service';

export default {
	namespaced: true,
	state: () => ({
		segments: [],
		isUpsertSegmentPopupVisible: false,
		isInfoSegmentPopupVisible: false,
		upsertSegmentPopupMode: '',
		currentSegment: {
			id: '',
			name: '',
			ttl: 30,
		},
	}),
	getters: {
		allSegmentsIds: (state) => state.segments.map((segment) => segment.id),
	},
	mutations: {
		UPDATE_SEGMENTS(state, payload) {
			state.segments = payload;
		},
		SET_DEFAULT_SEGMENTS(state) {
			state.segments = [];
		},
		SET_DEFAULT_CURRENT_SEGMENT(state) {
			state.currentSegment.id = '';
			state.currentSegment.name = '';
			state.currentSegment.ttl = 30;
		},
		TOGGLE_IS_UPSERT_SEGMENT_POPUP_VISIBLE(state, { value, mode = '' }) {
			state.upsertSegmentPopupMode = mode;
			state.isUpsertSegmentPopupVisible = value;
		},
		TOGGLE_IS_INFO_SEGMENT_POPUP_VISIBLE(state, { value }) {
			state.isInfoSegmentPopupVisible = value;
		},
		UPDATE_CURRENT_SEGMENT(state, segmentData) {
			state.currentSegment.name = segmentData.name;
			state.currentSegment.id = segmentData.id;
		},
		UPDATE_CURRENT_SEGMENT_BY_FIELD(state, { fieldName, value }) {
			state.currentSegment[fieldName] = value;
		},
	},
	actions: {
		async getRetargetingSegments({ commit }) {
			const [err, segments] = await this.$fetch.retargetingAPI.getRetargetingSegments();
			if (err) {
				return Promise.reject(err);
			}
			commit('UPDATE_SEGMENTS', segments);
			return Promise.resolve();
		},
		async recalculateCountOfUsers({ getters }) {
			const [err] = await this.$fetch.retargetingAPI.recalculateCountOfUsers(getters.allSegmentsIds);
			if (err) {
				return Promise.reject(err);
			}
			showMessage('recalculateSegmentCountOfUsersSuccessMSG');
			return Promise.resolve();
		},
	},
};
