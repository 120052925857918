import { confirm } from 'devextreme/ui/dialog';

const confirmMessages = {
	/**
	 * src\components\AccountsList\PAccountsListTableBox
	 * метод setCharge
	 */
	setChargeConfirm: () => (
		'<p style="text-align: center">Смена маржи приведет к пересчету сумм<br>в гостевом кабинете за прошедший период.<br><br>Вы уверены?</p>'
	),

	/**
	 * src\views\agency\AccountSettings
	 */
	removeGuestPasswordConfirm: () => '<p>Вы уверены, что хотите удалить данный гостевой пароль?</p>',
	changeGuestAccessConfirm: () => '<p>Вы уверены, что хотите изменить настройки гостевых доступов?</p>',
	changeMainPasswordConfirm: () => '<p>Вы уверены, что хотите изменить основной пароль?</p>',

	/**
	 * src\components\specified\CampaignStatistics\PStatisticBoxBannersTab
	 * src\store\modules\campaign\campaign-banners.js
	 * метод removeGuestPassword
	 */
	removeBannerConfirm: (bannerName = '') => `<p>Вы уверены, что хотите удалить объявление ${bannerName}?</p>`,

	/**
	 * src\components\Inventory\PBigListsTableBox
	 * метод removeBigList
	 * метод removeSelectedDomainsFromBigListContentTable
	 * метод removeOneDomainFromBigListContentTable
	 */
	removeBigListConfirm: (bigListName) => `<p>Вы уверены, что хотите удалить список площадок ${bigListName}?</p>`,
	removeIpBigListConfirm: (bigListName) => `<p>Вы уверены, что хотите удалить список IP ${bigListName}?</p>`,
	removeSelectedDomainsFromBigListContentTableConfirm: () => '<p>Вы уверены, что хотите удалить из списка выбранные домены?</p>',
	removeOneDomainFromBigListContentTable: (domainName) => `<p>Вы уверены, что хотите удалить домен ${domainName}?</p>`,

	/**
	 * src\components\PlayerCustomization\PPlayerCustomizationTableBox
	 * метод removePlayerCustomizationConfig
	 */
	removePlayerCustomizationConfigConfirm: (configName) => `<p>Вы уверены, что хотите удалить шаблон кастомизации плеера ${configName}?</p>`,

	/**
	 * src\views\account\Retargeting\SegmentsTableBox
	 * метод removeSegment
	 */
	removeSegmentConfirm: (segmentName = '') => `<p>Вы уверены, что хотите удалить сегмент ${segmentName}?</p>`,

	/**
	 * src\views\account\GroupsManagement\GroupsTableBox
	 * метод removeGroup
	 */
	removeGroupConfirm: (groupName = '') => `<p>Вы уверены, что хотите удалить группу ${groupName}?</p>`,

	/**
	 * src\views\account\GroupsSettings\GroupCampaignsTableBox\GroupControls
	 * метод updateGroupStatus
	 */
	updateGroupPausedStatusConfirm: (groupName = '') => `<p>Вы уверены, что хотите запустить группу ${groupName}?</p>`,
	updateGroupStoppedStatusConfirm: (groupName = '') => `<p>Вы уверены, что хотите остановить группу ${groupName}?</p>`,

	/**
	 * src\views\account\GroupsSettings\GroupCampaignsTableBox\index
	 */
	upsertGroupCampaignLimitsConfirm: (campaignName = '') => `<p>Вы уверены, что хотите изменить ограничения для кампании ${campaignName}?</p>`,
	removeCampaignFromGroupConfirm: (campaignName = '') => `<p>Вы уверены, что хотите удалить кампанию ${campaignName} из группы?</p>`,

	/**
	 * src\views\account\ProviderPanelSegments\index
	 */
	removeProviderPanelSegment: (segmentName = '') => `<p>Вы уверены, что хотите удалить cегмент ${segmentName}?</p>`,

	/**
	 * src\views\account\UpsertCampaign\AdsTab\index
	 */
	changeBannersCpmConfirm: () => '<p>Вы уверены, что хотите изменить ставку для объявлений?</p>',

	/**
	 * src\views\agency\AccountSettings\GuestAccessPricesTable
	 */
	campaignGuestAccessPricesUpdateConfirm: () => '<p>Вы уверены, что хотите изменить цены гостевого доступа?</p>',
};

const showConfirm = (keyConfirmMessages, ...props) => confirm(confirmMessages[keyConfirmMessages](...props), 'Подтверждение');

export default showConfirm;
