export default {
	namespaced: true,
	state: () => ({
		siteCategories: [],
		selectedSiteCategories: [],
		selectedSiteCategoriesNames: [],
		blackSiteCategories: false,
	}),
	getters: {
		siteCategories: (state) => JSON.parse(JSON.stringify(state.siteCategories)),
		isSelectedSiteCategories: (state) => state.selectedSiteCategories.length !== 0,
		selectedSiteCategories: (state) => state.selectedSiteCategories,
		selectedSiteCategoriesNames: (state) => state.selectedSiteCategoriesNames,
		blackSiteCategories: (state) => state.blackSiteCategories,
	},
	mutations: {
		UPDATE_SITE_CATEGORIES: (state, categories) => {
			state.siteCategories = categories;
		},
		SET_DEFAULT_SITE_CATEGORIES(state) {
			state.selectedSiteCategories = [];
			state.selectedSiteCategoriesNames = [];
			state.blackSiteCategories = false;
		},
		CLEAR_SELECTED_SITE_CATEGORIES: (state) => {
			state.selectedSiteCategories = [];
			state.siteCategories = state.siteCategories.map((category) => ({ ...category, selected: false }));
		},
		UPDATE_SELECTED_SITE_CATEGORIES: (state, categories) => {
			state.selectedSiteCategories = categories;
		},
		UPDATE_SELECTED_SITE_CATEGORIES_NAMES: (state, categories) => {
			state.selectedSiteCategoriesNames = categories;
		},
		UPDATE_BLACK_SITE_CATEGORIES: (state, value) => {
			state.blackSiteCategories = value;
		},
	},
	actions: {
		async getSiteCategories({ state, commit }) {
			const [err, data] = await this.$fetch.siteAPI.getSiteCategories();
			if (err) {
				return Promise.reject(err);
			}
			const categories = data.map((item) => {
				const category = { ...item };
				if (item.parent === '000000000000000000000000') {
					delete category.parent;
				}
				category.selected = state.selectedSiteCategories.includes(category.id);
				return category;
			});
			commit('UPDATE_SITE_CATEGORIES', categories);
			return Promise.resolve();
		},
	},
};
