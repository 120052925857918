import { formatNumber } from '@/helpers/common';
import showMessage from '@/helpers/messaging-service';

export default {
	namespaced: true,
	state: () => ({
		access: '',
		agency: '',
		balance: 0,
		guestAccess: {
			showBudget: false,
			showCPC: false,
			showCPM: false,
			showCPV: false,
			showReach: false,
			showWithMargin: false,
			showStatus: false,
			showActionColumn: false,
			showWin: false,
			showVtr: false,
			showQuartiles: false,
			showCtr: false,
			showClick: false,
			showPlatformsTab: false,
			showCampaignLogsBtn: false,
			showBanColumn: false,
			showPlatformTypeSelector: false,
		},
		id: '',
		isGuest: false,
		login: '',
		role: 'unauthorized',
		session: '',
		newAuthUser: {
			name: '',
			login: '',
			id: '',
			isBalanceVisible: false,
		},
		cabinets: [],
	}),
	getters: {
		login(state) {
			return state.login;
		},
		balance(state) {
			return formatNumber(state.balance, 'currency', 2);
		},
		accountID(state) {
			return state.id;
		},
		fullCurrentCabinet(state) {
			return {
				agencyId: state.agency,
				accountId: state.id,
			};
		},
		userID(state) {
			return state.id;
		},
		isGuest(state) {
			return state.isGuest;
		},
		userRole(state) {
			return state.role;
		},
		userAccess(state) {
			return state.access;
		},
		agencyCabinets: (state) => state.cabinets.filter((cabinet) => cabinet.type === 'agency'),
		favoriteAgencyCabinets(state, getters) {
			return getters.agencyCabinets.filter((cabinet) => cabinet.favorite);
		},
		accountCabinets: (state) => state.cabinets.filter((cabinet) => cabinet.type === 'account'),
		favoriteAccountCabinets(state, getters) {
			return getters.accountCabinets.filter((cabinet) => cabinet.favorite);
		},
		guestAccessProperty: (state) => (fieldName) => state.guestAccess[fieldName],
		isAccountSessionFromAgency(state) {
			const agencyId = sessionStorage.getItem('agency-id');
			return !!(state.role === 'account' && agencyId);
		},
		newAuthUser: (state) => state.newAuthUser,
	},
	mutations: {
		CLEAR_USER_INFO(state) {
			state.access = '';
			state.agency = '';
			state.balance = 0;
			state.guestAccess = {};
			state.id = '';
			state.isGuest = false;
			state.login = '';
			state.role = 'unauthorized';
			state.session = '';
			state.newAuthUser.name = '';
			state.newAuthUser.id = '';
			state.newAuthUser.login = '';
			state.newAuthUser.isBalanceVisible = false;
			state.cabinets = [];
		},
		UPDATE_USER_SESSION(state, payload) {
			state.session = payload;
		},
		UPDATE_NEW_AUTH_USER(state, payload) {
			state.newAuthUser.id = payload.id;
			state.newAuthUser.name = payload.name;
			state.newAuthUser.login = payload.login;
			state.newAuthUser.isBalanceVisible = payload.commonPermissions.balance;
		},
		UPDATE_USER_CABINETS(state, payload) {
			state.cabinets = payload;
		},
		UPDATE_USER_INFO(state, payload) {
			Object.keys(payload).forEach((key) => {
				state[key] = payload[key];
			});
		},
	},
	actions: {
		async setAuth({ commit }, { login, password }) {
			const [err, token] = await this.$fetch.authAPI.createSession(login, password);
			if (!err) {
				localStorage.setItem('session', token);
				localStorage.setItem('current-cabinet-type', 'cabinets');
				commit('UPDATE_USER_SESSION', token);
			}
			return !err;
		},
		removeAuth({ commit }) {
			commit('CLEAR_USER_INFO');
			localStorage.clear();
			sessionStorage.clear();
		},
		async updateUserInfo({ commit, rootState }) {
			const [err, userInfo] = await this.$fetch.authAPI.getSessionInfo();
			if (!err) {
				userInfo.isGuest = userInfo.isGuest || rootState.GlobalHooks.reportsAccountID === userInfo.id;
				commit('UPDATE_USER_INFO', userInfo);
			}
			return !err;
		},
		async getNewAuthUser({ commit }) {
			const [err, newAuthUser] = await this.$fetch.authAPI.getNewAuthUser();
			if (!err) {
				commit('UPDATE_NEW_AUTH_USER', newAuthUser);
			}
			return !err;
		},
		async getUserCabinets({ commit }) {
			const [err, cabinets] = await this.$fetch.authAPI.getUserCabinets();
			if (!err) {
				commit('UPDATE_USER_CABINETS', cabinets);
			}
			return !err;
		},
		async changeUserPassword({ state }, { password }) {
			const isSuccessful = true;
			const [err] = await this.$fetch.authAPI
				.updateUserPassword({ password, userId: state.newAuthUser.id });

			if (err) {
				showMessage('updateMainPasswordErrorMSG');
				return !isSuccessful;
			}

			return isSuccessful;
		},
		saveCurrentUserInfo({ state }, lastRoute) {
			sessionStorage.setItem('last-user-info', JSON.stringify({
				accountID: state.id,
				role: state.role,
				route: {
					name: lastRoute.name,
					params: lastRoute.params,
				},
			}));
		},
		getRelevantLoginRoute() {
			return { name: 'Login' };
		},
	},
};
