function setBanToPlatforms(map, rawData) {
	const localMap = JSON.parse(JSON.stringify(map));
	const localRawData = JSON.parse(JSON.stringify(rawData));
	localRawData.forEach((item) => {
		const currentMapItem = localMap[item.groupBy];
		if (!currentMapItem) {
			return;
		}
		item.ban = currentMapItem.ban;
		item.id = currentMapItem.id;
	});
	return localRawData;
}

export default {
	namespaced: true,
	state: () => ({
		eventInfoData: {
			device: [],
			site: [],
			geolocation: [],
			domain: [],
		},

		banIDsMap: {},
		initialBanIDsMap: {},
		selectedPlatformType: 'domain',
	}),
	getters: {
		IDsForBan(state) {
			const banIDs = [];
			const unBanIDs = [];

			Object.keys(state.banIDsMap).forEach((key) => {
				if (state.initialBanIDsMap[key].ban !== state.banIDsMap[key].ban) {
					state.banIDsMap[key].ban ? banIDs.push(state.banIDsMap[key].id) : unBanIDs.push(state.banIDsMap[key].id);
				}
			});
			return [banIDs, unBanIDs];
		},
	},
	mutations: {
		UPDATE_EVENT_INFO_DATA(state, { eventInfoType, eventInfoData }) {
			state.eventInfoData[eventInfoType] = eventInfoData;
		},
		UPDATE_INITIAL_BAN_IDS_MAP(state, payload) {
			state.initialBanIDsMap = payload;
		},
		SET_BAN_INFO_TO_PLATFORMS(state, eventInfoType) {
			state.eventInfoData[eventInfoType] = setBanToPlatforms(state.initialBanIDsMap, state.eventInfoData[eventInfoType]);
		},
		UPDATE_BAN_IDS_MAP(state, { platformName, banValue, banID }) {
			state.banIDsMap[platformName] = {
				ban: banValue,
				id: banID,
			};
		},
		UPDATE_SELECTED_PLATFORM_TYPE(state, platformType) {
			state.selectedPlatformType = platformType;
		},
		SET_DEFAULT_BAN_IDS_MAP(state) {
			state.banIDsMap = {};
		},
		SET_DEFAULT_INITIAL_BAN_IDS_MAP(state) {
			state.initialBanIDsMap = {};
		},
		SET_DEFAULT_EVENT_INFO_DATA(state) {
			Object.keys(state.eventInfoData).forEach((key) => {
				state.eventInfoData[key] = [];
			});
		},
	},
	actions: {
		async getEventInfoData({ commit, rootGetters }, eventInfoType) {
			const [analyticsErr, result] = await this.$fetch.campaignAPI
				.getAnalytics(
					eventInfoType,
					rootGetters['CampaignStatistics/campaignID'],
					rootGetters['CampaignStatistics/startDateTime'],
					rootGetters['CampaignStatistics/endDateTime'],
					rootGetters['Campaign/Ssps/sspsForStatisticsRequest'],
				);
			if (analyticsErr) {
				return Promise.reject(analyticsErr);
			}
			if (eventInfoType === 'domain') {
				commit('SET_DEFAULT_BAN_IDS_MAP');
				const [banListErr, banListMap] = await this.$fetch.banAPI.getBanListMapByCampaign(rootGetters['CampaignStatistics/campaignID'], eventInfoType);
				if (banListErr) {
					return Promise.reject(banListErr);
				}
				commit('UPDATE_EVENT_INFO_DATA', { eventInfoType, eventInfoData: result });
				commit('UPDATE_INITIAL_BAN_IDS_MAP', banListMap);
				commit('SET_BAN_INFO_TO_PLATFORMS', eventInfoType);
				return Promise.resolve();
			}
			commit('UPDATE_EVENT_INFO_DATA', { eventInfoType, eventInfoData: result });
			return Promise.resolve();
		},
	},
};
