import { twoSymbolsString } from '@/helpers/common';

export default {
	namespaced: true,
	state: () => ({
		dates: {
			from: new Date(),
			to: new Date().setHours(23, 59, 59, 999),
		},
		isWeekDaysEnabled: false,
		weekDays: {
			monday: {
				enabled: true,
				hours: [],
			},
			tuesday: {
				enabled: true,
				hours: [],
			},
			wednesday: {
				enabled: true,
				hours: [],
			},
			thursday: {
				enabled: true,
				hours: [],
			},
			friday: {
				enabled: true,
				hours: [],
			},
			saturday: {
				enabled: true,
				hours: [],
			},
			sunday: {
				enabled: true,
				hours: [],
			},
		},
	}),
	getters: {
		finalCalendar(state, getters) {
			return {
				dates: [
					{
						from: new Date(state.dates.from),
						to: new Date(state.dates.to),
					},
				],
				enabled: true,
				week_days: {
					enabled: state.isWeekDaysEnabled,
					...getters.finalWeekDays,
				},
			};
		},
		finalWeekDays(state) {
			const days = Object.keys(state.weekDays);
			const finalWeekDays = {};
			days.forEach((day) => {
				const initialWeekDay = state.weekDays[day];
				finalWeekDays[day] = {};
				const resultWeekDay = finalWeekDays[day];
				resultWeekDay.enabled = initialWeekDay.enabled;
				resultWeekDay.hours = [];
				initialWeekDay.hours.forEach((initialHourItem) => {
					const resultHourItem = {
						from: {
							hour: new Date(initialHourItem.from).getHours(),
							minute: new Date(initialHourItem.from).getMinutes(),
						},
						to: {
							hour: new Date(initialHourItem.to).getHours(),
							minute: new Date(initialHourItem.to).getMinutes(),
						},
					};
					resultWeekDay.hours.push(resultHourItem);
				});
			});
			return finalWeekDays;
		},
		selectedWeekDaysWithFormatting(state, getters) {
			const selectedWeekDays = {};
			const days = Object.keys(getters.finalWeekDays);
			days.forEach((day) => {
				if (getters.finalWeekDays[day].enabled) {
					selectedWeekDays[day] = JSON.parse(JSON.stringify(getters.finalWeekDays[day]));
					selectedWeekDays[day].hours.forEach((hourItem) => {
						hourItem.from.hour = twoSymbolsString(hourItem.from.hour);
						hourItem.from.minute = twoSymbolsString(hourItem.from.minute);
						hourItem.to.hour = twoSymbolsString(hourItem.to.hour);
						hourItem.to.minute = twoSymbolsString(hourItem.to.minute);
					});
				}
			});
			return selectedWeekDays;
		},
	},
	mutations: {
		UPDATE_DATES(state, { from, to }) {
			state.dates.from = from;
			state.dates.to = to;
		},
		UPDATE_WEEK_DAYS_ENABLED(state, value) {
			state.isWeekDaysEnabled = value;
		},
		UPDATE_SPECIAL_WEEK_DAY_ENABLED(state, { day, value }) {
			state.weekDays[day].enabled = value;
		},
		ADD_HOUR_ITEM_TO_WEEK_DAY(state, day) {
			const emptyItem = {
				from: new Date().setHours(0, 0),
				to: new Date().setHours(23, 59),
			};
			state.weekDays[day].hours = [...state.weekDays[day].hours, emptyItem];
		},
		UPDATE_HOUR_ITEM_TIME_VALUE(state, {
			day, index, fieldName, value,
		}) {
			state.weekDays[day].hours[index][fieldName] = value;
		},
		REMOVE_HOUR_ITEM_FROM_WEEK_DAY(state, { day, index }) {
			const hoursByWeekDay = state.weekDays[day].hours;
			hoursByWeekDay.splice(index, 1);
		},
		UPDATE_SPECIAL_DATE(state, { fieldName, value }) {
			state.dates[fieldName] = value;
		},
		SET_DEFAULT_DATES(state) {
			state.dates.from = new Date();
			state.dates.to = new Date().setHours(23, 59, 59, 999);
		},
		SET_DEFAULT_IS_WEEK_DAYS_ENABLED(state) {
			state.isWeekDaysEnabled = false;
		},
		SET_DEFAULT_SPECIAL_WEEK_DAY_ENABLED(state, day) {
			state.weekDays[day].enabled = true;
		},
		SET_DEFAULT_SPECIAL_WEEK_DAY_HOURS(state, day) {
			state.weekDays[day].hours = [];
		},
		ADD_TRANSLATED_HOURS_ITEM_TO_WEEK_DAY(state, { day, translatedHoursItem }) {
			state.weekDays[day].hours.push(translatedHoursItem);
		},
	},
	actions: {
		updateWeekDays({ commit }, weekDays) {
			commit('UPDATE_WEEK_DAYS_ENABLED', weekDays.enabled);
			const days = Object.keys(weekDays).filter((day) => day !== 'enabled');
			days.forEach((day) => {
				commit('UPDATE_SPECIAL_WEEK_DAY_ENABLED', { day, value: weekDays[day].enabled });
				commit('SET_DEFAULT_SPECIAL_WEEK_DAY_HOURS', day);
				const hoursByDay = weekDays[day].hours;
				if (hoursByDay) {
					hoursByDay.forEach((hoursItem) => {
						const translatedHoursItem = {};
						translatedHoursItem.from = new Date().setHours(hoursItem.from.hour, hoursItem.from.minute);
						translatedHoursItem.to = new Date().setHours(hoursItem.to.hour, hoursItem.to.minute);
						commit('ADD_TRANSLATED_HOURS_ITEM_TO_WEEK_DAY', { day, translatedHoursItem });
					});
				} else {
					commit('ADD_HOUR_ITEM_TO_WEEK_DAY', day);
				}
			});
		},
		setDefaultCalendar({ dispatch, commit }) {
			commit('SET_DEFAULT_DATES');
			commit('SET_DEFAULT_IS_WEEK_DAYS_ENABLED');
			dispatch('setDefaultWeekDays');
		},
		setDefaultWeekDays({ state, commit }) {
			const days = Object.keys(state.weekDays);
			days.forEach((day) => {
				commit('SET_DEFAULT_SPECIAL_WEEK_DAY_ENABLED', day);
				commit('SET_DEFAULT_SPECIAL_WEEK_DAY_HOURS', day);
				commit('ADD_HOUR_ITEM_TO_WEEK_DAY', day);
			});
		},
	},
};
