export default {
	namespaced: true,
	state: () => ({
		globalIsLoading: false,
		globalIsLoadingButton: false,
	}),
	getters: {
		globalIsLoading(state) {
			return state.globalIsLoading;
		},
	},
	mutations: {
		UPDATE_GLOBAL_IS_LOADING(state, value) {
			state.globalIsLoading = value;
		},
		UPDATE_GLOBAL_IS_LOADING_BUTTON(state, payload) {
			state.globalIsLoadingButton = payload;
		},
	},
	actions: {},
};
