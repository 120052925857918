import fileToBase64 from '@/helpers/file-to-base64';

class CampaignAPI {
	constructor(apiCall, getPercents) {
		this.apiCall = apiCall;
		this.getPercents = getPercents;
		this.devicesNameMap = {
			0: 'Не определено',
			1: 'Смартфон или планшет',
			2: 'Персональный компьютер',
			3: 'Телевизор',
			4: 'Смартфон',
			5: 'Планшет',
		};
		this.analyticsController = {
			geolocation: null,
			domain: null,
			device: null,
		};
		this.campaignReachController = null;
	}

	async getCampaignData(campaignID) {
		try {
			const result = await this.apiCall.get({
				gate: '/campaign',
				params: { id: campaignID },
				isFatal: true,
			});
			return [null, result];
		} catch (err) {
			return [err];
		}
	}

	async getLightCampaigns({
		startTime, endTime, type, status, hasGroup, account, onlyTarget = true,
	}) {
		try {
			const result = await this.apiCall.get({
				gate: '/campaign/list/compact',
				params: {
					startTime,
					endTime,
					type,
					status,
					hasGroup,
					withState: false,
					onlyTarget,
					account,
				},
				isFatal: true,
			});
			return [null, result];
		} catch (err) {
			return [err];
		}
	}

	async getCampaignOwner(campaignId) {
		try {
			const result = await this.apiCall.get({
				gate: `/campaign/${campaignId}/owner`,
			});
			return [null, result];
		} catch (err) {
			return [err];
		}
	}

	async getCampaignReach(campaignID) {
		if (this.campaignReachController) {
			this.campaignReachController.abort();
		}

		this.campaignReachController = new AbortController();

		try {
			const result = await this.apiCall.get({
				gate: `/campaign/reach/${campaignID}`,
				signal: this.campaignReachController.signal,
			});
			return [null, result];
		} catch (err) {
			return [err];
		}
	}

	async updateCampaignSettings(campaignData) {
		try {
			await this.apiCall.put('/campaign', campaignData);
			return [null];
		} catch (err) {
			return [err];
		}
	}

	async createCampaign(campaignData, campaignBanners) {
		let resultBanners = [];

		if (campaignBanners.length !== 0) {
			// eslint-disable-next-line no-restricted-syntax
			for await (const banner of campaignBanners) {
				const bannerFile = banner.file.length !== 0 ? banner.file[0] : [];
				const file = banner.file.length !== 0 ? await fileToBase64(bannerFile) : '';

				resultBanners = [...resultBanners, {
					banner,
					playerConfigId: banner.configID,
					file,
				}];
			}
		}

		try {
			const result = await this.apiCall.post('/campaign-banners', {
				campaign: campaignData,
				banners: resultBanners,
			});

			return [null, result];
		} catch (err) {
			return [err];
		}
	}

	async updateCampaignStatus(campaignID, statusForSet) {
		try {
			await this.apiCall.put('/campaign/status', { campaignID, setStatus: statusForSet });
			return [null];
		} catch (err) {
			return [err];
		}
	}

	async getCampaignState(campaignID) {
		try {
			const result = await this.apiCall.get({
				gate: `campaign/state/${campaignID}`,
				isFatal: true,
			});
			return [null, result];
		} catch (err) {
			return [err];
		}
	}

	async getBanners(campaignID) {
		try {
			const banners = await this.apiCall.get({
				gate: `/campaign/banners/${campaignID}`,
				isFatal: true,
			});
			if (banners.length === 0) {
				return [null, banners];
			}
			const bannersWithTranslatedToFrontendKeysTrackings = banners.map((banner) => {
				Object.keys(banner.tracking).forEach((key) => {
					if (!banner.tracking[key]) {
						banner.tracking[key] = [];
					}
				});
				return banner;
			});
			return [null, bannersWithTranslatedToFrontendKeysTrackings];
		} catch (err) {
			return [err];
		}
	}

	async getList(startTime, endTime, type, status) {
		try {
			const result = await this.apiCall.get({
				gate: '/campaign/list',
				params: {
					startTime, endTime, type, status,
				},
				isFatal: true,
			});
			return [null, result];
		} catch (err) {
			return [err];
		}
	}

	async getCampaignInfo(campaignID) {
		try {
			const result = await this.apiCall.get({
				gate: `/campaign/info/${campaignID}`,
				isFatal: true,
			});
			return [null, result];
		} catch (err) {
			return [err];
		}
	}

	async getLogs(campaignID, dateStart, dateStop) {
		try {
			const result = await this.apiCall.get({
				gate: `/campaign/logs/${campaignID}`,
				params: { dateStart, dateStop },
			});
			return [null, result];
		} catch (err) {
			return [err];
		}
	}

	async getAnalytics(type, campaignID, timeStart, timeStop, ssps = []) {
		if (this.analyticsController[type]) {
			this.analyticsController[type].abort();
		}

		this.analyticsController[type] = new AbortController();

		const params = {
			timeStart,
			timeStop,
			groupByParams: type,
			ssps: ssps.join(','),
		};

		try {
			const analyticsResult = await this.apiCall.get({
				gate: `/campaign/${campaignID}/analytics`,
				params,
				signal: this.analyticsController[type].signal,
				isFatal: true,
			});
			const resultAnalytics = analyticsResult.analyticsBySSPS[type.toLowerCase()] ?? [];
			if (resultAnalytics.length !== 0) {
				resultAnalytics.forEach((item) => {
					item.ctr = this.getPercents(item.click, item.win, 2);
					item.budget = item.sumPrice.toFixed(2);
					// TODO DSP-T-1715 Remove after back problem fix
					item.complete = item.complete > item.win ? item.win : item.complete;
					const calculatedViewPercents = this.getPercents(item.view, item.win, 2);
					item.viewPercents = calculatedViewPercents >= 100 ? 98 : calculatedViewPercents;

					item.vtr = this.getPercents(item.complete, item.win, 2);
					item.ban = false;
					item.id = '';
					if (type === 'device') {
						item.groupBy = this.devicesNameMap[item.groupBy];
					}
				});
			}
			return [null, resultAnalytics];
		} catch (err) {
			return [err];
		}
	}

	async createCampaignClone(campaignID, banners, newCampaignName) {
		const requestData = {
			campaignID,
			banners,
			name: newCampaignName,
		};
		try {
			const result = await this.apiCall.post('/campaign/copy', requestData);
			return [null, result];
		} catch (err) {
			return [err];
		}
	}
}

export default CampaignAPI;
