export default {
	namespaced: true,
	state: () => ({
		interactivesDataByOffers: [],
		interactivesDataByLogoButton: [],
		interactivesDataByOverlay: [],
	}),
	getters: {},
	mutations: {
		UPDATE_INTERACTIVES_DATA_BY_OFFERS(state, payload) {
			state.interactivesDataByOffers = payload;
		},
		UPDATE_INTERACTIVES_DATA_BY_LOGO_BUTTON(state, payload) {
			state.interactivesDataByLogoButton = payload;
		},
		UPDATE_INTERACTIVES_DATA_BY_OVERLAY(state, payload) {
			state.interactivesDataByOverlay = payload;
		},
		SET_DEFAULT_INTERACTIVES_DATA_BY_OFFERS(state) {
			state.interactivesDataByOffers = [];
		},
		SET_DEFAULT_INTERACTIVES_DATA_BY_LOGO_BUTTON(state) {
			state.interactivesDataByLogoButton = [];
		},
		SET_DEFAULT_INTERACTIVES_DATA_BY_OVERLAY(state) {
			state.interactivesDataByOverlay = [];
		},
	},
	actions: {
		async getInteractivesStatisticsByOffers({ commit, rootState, rootGetters }) {
			const [interactivesByOffersErr, statisticInteractivesDataByOffers] = await this.$fetch.statisticsAPI.getStatisticByOffers(
				rootGetters['CampaignStatistics/campaignID'],
				new Date(rootGetters['CampaignStatistics/startDateTime']).toISOString(),
				new Date(rootGetters['CampaignStatistics/endDateTime']).toISOString(),
				rootState.CampaignStatistics.timeZone,
				rootGetters['Campaign/Ssps/sspsForStatisticsRequest'],
			);
			if (interactivesByOffersErr) {
				return Promise.reject(interactivesByOffersErr);
			}
			if (statisticInteractivesDataByOffers.length === 0) {
				commit('SET_DEFAULT_INTERACTIVES_DATA_BY_OFFERS');
				return Promise.resolve();
			}
			commit('UPDATE_INTERACTIVES_DATA_BY_OFFERS', statisticInteractivesDataByOffers);
			return Promise.resolve();
		},
		async getInteractivesStatisticsByLogoButton({ commit, rootState, rootGetters }) {
			const [interactivesByLogoButtonErr, statisticInteractivesDataByLogoButton] = await this.$fetch.statisticsAPI.getStatisticByLogoButton(
				rootGetters['CampaignStatistics/campaignID'],
				new Date(rootGetters['CampaignStatistics/startDateTime']).toISOString(),
				new Date(rootGetters['CampaignStatistics/endDateTime']).toISOString(),
				rootState.CampaignStatistics.timeZone,
				rootGetters['Campaign/Ssps/sspsForStatisticsRequest'],
			);
			if (interactivesByLogoButtonErr) {
				return Promise.reject(interactivesByLogoButtonErr);
			}
			if (statisticInteractivesDataByLogoButton.length === 0) {
				commit('SET_DEFAULT_INTERACTIVES_DATA_BY_LOGO_BUTTON');
				return Promise.resolve();
			}
			commit('UPDATE_INTERACTIVES_DATA_BY_LOGO_BUTTON', statisticInteractivesDataByLogoButton);
			return Promise.resolve();
		},
		async getInteractivesStatisticsByOverlay({ commit, rootState, rootGetters }) {
			const [interactivesErrByOverlay, statisticInteractivesDataByOverlay] = await this.$fetch.statisticsAPI.getStatisticByOverlay(
				rootGetters['CampaignStatistics/campaignID'],
				new Date(rootGetters['CampaignStatistics/startDateTime']).toISOString(),
				new Date(rootGetters['CampaignStatistics/endDateTime']).toISOString(),
				rootState.CampaignStatistics.timeZone,
				rootGetters['Campaign/Ssps/sspsForStatisticsRequest'],
			);
			if (interactivesErrByOverlay) {
				return Promise.reject(interactivesErrByOverlay);
			}
			if (statisticInteractivesDataByOverlay.length === 0) {
				commit('SET_DEFAULT_INTERACTIVES_DATA_BY_OVERLAY');
				return Promise.resolve();
			}
			commit('UPDATE_INTERACTIVES_DATA_BY_OVERLAY', statisticInteractivesDataByOverlay);
			return Promise.resolve();
		},
		async getFullInteractivesStatistics({ dispatch }) {
			const interactivesStatisticsByOffersPromise = dispatch('getInteractivesStatisticsByOffers');
			const interactivesStatisticsByLogoButtonPromise = dispatch('getInteractivesStatisticsByLogoButton');
			const interactivesStatisticsByOverlayPromise = dispatch('getInteractivesStatisticsByOverlay');
			const fullInteractivesStatisticsPromises = [
				interactivesStatisticsByOffersPromise,
				interactivesStatisticsByLogoButtonPromise,
				interactivesStatisticsByOverlayPromise,
			];
			try {
				await Promise.all(fullInteractivesStatisticsPromises);
				return Promise.resolve();
			} catch (err) {
				console.error(err);
				return Promise.reject(err);
			}
		},
	},
};
