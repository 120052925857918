export default {
	namespaced: true,
	state: () => ({
		allCategories: [],
		selectedCategoryByProviders: {},
	}),
	getters: {
		categoriesBySelectedProvider: (state, getters, rootState) => JSON.parse(JSON.stringify(state.allCategories))
			.filter((category) => category.provider === rootState.ProviderPanel.provider),
		isCategoriesSelected: (state) => Object.keys(state.selectedCategoryByProviders).length !== 0,
		selectedCategoryBySelectedProvider(state, rootState, getters, rootGetters) {
			if (!state.selectedCategoryByProviders[rootGetters['ProviderPanel/provider']]) {
				return { id: '', title: '' };
			}
			return state.selectedCategoryByProviders[rootGetters['ProviderPanel/provider']];
		},
	},
	mutations: {
		SET_DEFAULT_ALL_CATEGORIES(state) {
			state.allCategories = [];
		},
		SET_DEFAULT_SELECTED_CATEGORIES(state) {
			state.selectedCategories = {};
		},
		UPDATE_ALL_CATEGORIES(state, payload) {
			state.allCategories = payload;
		},
		UPDATE_SELECTED_CATEGORY_BY_PROVIDERS(state, payload) {
			state.selectedCategoryByProviders = payload;
		},
	},
	actions: {
		async getCategories({ commit }) {
			const [err, categories] = await this.$fetch.categorizerAPI.getCategories();
			if (err) {
				return Promise.reject();
			}
			commit('UPDATE_ALL_CATEGORIES', categories);
			return Promise.resolve();
		},
	},
};
