import getRelevantSession from '@/helpers/get-relevant-session';
import { getRelevantCabinetId } from '@/helpers/getRelevantCabinetId';

const requestInterceptor = (config) => {
	config.headers.Authorization = getRelevantSession();
	config.headers.Cabinet = getRelevantCabinetId();
	return config;
};

export default requestInterceptor;
