export default {
	namespaced: true,
	state: () => ({
		previewFileArray: [],
		firstVisit: true,
	}),
	getters: {
		previewFile: (state) => state.previewFileArray[0],
	},
	mutations: {
		SET_PREVIEW_FILE(state, payload) {
			state.previewFileArray = payload;
		},
		UPDATE_FIRST_VISIT_INFO(state) {
			state.firstVisit = false;
		},
		CLEAR_STORE(state) {
			state.previewFileArray = [];
		},
	},
	actions: {},
};
