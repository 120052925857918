export default {
	namespaced: true,
	state: () => ({
		segments: [],
		selectedPanelSegments: [],
	}),
	getters: {
		providerPanelSegments: (state) => state.segments,
		selectedPanelSegments: (state) => state.selectedPanelSegments,
	},
	mutations: {
		SET_SEGMENTS(state, payload) {
			state.segments = payload;
		},
		UPDATE_SELECTED_PANEL_SEGMENTS(state, payload) {
			state.selectedPanelSegments = payload;
		},
		SET_DEFAULT_SELECTED_PANEL_SEGMENTS(state) {
			state.selectedPanelSegments = [];
		},
		SET_DEFAULT_SEGMENTS(state) {
			state.segments = [];
		},
	},
	actions: {
		async getSegments({ commit }) {
			const [err, segments] = await this.$fetch.categorizerAPI.getSegments();
			if (err) {
				return Promise.reject();
			}
			commit('SET_SEGMENTS', segments);
			return Promise.resolve();
		},
	},
};
