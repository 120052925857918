export default {
	namespaced: true,
	state: () => ({
		ios: false,
		android: false,
		windows: false,
		macosx: false,
		linux: false,
	}),
	getters: {
		osbid: (state) => state,
		selectedOsbid: (state) => {
			const selectedOsbid = [];
			Object.keys(state).forEach((type) => {
				if (state[type]) {
					selectedOsbid.push(type);
				}
			});
			return selectedOsbid;
		},
	},
	mutations: {
		UPDATE_OPERATING_SYSTEM(state, campaignOperatingSystem) {
			Object.keys(state).forEach((fieldName) => {
				state[fieldName] = campaignOperatingSystem ? campaignOperatingSystem.includes(fieldName) : false;
			});
		},
		UPDATE_SPECIAL_OPERATING_SYSTEM(state, { fieldName, value }) {
			state[fieldName] = value;
		},
		SET_DEFAULT_OPERATING_SYSTEM(state) {
			Object.keys(state).forEach((fieldName) => {
				state[fieldName] = false;
			});
		},
	},
};
