class AgencyAPI {
	constructor(apiCall) {
		this.apiCall = apiCall;
	}

	async getAllAccounts(startTime, endTime) {
		try {
			const result = await this.apiCall.get({
				gate: '/agency/accounts',
				params: { startTime, endTime },
				isFatal: true,
			});
			return [null, result];
		} catch (err) {
			return [err];
		}
	}

	async createAccount(login, password) {
		try {
			await this.apiCall.post('/agency/account', { login, password });
			return [null];
		} catch (err) {
			return [err];
		}
	}

	async getGuestConfig(accountID) {
		try {
			const result = await this.apiCall.get({
				gate: `/agency/account/${accountID}/access`,
				isFatal: true,
			});
			return [null, result];
		} catch (err) {
			return [err];
		}
	}

	async updateGuestConfig(accountID, payload) {
		try {
			await this.apiCall.put('/agency/account/guest/access', { accountID, ...payload });
			return [null];
		} catch (err) {
			return [err];
		}
	}

	async getGuestPasswords(accountID) {
		try {
			const result = await this.apiCall.get({
				gate: `/agency/account/${accountID}/guests`,
				isFatal: true,
			});
			const guestPasswordsList = result.guestList ?? [];
			return [null, guestPasswordsList];
		} catch (err) {
			return [err];
		}
	}

	async createGuestPassword(accountID, password) {
		try {
			await this.apiCall.post('/agency/account/guest-password', { accountID, password });
			return [null];
		} catch (err) {
			return [err];
		}
	}

	async removeGuestPassword(accountID, passwordGuest) {
		try {
			await this.apiCall.delete('/agency/account/guest-password', { accountID, passwordGuest });
			return [null];
		} catch (err) {
			return [err];
		}
	}

	async updateMainPassword(accountID, password) {
		try {
			await this.apiCall.put('/agency/account/password', { accountID, password });
			return [null];
		} catch (err) {
			return [err];
		}
	}

	async updateCharge(accountID, charge) {
		try {
			await this.apiCall.put('/agency/account/charge', { accountID, charge });
			return [null];
		} catch (err) {
			return [err];
		}
	}

	async updateBalance(accountID, payload) {
		try {
			await this.apiCall.put('/agency/account/money-transfer', { accountID, ...payload });
			return [null];
		} catch (err) {
			return [err];
		}
	}

	async getCampaignsGuestPrices(accountId) {
		try {
			const result = await this.apiCall.get({
				gate: `/agency/campaigns-guest-prices/${accountId}`,
				isFatal: true,
			});

			return [null, result];
		} catch (err) {
			return [err, null];
		}
	}

	async updateGuestPricesByCampaignId(campaignId, { cpc, cpm, cpv }) {
		try {
			await this.apiCall.post(`/agency/campaign-guest-prices/${campaignId}`, {
				cpc,
				cpm,
				cpv,
			});
			return [null];
		} catch (err) {
			return [err];
		}
	}
}

export default AgencyAPI;
