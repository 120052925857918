export default {
	namespaced: true,
	state: () => ({
		userLimits: {
			userTotalWin: 0,
			userWinInterval: 0,
		},
		limits: {
			totalBudget: 0,
			dayBudget: 0,
			totalShow: 0,
			dayLoad: 0,
			totalComplete: 0,
			dayComplete: 0,
			totalClick: 0,
			dayClick: 0,
		},
		checkValues: {
			isBudgetLimit: false,
			isDayBudgetLimit: false,
			isWinLimit: false,
			isDayWinLimit: false,
			isCompleteLimit: false,
			isDayCompleteLimit: false,
			isClickLimit: false,
			isDayClickLimit: false,
		},
	}),
	getters: {
		finalLimits(state) {
			const unusedLimits = {
				dayClickPlus: 0,
				userTotalClick: 0,
			};
			return { ...unusedLimits, ...state.limits, ...state.userLimits };
		},
		limitByField: (state) => (fieldName) => state.limits[fieldName],
		checkValueByField: (state) => (fieldName) => state.checkValues[fieldName],
	},
	mutations: {
		SET_DEFAULT_LIMITS(state) {
			state.limits.totalBudget = 500;
			state.limits.dayBudget = 300;
			state.limits.totalShow = 0;
			state.limits.dayLoad = 0;
			state.limits.totalComplete = 0;
			state.limits.dayComplete = 0;
			state.limits.dayClick = 0;
			state.limits.totalClick = 0;
		},
		SET_DEFAULT_CHECK_VALUES(state) {
			state.checkValues.isBudgetLimit = true;
			state.checkValues.isDayBudgetLimit = true;
			state.checkValues.isDayWinLimit = false;
			state.checkValues.isWinLimit = false;
			state.checkValues.isCompleteLimit = false;
			state.checkValues.isDayCompleteLimit = false;
			state.checkValues.isClickLimit = false;
			state.checkValues.isDayClickLimit = false;
		},
		SET_DEFAULT_USER_LIMITS(state) {
			Object.keys(state.userLimits).forEach((key) => {
				state.userLimits[key] = 0;
			});
		},
		UPDATE_ALL_LIMITS(state, limitsData) {
			Object.keys(state.limits).forEach((key) => {
				state.limits[key] = limitsData[key];
			});
			Object.keys(state.userLimits).forEach((key) => {
				state.userLimits[key] = limitsData[key];
			});
			state.checkValues.isBudgetLimit = true;
			state.checkValues.isDayBudgetLimit = true;
			state.checkValues.isWinLimit = state.limits.totalShow !== 0;
			state.checkValues.isDayWinLimit = state.limits.dayLoad !== 0;
			state.checkValues.isCompleteLimit = state.limits.totalComplete !== 0;
			state.checkValues.isDayCompleteLimit = state.limits.dayComplete !== 0;
			state.checkValues.isClickLimit = state.limits.totalClick !== 0;
			state.checkValues.isDayClickLimit = state.limits.dayClick !== 0;
		},
		UPDATE_SPECIAL_LIMIT(state, { fieldName, value }) {
			state.limits[fieldName] = value;
		},
		UPDATE_SPECIAL_CHECK_VALUE(state, { fieldName, value }) {
			state.checkValues[fieldName] = value;
		},
		UPDATE_SPECIAL_USER_LIMIT(state, { fieldName, value }) {
			state.userLimits[fieldName] = value;
		},
	},
	actions: {
		setDefaultAllLimits({ commit }) {
			commit('SET_DEFAULT_LIMITS');
			commit('SET_DEFAULT_CHECK_VALUES');
			commit('SET_DEFAULT_USER_LIMITS');
		},
	},
};
