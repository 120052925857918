import showMessage from '@/helpers/messaging-service';

export function specialSymbolsCheck(value) {
	const specialSymbolsRegExp = /[$^&*!@#]/g;
	return value.search(specialSymbolsRegExp) === -1;
}

export function topLevelDomainCheck(value) {
	return !value.includes('https')
		&& !value.includes('http')
		&& !value.includes(':')
		&& !value.includes('/')
		&& !(value.split('.').length !== 2);
}

export function URLCheck(value) {
	const isValid = true;
	if (value && value.trim().length !== 0) {
		try {
			// eslint-disable-next-line no-new
			new URL(value);
			return isValid;
		} catch (e) {
			return !isValid;
		}
	}
}

export function isCampaignDataValid(campaign, copying) {
	const isValid = true;

	const campaignName = campaign.name;
	if (campaignName === '') {
		showMessage('validateCampaignEmptyNameErrorMSG');
		return !isValid;
	}
	if (campaignName.length < 4) {
		showMessage('minCampaignNameErrorMSG');
		return !isValid;
	}
	if (!specialSymbolsCheck(campaignName)) {
		showMessage('specialSymbolsCampaignNameErrorMSG');
		return !isValid;
	}

	if (!copying) {
		const { from, to } = campaign.calendar.dates[0];
		const isCampaignDatesValid = new Date(from).getTime() < new Date(to).getTime();
		if (campaign.ssp.length === 0) {
			showMessage('campaignSspNotSelectedErrorMSG');
			return !isValid;
		}

		if (!isCampaignDatesValid) {
			showMessage('validateCampaignIncorrectCalendarDatesErrorMSG');
			return !isValid;
		}

		if (campaign.deviceInfo.deviceTypes.length === 0) {
			showMessage('emptyCampaignDeviceTypesErrorMSG');
			return !isValid;
		}
	}

	return isValid;
}

export class BrandLiftValidator {
	static isSlideQuestionValid = (slide, slideIndex) => {
		const isValid = true;
		if (slide.question === '') {
			showMessage('emptySlideQuestionBrandLiftErrorMSG', slideIndex);
			return !isValid;
		}
		return isValid;
	};

	static isSlideAnswersValid = (answers, slideIndex) => answers.every((slideAnswer) => {
		if (slideAnswer.answer === '') {
			showMessage('emptySlideAnswersBrandLiftErrorMSG', slideIndex);
		}
		return slideAnswer.answer !== '';
	});

	static isSlidesValid = (slides) => slides.every((slide, slideIndex) => this.isSlideQuestionValid(slide, slideIndex)
		&& this.isSlideAnswersValid(slide.answers, slideIndex));

	static isBrandLiftDataValid = (brandLiftData) => {
		const isValid = true;
		if (brandLiftData.name === '') {
			showMessage('emptyNameBrandLiftErrorMSG');
			return !isValid;
		}
		if (!specialSymbolsCheck(brandLiftData.name)) {
			showMessage('specialSymbolsNameBrandLiftErrorMSG');
			return !isValid;
		}
		if (!this.isSlidesValid(brandLiftData.slides)) {
			return !isValid;
		}
		return isValid;
	};
}
