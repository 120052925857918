export default {
	namespaced: true,
	state: () => ({
		campaigns: [],
		lightCampaigns: [],
	}),
	mutations: {
		SET_CAMPAIGNS_LIST(state, payload) {
			state.campaigns = payload;
		},
		SET_CAMPAIGNS_LIGHT(state, payload) {
			state.lightCampaigns = payload;
		},
	},
	actions: {
		async getLightCampaigns({ commit }, accountId) {
			const [err, result] = await this.$fetch.campaignAPI.getLightCampaigns({
				status: 'all',
				type: 'all',
				account: accountId,
			});
			if (err) {
				return Promise.reject(err);
			}

			commit('SET_CAMPAIGNS_LIGHT', result);
			return Promise.resolve();
		},
		async getCampaigns({ commit }, data) {
			const [err, campaigns] = await this.$fetch.campaignAPI.getList(data.startTime, data.endTime, data.type, data.status);
			if (err) {
				commit('SET_CAMPAIGNS_LIST', []);
				return;
			}
			commit('SET_CAMPAIGNS_LIST', campaigns);
		},
		async getCampaignsByAccountID({ commit }, data) {
			const [err, campaigns] = await this.$fetch.reportsAPI.getCampaignsListByAccountID(data.startTime, data.endTime, data.accountID, data.type, data.status);
			if (err) {
				commit('SET_CAMPAIGNS_LIST', []);
				return;
			}
			commit('SET_CAMPAIGNS_LIST', campaigns);
		},
		async getCampaignsList({ dispatch, rootGetters }, data) {
			if (rootGetters['GlobalHooks/isReportsAccount'] && data.accountID) {
				dispatch('getCampaignsByAccountID', data);
				return;
			}
			dispatch('getCampaigns', data);
		},
	},
};
